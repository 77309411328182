import React, { useEffect, useMemo } from "react";
import grades from "types/Grades";
import { faArrowLeft, faCheckCircle, faExclamationCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgressProps, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { routes } from "routes";
import Button from "components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { mutate } from "swr";
import useCurrentUser from "loaders/useCurrentUser";
import useGenerateClassCode from "../../hooks/useGenerateClassCode";
import { useTracking } from "context/TrackingProvider";
import { googleClassroomToken } from "pages/login/loginUtils";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useGoogleLogin } from "@react-oauth/google";
import { useAlert } from "context/AlertProvider";

interface IGoogleClassroomImportRequest {
  gapi_course_id: string;
  googleStudents: {
    name: string | undefined;
    gapi_course_id: string;
    gapi_user_id: string;
  }[];
  grade: keyof typeof grades;
  klass_code: string;
  klass_name: string;
  teacher_id: number;
}

interface IGoogleClassroomCourse {
  id: string;
  alternateLink: string;
  calendarId: string;
  courseGroupEmail: string;
  creationTime: string;
  descriptionHeading: string;
  enrollmentCode: string;
  gradebookSettings: {
    calculationType: string;
    displaySetting: string;
  }
  guardiansEnabled: boolean;
  name: string;
  ownerId: string;
  room: string;
  section: string;
  teacherFolder: {
    id: string;
    title: string;
    alternateLink: string;
  }
  teacherGroupEmail: string;
  updateTime: string;
}

interface IGoogleClassroomCourseResponse {
  courses: IGoogleClassroomCourse[]
}

interface IGoogleClassroomCourseStudent {
  courseId: string;
  profile: {
    id: string;
    name: {
      familyName: string;
      fullName: string;
      givenName: string;
    }
  }
  userId: string;
}

interface IGoogleClassroomCourseStudentResponse {
  students: IGoogleClassroomCourseStudent[]
}

const ImportGoogleClasses: React.VFC = () => {
  const [classrooms, setClassrooms] = useState<IGoogleClassroomCourse[]>([]);
  const [classroomsLoading, setClassroomsLoading] = useState(true);
  const [classroomsError, setClassroomsError] = useState(false);
  const [needsSignIn, setNeedsSignIn] = useState(false)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const alert = useAlert()
  const [currentUserResourceName, setCurrentUserResourceName] = useState<string>();

  const filteredClassrooms = useMemo(() => {
    return classrooms?.filter(({ ownerId }) => !currentUserResourceName || ownerId === currentUserResourceName)
  }, [classrooms])

  const loadClasses = async () => {
    setClassroomsLoading(true)

    if (!accessToken) {
      setNeedsSignIn(true)
      setClassroomsLoading(false)
      return
    }

    const getClassrooms = async () => {
      if (needsSignIn) {
        return
      }
      fetch('https://people.googleapis.com/v1/people/me?personFields=names', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        })
        .then(res => {
          res.json().then((json: { resourceName: string }) => {
            setCurrentUserResourceName(json.resourceName?.split('/').pop())
          })
        })

      fetch('https://classroom.googleapis.com/v1/courses', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
      .then((res) => {
        res.json().then((json: IGoogleClassroomCourseResponse) => {
          setClassroomsLoading(false)
          setClassrooms(json?.courses)
        })
        .catch(() => {
          alert.error('Could not get classroom data', 'Please try again later')
          setClassroomsError(true)
        })
      })
      .catch(() => {
        alert.error('Could not get classroom data', 'Please try again later')
        setClassroomsError(true)
      })
    }

    await getClassrooms()
  }

  useEffect(() => {
    loadClasses();
  }, [needsSignIn]);

  const { teacherData, error: teacherDataError } = useTeacherInit();
  const teacherDataLoading = !teacherData && !teacherDataError;
  const [selectedClasses, setSelectedClasses] = useState<IGoogleClassroomImportRequest[]>([]);
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<('submitting' | 'success' | 'error')[]>([]);
  const { track } = useTracking();

  const handleSubmit = (_submitStatus: ('error' | 'submitting' | 'success')[] = []) => {
    setIsSubmitting(true);
    const request = selectedClasses[_submitStatus?.length];
    const submitStatus = _submitStatus.concat('submitting')
    setSubmitStatus(submitStatus);

    if (request === undefined) {
      setSubmitSuccess(true);
      mutate(endpoints.teacherInit);

      return;
    }


    justFetch(endpoints.addGoogleClasses, 'POST', {
      klasses: [request]
    })
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        track('Created Class', {
          'Upload Source': 'Google Classroom',
          'Number of Students': request.googleStudents?.length
        });

        handleSubmit(submitStatus.slice(0, submitStatus?.length - 1).concat('success'));
      })
      .catch(() => {
        setSubmitStatus(submitStatus.slice(0, submitStatus?.length - 1).concat('error'));
        setSubmitError(true);
      })
  }

  const handleGoogleLogin = useGoogleLogin({
    flow: 'implicit',
    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters',
    onSuccess: async accessResponse => {
      googleClassroomToken.set(accessResponse?.access_token) 
      setAccessToken(accessResponse?.access_token)
      setNeedsSignIn(false)     
    },
    onError: () => {
      alert.error('Could not authenticate with Google', 'Please try again later')
    }
  });

  if (classroomsLoading || teacherDataLoading) {
    return <Container>
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    </Container>
  }

  if (classroomsError || teacherDataError) {
    return <Container>
      <Alert severity="error" action={<Button size="small" color="inherit" onClick={loadClasses}>Try again</Button>}>We couldn't load your classes from Google Classroom.</Alert>
    </Container>
  }

  return <Container showProgress={isSubmitting} progressProps={{ variant: 'determinate', value: 5 + (submitStatus?.length - 1) / selectedClasses?.length * 100 }}>
    <Dialog open={submitSuccess} fullWidth>
      <DialogTitle>
        Classes Imported
      </DialogTitle>
      <DialogContent>
        Your Google classes have been imported.
      </DialogContent>
      <DialogActions>
        <Box></Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(routes.classes.index)}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={submitError} fullWidth>
      <DialogTitle>
        Error Importing Classes
      </DialogTitle>
      <DialogContent>
        We couldn't import one of your classes from Google.
      </DialogContent>
      <DialogActions>
        <Box></Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(routes.classes.index)}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
    <Typography>
      {needsSignIn ? 'Log in below to import classes:' : 'Select the classes that you\'d like to import:'}
    </Typography>
    {teacherData?.district?.no_pii &&
      <Alert severity="error">
        <>Due to your district's privacy restrictions, you may not use Google Classroom Import. You can still add students to your classes manually. Please contact <a href="mailto:support@kodable.com" target="_blank" rel="noreferrer">support@kodable.com</a> with any questions.</>
      </Alert>
    }
    {!teacherData?.district?.no_pii && <Box component={Paper} {...{ variant: 'outlined' }} maxHeight="50vh" overflow="scroll">
      <List>
        {(filteredClassrooms && filteredClassrooms?.length > 0) &&
          filteredClassrooms.map((course, idx) => {
          const selected = !!selectedClasses.find(klass => klass.gapi_course_id === course.id!);
          return <GoogleClassroomCard
            key={course.id}
            index={idx}
            course={course}
            selected={selected}
            onSelect={request => {
              if (request) {
                if (!selected) {
                  setSelectedClasses(selectedClasses => selectedClasses.concat(request));
                } else {
                  setSelectedClasses(selectedClasses => selectedClasses.map(oldRequest => {
                    if (oldRequest.gapi_course_id === request.gapi_course_id) {
                      return request;
                    }

                    return oldRequest;
                  }))
                }
              } else {
                setSelectedClasses(selectedClasses => selectedClasses.filter(oldRequest => oldRequest.gapi_course_id !== course.id))
              }
            }}
            isSubmitting={isSubmitting}
            submitStatus={submitStatus[idx]}
          />
        })}
        {(!filteredClassrooms || filteredClassrooms?.length < 1) &&
          <Box
            padding={'1rem'}
            textAlign={'center'}
          >
            {!needsSignIn &&
              <Box mb={2}>You do not have any Google Classrooms to import, or all of your classes are already imported</Box>
            }
            {needsSignIn &&
              <Button
              startIcon={<FontAwesomeIcon icon={faGoogle} />}
              className=""
              variant="contained"
              onClick={() => handleGoogleLogin()}
              color="red"
              >Sign in with Google</Button>
            }
          </Box>
        }
      </List>
    </Box>
    }
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Button
        variant="outlined"
        onClick={() => history.push(routes.classes.index)}
        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        disabled={isSubmitting}
      >Back</Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}
        disabled={selectedClasses?.length === 0 || isSubmitting}
      >{isSubmitting ? <>Imported {submitStatus?.length - 1}/{selectedClasses?.length}</> : 'Import'}</Button>
    </Box>
  </Container>
}

const GoogleClassroomCard: React.VFC<{
  course: IGoogleClassroomCourse,
  selected: boolean, onSelect: (request: IGoogleClassroomImportRequest | null) => void,
  isSubmitting: boolean, submitStatus?: 'submitting' | 'success' | 'error',
  index: number
}> = ({ course, selected, onSelect, isSubmitting, submitStatus, index }) => {

  const [loadingValidation, setLoadingValidation] = useState(true);
  const [loadingStudents, setLoadingStudents] = useState(true);

  const [alreadyImported, setAlreadyImported] = useState(false);
  const [students, setStudents] = useState<IGoogleClassroomCourseStudent[]>([]);
  const alert = useAlert()

  useEffect(() => {
    if (!loadingStudents) {
      return
    }
    justFetch(endpoints.validateGoogleClassroom, 'POST', {
      gapi_course_id: course.id
    })
      .then(res => res.json() as Promise<boolean>)
      .then(valid => setAlreadyImported(!valid))
      .then(() => setLoadingValidation(false))

      getAllStudents.then((res) => {
        setStudents(res?.students)
      })
  }, [course]);

  const getAllStudents = new Promise<IGoogleClassroomCourseStudentResponse>((resolve, reject) => {
    const access_token = googleClassroomToken.get()

    if (access_token === null) {
      reject()
    }

    fetch(`https://classroom.googleapis.com/v1/courses/${course?.id}/students`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    })
    .then((res) => {
      res.json().then((json) => {
        setLoadingStudents(false)
        resolve(json)
      })
      .catch(() => {
        alert.error('Could not get student data', 'Please try again later')
      })
    })
    .catch(() => {
      alert.error('Could not get student data', 'Please try again later')
    })
  })

  const { currentUser } = useCurrentUser();

  const { classCode } = useGenerateClassCode();

  if (isSubmitting && !selected) {
    return null;
  }

  return <ListItem
    key={course.id}
    button {...{ disableRipple: true }}
    selected={selected} onClick={() => {
      if (selected) {
        onSelect(null);
      } else {
        onSelect({
          gapi_course_id: course.id!,
          googleStudents: (students && Object.keys(students)?.length < 1) ? [] : students?.map((student: IGoogleClassroomCourseStudent) => {
            const firstName = student.profile?.name?.givenName;
            const lastInitial = student.profile?.name?.familyName?.[0];

            return {
              name: `${firstName}${lastInitial ? ` ${lastInitial}` : ''}`,
              gapi_course_id: course.id!,
              gapi_user_id: student.userId!
            }
          }),
          grade: 'all',
          klass_code: classCode,
          klass_name: course.name!,
          teacher_id: currentUser.id
        });
      }
    }}
    disabled={isSubmitting || loadingStudents || students?.length === 0 || students?.length > 50 || loadingValidation || alreadyImported || classCode?.length === 0}
  >
    <ListItemIcon>
      {!isSubmitting && <Checkbox
        checked={selected}
      />}
      {submitStatus === 'submitting' && <CircularProgress size={32} />}
      {submitStatus === 'success' && <FontAwesomeIcon size="2x" icon={faCheckCircle} />}
      {submitStatus === 'error' && <FontAwesomeIcon size="2x" icon={faExclamationCircle} />}
    </ListItemIcon>
    <ListItemText
      primary={<>
        {alreadyImported ? <><Chip size="small" label="Already imported" />&nbsp;</> : ''}&nbsp;
        {!loadingStudents && students?.length === 0 ? <><Chip size="small" label="No students" />&nbsp;</> : ''}&nbsp;
        {!loadingStudents && students?.length > 50 ? <><Chip size="small" label="Over 50 student limit" />&nbsp;</> : ''}&nbsp;
        {course.name}
      </>}
      secondary={<Box display="flex" alignItems="center"><FontAwesomeIcon icon={faUser} />&nbsp;{!loadingStudents ? students?.length : <CircularProgress size={15} />}</Box>}
    />
  </ListItem>;
}

const Container: React.FC<{ showProgress?: boolean, progressProps?: LinearProgressProps }> = ({ children, showProgress = false, progressProps }) => {
  const sharedClasses = useSharedStyles();

  return <PageContainer variant="centered" showProgress={showProgress} progressProps={progressProps}>
    <Box className={sharedClasses.vspacing2}>
      <PageHeader title="Import Google Classroom Classes" />
      {children}
    </Box>
  </PageContainer>
}

export default ImportGoogleClasses;
