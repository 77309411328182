import {useEffect, useLayoutEffect, useState} from "react";
import {justFetch} from "../../mutations/mutate";
import endpoints from "../../endpoints";
import {Box, Paper} from "@material-ui/core";
import {GridColDef} from "@material-ui/data-grid";
import SyncCleverDialog from "../../components/dialogs/clever/SyncCleverDialog";
import useDialogState from "../../hooks/useDialogState";
import CleverButtonToolbox from "./components/CleverButtonToolbox";
import CleverTable from "./components/CleverTable";

interface CleverSchool {
  school_name: string
  school_id: string
}

const CleverSchools: React.VFC = () => {
  const [schools, setSchools] = useState<CleverSchool[]>([])
  const [filteredSchools, setFilteredSchools] = useState<CleverSchool[]>([])
  const [query, setQuery] = useState<string>('')
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0)
  const [isLoading, setLoading] = useState(true);
  const [totalRowCount, setTotalRowCount] = useState(0)
  const syncCleverDialogState = useDialogState()

  const onPageChange = async (page: number) => {
    await getSchools(page+1, pageSize, query)
    setPage(page)
  }

  const getSchools = async (page: number, pageSize: number, query?: string) => {
    const resp = await justFetch(endpoints.clever.allSchools(page, pageSize, query), 'GET')
    const data = await resp.json()
    setTotalRowCount(data.total_count)
    setSchools(data.schools)
    setFilteredSchools(data.schools)
    setLoading(false)
  }

  useLayoutEffect(() => {
    getSchools(page+1, pageSize)
  }, [])

  useEffect(() => {
    getSchools(page+1, pageSize, query)
  }, [query]);

  const columns: GridColDef[] = [
    { field: 'school_name', headerName: 'School Name', width: 300 },
    { field: 'klass_count', headerName: '# Classes', width: 120, type: 'number' },
    { field: 'teacher_count', headerName: '# Teachers', width: 135, type: 'number' },
    { field: 'student_count', headerName: '# Students', width: 135, type: 'number' },
  ]

  return (
    <Box ml={2} mr={2}>
      <SyncCleverDialog open={(syncCleverDialogState.open)} onClose={syncCleverDialogState.onClose} />
      <CleverButtonToolbox
        searchPlaceholder={"Search Schools"}
        searchDisabled={schools.length === 0 && query.length === 0}
        onSearchChange={(searchValue) => setQuery(searchValue)}
        toolTip={'These are the Clever schools currently syncing with Kodable.'}
      />
      <Box component={Paper}>
        <CleverTable
          page={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={setPageSize}
          columns={columns}
          rows={filteredSchools}
          loading={isLoading}
          totalRowCount={totalRowCount}
        />
      </Box>
    </Box>
  )
}

export default CleverSchools