import {Box, Paper} from "@material-ui/core";
import DogEar from "./DogEar";
import { BoxProps as MatBoxProps} from '@material-ui/core';
import {omit} from "../../utils/omit";
import React from "react";

const DogEarBox: React.FC<MatBoxProps & {imageUrl?: string, color?: string, width: string | number}> = (props) => {

  return (
    <Box component={Paper} {...{ variant: 'outlined' }} width={props.width} style={{overflow: "hidden"}}>
      {props.imageUrl && <DogEar imageUrl={props.imageUrl} color={props.color} />}
      <Box width={'100%'} p={props.p} {...omit(props, ['width', 'component', 'color', 'display'])}>
        {props.children}
      </Box>
    </Box>
  )
}

export default DogEarBox