import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import useSharedStyles from "../../../components/useSharedStyles";
import Button from "../../../components/ui/buttons/Button";
import React from "react";

const ExistingQuoteDialog: React.VFC<{open: boolean, onClose: () => void, name: string, email: string}> = ({open, onClose, name, email}) => {
  const sharedClasses = useSharedStyles()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>A quote for your organization exists!</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
          <Typography variant="body1">Someone at your organization has already submitted a quote request. Please contact them to make changes.</Typography>
        <Box marginLeft={3}>
          <Typography variant="body1" style={{fontWeight: 700}}>Name: <span style={{fontWeight: 400}}>{name}</span></Typography>
          <Typography variant="body1" style={{fontWeight: 700}}>Email: <span style={{fontWeight: 400}}>{email}</span></Typography>
        </Box>
          <Typography variant="body1">If you have any questions please contact <a
            href="mailto:support@kodable.com">support@kodable.com</a></Typography>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExistingQuoteDialog