import React, { useEffect } from 'react'
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Checkbox, Dialog, DialogTitle, FormControlLabel, IconButton, LinearProgress, Link, styled, Typography } from "@material-ui/core"
import Button from "components/ui/buttons/Button"
import useSharedStyles from "components/useSharedStyles"
import { useFormikContext } from "formik"
import { useMemo } from "react"
import { useHistory } from "react-router-dom"
import { IOrderRequest } from "../IOrderRequest"
import { submitOrderSteps } from "./SubmitOrderIndex"
import { useQuickQuote } from "./useQuickQuote"


export const ConfirmOrder: React.VFC<{ viewOnly?: boolean }> = ({ viewOnly }) => {
  const form = useFormikContext<IOrderRequest>();
  const sharedClasses = useSharedStyles();
  const { quote } = useQuickQuote();
  const calculatedTotalPrice = useMemo(() => {
    return (quote?.net_price || 0) - (form.values.custom_discount || 0)
  }, [quote, form.values.custom_discount]);

  return <>
    <Dialog open={form.isSubmitting}>
      <LinearProgress />
      <DialogTitle>Submitting order...</DialogTitle>
    </Dialog>
    <Box display="flex" flexDirection="column" py={2} px={viewOnly ? 0 : 4} className={sharedClasses.vspacing2}>
      <ConfirmationSectionHeader index={1} hideEdit={viewOnly} />
      <Box>
        <ConfirmationKV
          label="Organization Name"
          value={form.values.organization_name}
        />
        <ConfirmationKV
          label="Type"
          value={`Premium${form.values.has_curriculum ? '+' : ''}`}
        />
        <ConfirmationKV
          label="Term"
          value={`${form?.values?.term_months} months`}
        />
        <ConfirmationKV
          label="Number of Schools"
          value={`${form.values.num_sites}`}
        />
        {quote && <ConfirmationKV
          label={`Price Each`}
          value={`$${(quote.list_price_per_site).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`}
        />}
        {quote && <ConfirmationKV
          label={`Subtotal`}
          value={`$${(quote.list_price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`}
        />}
        {quote && !!quote.discount && <ConfirmationKV
          label={`Volume Discount`}
          value={`$${(quote.discount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`}
        />}
        {quote && !!form.values.custom_discount && <ConfirmationKV
          label={`Additional Discount`}
          value={`$${(form.values.custom_discount || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`}
        />}
        {quote && <ConfirmationKV
          label={`Total Price`}
          value={`$${calculatedTotalPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`}
        />}
      </Box>
      <ConfirmationSectionHeader index={2} hideEdit={viewOnly} />
      <Box>
        <ConfirmationKV
          label="Contact Name"
          value={`${form.values.billing_contact_first_name} ${form.values.billing_contact_last_name}`}
        />
        <ConfirmationKV
          label="Contact Email"
          value={form.values.billing_contact_email}
        />
        {form.values.billing_contact_phone?.length > 0 && <ConfirmationKV
          label="Contact Phone"
          value={form.values.billing_contact_phone}
        />}
        <ConfirmationKV
          label="Address Line 1"
          value={form.values.billing_contact_address_street}
        />
        {form.values.billing_contact_address_street2 && <ConfirmationKV
          label="Address Line 2"
          value={form.values.billing_contact_address_street2}
        />}
        <ConfirmationKV
          label="City"
          value={form.values.billing_contact_address_city}
        />
        {form.values.billing_contact_address_province?.length > 0 && <ConfirmationKV
          label="State/Province"
          value={form.values.billing_contact_address_province}
        />}
        <ConfirmationKV
          label="Postal Code"
          value={form.values.billing_contact_address_code}
        />
        <ConfirmationKV
          label="Country"
          value={form.values.billing_contact_address_country}
        />
      </Box>
      <ConfirmationSectionHeader index={3} hideEdit={viewOnly} />
      <Box>
        <ConfirmationKV
          label="Uploaded purchase order?"
          value={form.values.purchase_order_file_id ? 'Yes' : 'No'}
        />
        <ConfirmationKV
          label="Purchase Order Number"
          value={form.values.purchase_order_number?.toString() || 'None'}
        />
      </Box>
      <ConfirmationSectionHeader index={4} hideEdit={!!form.values.license_holder_id || viewOnly} />
      <Box>
        <ConfirmationKV
          label="Name"
          value={form.values.license_holder_name}
        />
        <ConfirmationKV
          label="Email"
          value={form.values.license_holder_email}
        />
      </Box>
      {!viewOnly && <>
        <Box display="flex" alignItems="baseline" borderBottom="1px solid #eee" pb={1} className={sharedClasses.hspacing1}>
          <Typography variant="h2">Terms</Typography>
        </Box>
        <Typography variant="body2">Upon signature by Customer and submission to Kodable, this Order Form is legally binding unless this Order Form is rejected by Kodable for any of the following: (1) the signatory below does not have the authority to bind Customer to this Order Form, (2) the requested  order information or signature is incomplete or does not match our records or the rest of this Order Form. Orders are non-refundable. This Order Form is governed by the Kodable Terms of Service found at <Link href="https://www.kodable.com/privacy" target="_blank">https://www.kodable.com/privacy</Link>.</Typography>
        <Box display="flex" justifyContent="flex-end">
          <FormControlLabel
            control={<Checkbox
              id="agreed_to_terms"
              name="agreed_to_terms"
              value={form.values.agreed_to_terms}
              onChange={form.handleChange}
            />}
            label="I agree to the terms of service"
          />
        </Box>
        <Button
          variant="contained"
          color="green"
          onClick={()=>{/*console.log("SUBMIT FORM: ", form);*/ form.submitForm(); }}
          disabled={!form.isValid}
        >Submit</Button>
      </>}
    </Box>
  </>
}

const ConfirmationSectionHeader = ({ index, hideEdit }: { index: number, hideEdit?: boolean }) => {
  const sharedClasses = useSharedStyles();
  const history = useHistory();

  return <Box display="flex" alignItems="baseline" borderBottom="1px solid #eee" pb={1} className={sharedClasses.hspacing1}>
    <Typography variant="h2">{submitOrderSteps[index].label}</Typography>
    {!hideEdit && <IconButton size="small" onClick={() => history.replace(submitOrderSteps[index].pathname)}>
      <FontAwesomeIcon size="sm" icon={faEdit} />
    </IconButton>}
  </Box>
}
const getValue = (label: string, value: string)=>
{
  if (!value)
    return "";
  return value.split('\n').map((part, idx, { length }) => <React.Fragment key={idx}>{part}{idx < length - 1 ? <br /> : ''}</React.Fragment>);
}

export const ConfirmationKV = ({ label, value }: { label: string, value: string }) => {
  return (
    <Box display="flex" alignItems="flex-start">
      <Box minWidth="250px">
        <Typography variant="subtitle1">{label}</Typography>
      </Box>
      <Box maxWidth="400px">
        <Typography style={{ wordWrap: 'break-word'}}>{getValue(label, value)}</Typography>
      </Box>
    </Box>
  );
};