import grades from "types/Grades";
import courseSections from "./courseSections";

export interface ICourseData {
  id: number;
  name: string;
  meta: ICourseMetadata;
  configuration: {
    theme?: string;
    pathNodes?: IPathNode[];
    lessonNodes?: ILessonNode[];
    rewardNodes?: IPathNode[];
  }
}
export interface ICourseMetadata {
  dashboardTitle?: string;
  dashboardSubtitle?: string;
  section?: keyof typeof courseSections;
  inSectionOrder?: number;
  grades?: ReadonlyArray<(keyof typeof grades)>;
  unlimitedLevels?: boolean;
  resources?: number[];
  standards?: string[];
  showDeepLink?: boolean;
  hideAnswerKeys?: boolean;
  overrideScene?: string;
  emptyCourse?: boolean;
  courseCertificateUrl?: string;
  premium?: boolean;
  iconUrl?: string;
  disableAssignments?: boolean;
}

export interface IPathNode {
  nodeID: string;
  previousNodes: string[];
  enabled: boolean;
  extraParams?: string;
}

export enum ELessonType {
  gameContent = 0,
  mediaContent = 1
}

export interface ILessonNode extends IPathNode {
  url?: string;
  meta?: ILessonMetadata;
  image: string | null;
  pathType: number;
  lessonType: ELessonType;
  levels?: number[];
  mediaLevelID?: number;
}

export interface ILessonMetadata {
  dashboardTitle?: string;
  lengthMinutes?: number;
  helperText?: string;
  analyticsName?: string;
  gameTitle?: string;
  concept?: string;
}

export interface IGalaxyCourse extends ICourseData {
  configuration: ICourseData['configuration'] & {
    planetNodes: {
      courseID: number;
      image: string;
    }[]
  }
}

export interface ILevelData {
  id: number;
  name: string;
  meta?: ILevelMetadata;
  thumbnail_url: string | null;
  fullsize_url: string | null;
}

export interface ILevelMetadata {
  dashboardTitle?: string;
}

const pathNodesToMap = (pathNodes: IPathNode[]) => pathNodes.reduce((prev: { [key: string]: IPathNode }, cur) => ({
  ...prev,
  [cur.nodeID]: cur
}), {});

const generateNextNodes = (pathNodes: IPathNode[]) =>
  pathNodes.reduce((prev: { [key: string]: string[] }, { nodeID, previousNodes }) =>
    previousNodes?.reduce((prev: { [key: string]: string[] }, cur) => ({
      ...prev,
      [cur]: (prev[cur] || []).concat(nodeID)
    }), prev) || prev, {});

export const generateOrderedLessons = (course: ICourseData, includePremiumLessons = true): ILessonNode[] => {
  const orderedLessons: ILessonNode[] = [];
  const nodes = (course.configuration.pathNodes || []).concat(course.configuration.lessonNodes || []).concat(course.configuration.rewardNodes || []);
  const nodesById: { [key: string]: IPathNode | ILessonNode } = pathNodesToMap(nodes);
  const nextNodes: { [key: string]: string[] } = generateNextNodes(nodes);

  const firstNode = nodes.find(({ previousNodes }) => !previousNodes);

  if (!firstNode) {
    return [];
  }

  let queue: (IPathNode | ILessonNode)[] = [firstNode];
  while (queue.length) {
    const cur = queue.shift()!;
    // if (cur.nodeID.startsWith('lesson')) {
      const lessonNode = cur as ILessonNode;
      orderedLessons.push(lessonNode);
    // }
    if (nextNodes[cur.nodeID]) {
      queue = queue.concat(nextNodes[cur.nodeID].map(nodeId => nodesById[nodeId]))
    }
  }

  return orderedLessons.filter(lesson => lesson.nodeID.startsWith('lesson') && lesson.enabled && (includePremiumLessons || !isPaidNode(lesson)));
}

export interface ILevelCompletion {
  id: number;
  level_id: number;
  student_id: number;
  score: number;
  created_at: string;
}

export function isPaidNode(node: ILessonNode) {
  return (node.pathType > 0 )|| node.extraParams?.includes('forcePaywall');
}