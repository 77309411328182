import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import { SubmitOrderPageActions } from "./SubmitOrderPageActions";
import { SubmitOrderTextField } from "./SubmitOrderTextField";
import { useSubmitOrderFormHooks } from "./useSubmitOrderFormHooks";
import { useFormikContext } from "formik";
import { IOrderRequest } from "../IOrderRequest";
import useCurrentUser from "loaders/useCurrentUser";
import React, { useEffect, useState } from "react";

interface SetupAdministratorProps {
  godModeUpgrade?: boolean;
}
export const SetupAdministrator: React.VFC<SetupAdministratorProps> = ({godModeUpgrade}) => {
  const { currentUser } = useCurrentUser();
  const form = useFormikContext<IOrderRequest>();
  const { allowNext, sharedClasses } = useSubmitOrderFormHooks({
    validateFields: ['license_holder_name', 'license_holder_email']
  });
  const [licenseHolderIsTeacher, setLicenseHolderIsTeacher] = useState(godModeUpgrade);

  useEffect(() => {
    const validateForm = async () => {
      await form.validateForm();
    };
    validateForm();
  }, [form.values.license_holder_name, form.values.license_holder_email]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const licenseHolderIsSubmitter = event.target.value === 'current_user'
    form?.setFieldValue('license_holder_is_submitter', licenseHolderIsSubmitter)

    let teacherSelected = event.target.value === 'teacher'
    setLicenseHolderIsTeacher( teacherSelected);

    if (licenseHolderIsSubmitter) {
      if (!currentUser) return

      form?.setFieldValue('license_holder_name', currentUser?.name)
      form?.setFieldValue('license_holder_email', currentUser?.username)
    }
    else if (teacherSelected) {
      form?.setFieldValue('license_holder_name', form.initialValues.license_holder_name)
      form?.setFieldValue('license_holder_email', form.initialValues.license_holder_email)
    }
    else
    {
      form?.setFieldValue('license_holder_name', '')
      form?.setFieldValue('license_holder_email', '')
    }
  }

  return <>
    <Box className={sharedClasses.vspacing2}>
      <Box className={sharedClasses.vspacing2}>
        <Typography variant="h1" align="center"><strong>Who will be the main person setting up {form?.values?.organization_name}'s Kodable account?</strong></Typography>
        <Typography variant="body1" align="center">This process will include adding classes and students to the account, as well as inviting any other teachers to access the Premium{form?.values?.has_curriculum ? '+' : ''} features.</Typography>
      </Box>
      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel value={'current_user'} control={<Radio />} label="I will be the main person setting up Kodable" />
            {godModeUpgrade && <FormControlLabel value={'teacher'} control={<Radio />} label="Upgraded Teacher will be the main point of contact setting up Kodable" />}
            <FormControlLabel value={'other'} control={<Radio />} label="Someone else will be the main point of contact setting up Kodable" />
          </RadioGroup>
        </FormControl>
      </Box>
      {(form?.values?.license_holder_is_submitter !== undefined || licenseHolderIsTeacher !== undefined) &&
      <>
        <br />
        <SubmitOrderTextField
          field="license_holder_name"
          label={form?.values?.license_holder_is_submitter ? "Your Name" : "Point of Contact Name"}
          disabled={
            (form?.values?.license_holder_is_submitter && !!currentUser && !!currentUser?.name) ||
            (licenseHolderIsTeacher)
          }
        />
        <SubmitOrderTextField
          field="license_holder_email"
          label={form?.values?.license_holder_is_submitter ? "Your Email" : "Point of Contact Email"}
          disabled={
            (form?.values?.license_holder_is_submitter && !!currentUser && !!currentUser?.username) ||
            (licenseHolderIsTeacher)}
        />
      </>
      }
    </Box>
    <SubmitOrderPageActions allowPrev allowNext={allowNext} />
  </>
}