import { Box, Typography } from "@material-ui/core"
import { GameTitle } from "enums/GameTitle"
import React from "react";

const CoursesListHeader: React.VFC<{game: GameTitle}> = ({game}) => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" mb={4}>
        <Typography variant="h1" paragraph>{game === GameTitle.CREATOR ? 'Challenges' : 'Courses'}</Typography>
        <Typography>
          {game === GameTitle.CREATOR ?
          'Stay tuned for additional challenges and resources coming soon!'
          :
          'Each course contains game content and additional unplugged materials'
          }
        </Typography>
      </Box>
    )
}

export default CoursesListHeader;