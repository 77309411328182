import React from 'react';
import useStudent from 'loaders/useStudent';
import { Redirect, Link, useLocation, useParams } from 'react-router-dom';
import FuzzImage from 'components/misc/FuzzImage';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
  const location = useLocation();
  const { studentId } = useParams<{ studentId: string }>();
  const match = location.pathname.includes(to) && to !== '/';

  return (
    <Link
      style={{ marginBottom: '-2px', borderBottom: match ? '2px solid #fafafa' : undefined }}
      className={`px-3 py-1 ml-1 lg:px-8 lg:py-3 lg:ml-2 text-md lg:text-lg rounded-t inline-block border-kodable-blue ${
        match ? 'text-kodable-blue border-2' : 'text-black border-0'
      }`}
      to={`/parent/students/${studentId}${to}`}>
      {children}
    </Link>
  );
};

const StudentNav: React.FC = () => {
  const student = useStudent();

  if (!student) {
    return <Redirect to="/" />;
  }

  return (
    <div className="md:h-68">
      <div className="md:pt-2 md:px-8 flex justify-center items-center md:justify-start md:items-start">
        {student.selected_fuzz &&
          <FuzzImage
            className="w-32 md:w-48"
            fuzzId={student.selected_fuzz}
            studentCode={student.student_code}
          />
        }
        <div className="flex flex-1 flex-col md:pb-20">
          <h1 className="text-2xl md:text-5xl font-bold mb-2 md:mt-6">
            {student.name}
          </h1>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="bg-kodable-teal px-2 py-1 md:px-4 md:py-3 text-white md:text-2xl rounded self-start hover:bg-kodable-teal-dark active:bg-kodable-teal-darker"
            href={`${process.env.REACT_APP_GAME_ENDPOINT}/play?user=${student.student_code.replace("_pi", "")}`}>
            <div className="flex justify-center items-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="gamepad"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                className="w-8 h-8 mr-3">
                <path
                  fill="currentColor"
                  d="M480.07 96H160a160 160 0 1 0 114.24 272h91.52A160 160 0 1 0 480.07 96zM248 268a12 12 0 0 1-12 12h-52v52a12 12 0 0 1-12 12h-24a12 12 0 0 1-12-12v-52H84a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h52v-52a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12v52h52a12 12 0 0 1 12 12zm216 76a40 40 0 1 1 40-40 40 40 0 0 1-40 40zm64-96a40 40 0 1 1 40-40 40 40 0 0 1-40 40z"></path>
              </svg>
              Play
            </div>
          </a>
        </div>
      </div>
      <nav className="w-full border-b-2 border-kodable-blue relative md:-mt-4">
        <NavLink to={`/overview`}>Overview</NavLink>
        <NavLink to={`/projects`}>Projects</NavLink>
        <NavLink to={`/progress`}>Progress</NavLink>
        <NavLink to={`/manage`}>Manage</NavLink>
      </nav>
    </div>
  );
};

export default StudentNav;
