import { Box, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, LinearProgress, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { DialogStateProps } from "hooks/useDialogState";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import { IKlass } from "types/IKlass";
import { generateOrderedLessons, ICourseData, isPaidNode } from "../../../pages/courses/ICourseData";
import Amplitude from 'utils/Amplitude';

export interface IClassLessonUnassignment {
  course_id: number;
  lesson_node_id: string;
}

const EditLessonAssignmentsDialog: React.VFC<DialogStateProps & { courseId: string | number, klassId: string | number }> = ({ open = false, onClose = () => { }, courseId, klassId }) => {
  const { currentUser } = useCurrentUser();
  const { data: klassData, error: unassignmentsError } = useSWR<IKlass>(klassId ? endpoints.classById(klassId, ['assignments']) : null)
  const unassignments = klassData?.assignments?.lesson_omissions;
  const { data: courseData, error: courseDetailsError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const courseDetails = useMemo(() => {
    return courseData?.find(({ id }) => id.toString() === courseId.toString());
  }, [courseId, courseData]);
  const orderedLessons = useMemo(() => courseDetails ? generateOrderedLessons(courseDetails) : [], [courseDetails]);
  const [lessonsToAssign, setLessonsToAssign] = useState<string[]>([]);
  const [lessonsToUnassign, setLessonsToUnassign] = useState<string[]>([]);

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const alert = useAlert();

  useEffect(() => {
    if (open) {
      setSubmitting(false);
      setSubmitError(false);
      setLessonsToAssign([]);
      setLessonsToUnassign([]);
    }
  }, [open]);

  const theme = useTheme()

  const setLessonAssigned = (nodeId: string) => {
    setLessonsToAssign(lessons => lessons.concat(nodeId));
    setLessonsToUnassign(lessons => lessons.filter(_nodeId => _nodeId !== nodeId));
  }

  const setLessonUnassigned = (nodeId: string) => {
    if (!unassignments?.find(({ course_id, lesson_node_id }) => course_id === courseDetails?.id && lesson_node_id === nodeId)) {
      setLessonsToUnassign(lessons => lessons.concat(nodeId));
    }
    setLessonsToAssign(lessons => lessons.filter(_nodeId => _nodeId !== nodeId));
  }

  const handleSubmit = () => {
    setSubmitting(true);
    justFetch(endpoints.updateClassLessonUnassignments(klassId, courseId), 'POST', {
      assign_lesson_node_ids: lessonsToAssign,
      unassign_lesson_node_ids: lessonsToUnassign
    }).then(res => {
      if (!res.ok) {
        throw new Error();
      }
      
      // only track on unassignment
      if (lessonsToUnassign?.length > 0) {
        Amplitude.track('Lesson Unassigned', {
          klassId: klassId,
          courseId: courseId,
          lessonsUnassigned: lessonsToUnassign
        });
      }
      
      mutate(endpoints.classById(klassId, ['assignments']), undefined, true);
      alert.success('Lesson Assignments Updated');
      onClose();
    }).catch(() => {
      setSubmitting(false);
      setSubmitError(true);
    })
  }

  const loading = !courseDetails && !courseDetailsError && !unassignments && !unassignmentsError;
  const error = unassignmentsError || courseDetailsError;

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: submitting ? 'visible' : 'hidden' }} />

    <DialogTitle style={{ textAlign: 'center', boxShadow: '0px 3px 5px 1px rgba(0, 0, 0, 0.15)' }}>
      <Typography variant="h1">Edit Lesson Assignments</Typography>
      <Typography variant="h2">{courseDetails?.meta.dashboardTitle}</Typography>
    </DialogTitle>

    {loading && <DialogContent>
      <CircularProgress />
    </DialogContent>}

    {error && !loading && <DialogContent>
      <Alert severity="error">There was an error loading lesson assignments for this course.</Alert>
    </DialogContent>}

    {!loading && <DialogContent>
      {orderedLessons.map(lesson => {
        const initialChecked = !unassignments?.some(unassignment => unassignment.course_id.toString() === courseId.toString() && unassignment.lesson_node_id === lesson.nodeID) && (lesson.pathType === 0 || currentUser.plan === 'School');
        const checked = (initialChecked && !lessonsToUnassign.includes(lesson.nodeID)) || lessonsToAssign.includes(lesson.nodeID);
        const showPaywall = currentUser.plan === 'Free' && isPaidNode(lesson);

        return <Box key={lesson.nodeID} ml={2} height={75} display="flex" alignItems="center">
          <FormControlLabel
            control={<Checkbox style={{ color: theme.palette.green.main }} disabled={submitting || showPaywall} checked={checked} onChange={e => e.target.checked ? setLessonAssigned(lesson.nodeID) : setLessonUnassigned(lesson.nodeID)} />}
            label={<Box ml={1} mb={1} display="flex" flexDirection="row" alignItems="center">
              <Box width={80} display="flex" justifyContent="center">
                <img
                  style={{
                    maxHeight: 60
                  }}
                  src={`images/courses/lesson_icons/${courseDetails?.configuration.theme}/${lesson.image}.png`}
                />
              </Box>
              <Box ml={2}>
                <Typography>{lesson.meta?.dashboardTitle}</Typography>
                {showPaywall && <Typography variant="body2" style={{ color: '#666', fontStyle: 'italic' }}>This lesson is available with Kodable Premium</Typography>}
              </Box>
            </Box>}
          />
        </Box>
      })}
    </DialogContent>}
    <DialogActions style={{ boxShadow: '0px -3px 5px 1px rgba(0, 0, 0, 0.15)' }}>
      <Box width="100%" display="flex" flexDirection="column">
        {submitError && <Alert severity="error">There was an error updating lesson assignments.</Alert>}
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={submitting}
          >
            Save
          </Button>
        </Box>
      </Box>
    </DialogActions>
  </Dialog>
}

export default EditLessonAssignmentsDialog;