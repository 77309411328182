import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import MenuItem from 'components/ui/MenuItem';
import Button from "components/ui/buttons/Button";
import { useFormik } from "formik";
import { IClassDialogProps } from "../../../pages/classes/IClassDialogProps";
import * as Yup from 'yup';
import { Alert } from "@material-ui/lab";
import useTeacherInit from "loaders/useTeacherInit";
import React, { useState } from "react";
import Select from "components/ui/Select";
import { useMemo } from "react";
import useSharedStyles from "components/useSharedStyles";
import { justFetch } from "mutations/mutate";
import endpoints from "endpoints";
import { mutate } from "swr";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { routes } from "routes";

const AssignNewTeacherDialog: React.FC<IClassDialogProps> = ({ open, onClose = () => { }, classId }) => {
  const { teacherData } = useTeacherInit();
  const classData = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id === classId);
  }, [teacherData, classId]);
  const [submitError, setSubmitError] = useState(false);
  const history = useHistory();

  const form = useFormik({
    initialValues: {
      teacher_id: undefined,
    },
    validationSchema: Yup.object({
      teacher_id: Yup.number().required('Select another teacher for this class')
    }),
    onSubmit: ({ teacher_id }) => {
      return justFetch(endpoints.classById(classId), 'PUT', { teacher_id })
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          mutate(endpoints.teacherInit);
          onClose();
          history.push(routes.classes.index);
        })
        .catch(() => {
          setSubmitError(true);
        });
    }
  });

  const availableTeachers = useMemo(() => {
    return teacherData?.teachers;
  }, [teacherData, classData]);

  useEffect(() => {
    if (open) {
      form.resetForm();
    }
  // eslint-disable-next-line
  }, [open]);

  const sharedClasses = useSharedStyles();

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Change Class Teacher</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Alert severity="warning">
          You are about to change the class teacher. The current teacher will be unable to view the class or student progress after this switch.
        </Alert>
        <Select
          label="Teacher"
          onChange={e => form.setFieldValue('teacher_id', e.target.value)}
          displayEmpty
          error={!!form.errors.teacher_id}
          helperText={form.errors.teacher_id}
          value={form.values.teacher_id}
        >
          <MenuItem value={undefined} disabled>Select a teacher</MenuItem>
          {availableTeachers?.map(teacher => (
            <MenuItem key={teacher.id} value={teacher.id}>{teacher.name}</MenuItem>
          ))}
        </Select>

        {
          !form.isValid && form.submitCount > 0 && <Box><Alert severity="error">Please correct the errors above to continue.</Alert></Box>
        }

        {submitError && <Alert severity="error" action={
          <Button
            color="inherit"
            size="small"
            onClick={() => form.submitForm()}
          >Try again</Button>
        } >There was an error trying to submit this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          type="reset"
          disabled={form.isSubmitting}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={form.isSubmitting}
          disableElevation
        >
          Save
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default AssignNewTeacherDialog;