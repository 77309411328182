import { Box, Link, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { Alert } from "@material-ui/lab";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CLASS_STUDENT_LIMIT } from "components/dialogs/class/AddStudentsDialog";
import { useTracking } from "context/TrackingProvider";
import useTeacherInit from "loaders/useTeacherInit";
import IAddNewClassRequest from "types/IAddNewClassRequest";
import { teacherOnboardingContext } from "../../teacher-onboarding/TeacherOnboardingIndex";
import {routes} from "../../../routes";

export const AddClassManualStudents: React.VFC = () => {
  const { state } = useLocation<{ addNewClassRequest: IAddNewClassRequest }>();
  const sharedClasses = useSharedStyles();
  const [studentEntry, setStudentEntry] = useState(state?.addNewClassRequest.students.join('\n') || '');
  const { teacherData } = useTeacherInit();
  const { addNewClassForm } = useContext(teacherOnboardingContext);
  const { pathname } = useLocation()
  const history = useHistory();

  useEffect(() => {
    addNewClassForm.setFieldValue('students', studentEntry.split('\n').map(entry => entry.trim()).filter(Boolean));
  }, [studentEntry]);

  const showStudentLimitWarning = useMemo(() => {
    return studentEntry.split('\n').map(entry => entry.trim()).filter(Boolean).length > CLASS_STUDENT_LIMIT;
  }, [studentEntry]);

  const { track } = useTracking();
  useEffect(() => {
    if (teacherData?.klasses.length === 0) {
      track('Viewed Onboarding Page', {
        'Page Name': 'Add Class Students'
      });
    }
  }, [teacherData]);

  return <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
    <Typography variant="body2" style={{ fontStyle: 'italic' }}>Add one student per line or copy and paste.</Typography>
    <TextField
      multiline
      minRows={14}
      placeholder="Enter one student per line"
      value={studentEntry}
      onChange={e => setStudentEntry(e.target.value)}
      style={{ background: 'white' }}
      disabled={addNewClassForm.isSubmitting}
    />
    {(studentEntry.length < 1 && !addNewClassForm.isSubmitting) &&
      <Box display="flex" justifyContent="flex-end">
        <Link
          onClick={() => history.push(routes.teacherOnboarding.assignGames.index)}
        >Add students later</Link>
      </Box>
    }
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        variant="contained"
        color="blue"
        size="large"
        disableElevation={false}
        disabled={
          addNewClassForm.isSubmitting ||
          pathname === routes.teacherOnboarding.addClassManual.students && addNewClassForm.values.students.length === 0 ||
          showStudentLimitWarning
        }
        style={{
          fontWeight: 'bold',
          margin: "1rem 0 0"
        }}
        onClick={async () => {
          history.push(routes.teacherOnboarding.assignGames.index);
        }}
      >
        Continue
      </Button>
    </Box>
    {showStudentLimitWarning && <Alert severity="error">You cannot have more than 50 students in a class.</Alert>}
  </Box>
}