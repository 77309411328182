import { faEmpire } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, makeStyles } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import useSWR, { mutate } from "swr";
import { IKlass } from "types/IKlass";
import { IResource } from "types/IResource";
import Amplitude from "utils/Amplitude";
import EditCourseDialog from "../../../components/dialogs/courses/EditCourseDialog";
import EditLessonAssignmentsDialog from "../../../components/dialogs/courses/EditLessonAssignmentsDialog";
import { ICourseData, ELessonType, IGalaxyCourse, generateOrderedLessons } from "../ICourseData";
import CourseCardContainer from "./CourseCardContainer";
import Button from "components/ui/buttons/Button";
import { BasicsCourseID } from "enums/BasicsCourseID";

interface CourseCardProps {
    course: ICourseData;
    assignedCourses?: number[];
    classId?: number;
    isBuilding?: boolean;
    iconOverride?: string;
}

export const useCourseCardStyles = makeStyles(() => ({
    paperOutlined: {
      borderColor: '#91BC55'
    },
    checkbox: {
      color: '#91BC55 !important',
    },
    '@keyframes floaty': {
      '0%': {
        transform: 'translateY(0)',
        filter: 'drop-shadow(gray 0px 2px 4px)'
      },
      '50%': {
        transform: 'translateY(-5px)',
        filter: 'drop-shadow(gray 0px 7px 6px)'
      },
      '100%': {
        transform: 'translateY(0)',
        filter: 'drop-shadow(gray 0px 2px 4px)'
      },
    },
    floaty: {
      transform: 'scale(1)',
      '& #floaty-planet': {
        filter: 'drop-shadow(gray 0px 2px 4px)'
      },
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.01)',
        '& #floaty-planet': {
          animation: '$floaty',
          animationDuration: '1.5s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease-in-out'
        }
      }
    }
}))

const CourseCard: React.VFC<CourseCardProps> = ({ course, assignedCourses, classId, iconOverride, isBuilding = false }) => {
    const { data: courseData } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
    const { data: klassData } = useSWR<IKlass>(classId ? endpoints.classById(classId, ['assignments']) : null)
    const { data: materials } = useSWR<IResource[]>(endpoints.resourcesByCourseId(course?.id));
    const unassignments = klassData?.assignments?.lesson_omissions;
    
    const unassignedMediaLessons = useMemo(() => {
      if (course.meta.disableAssignments) {
        return 0;
      }
  
      return unassignments?.filter(unassignment => unassignment.course_id === course.id && course.configuration.lessonNodes?.find(({ nodeID }) => nodeID === unassignment.lesson_node_id)?.lessonType === ELessonType.mediaContent).length || 0;
    }, [unassignments, course]);
  
    const unassignedGameLessons = useMemo(() => {
      if (course.meta.disableAssignments) {
        return 0;
      }
      
      return unassignments?.filter(unassignment => unassignment.course_id === course.id && course.configuration.lessonNodes?.find(({ nodeID }) => nodeID === unassignment.lesson_node_id)?.lessonType === ELessonType.gameContent).length || 0;
    }, [unassignments, course]);
  
    const galaxyImage = useMemo(() => {
      return (courseData?.find(({ id }) => id === 1) as IGalaxyCourse)?.configuration.planetNodes.find(planetNode => planetNode.courseID === course.id)?.image;
    }, [courseData]);
    
    const [showEditCourseDialog, setShowEditCourseDialog] = useState(false);
    const [showEditLessonAssignmentsDialog, setShowEditLessonAssignmentsDialog] = useState(false);
    const sharedClasses = useSharedStyles();
    const [isAssigning, setIsAssigning] = useState(false);
    const [showAssignError, setShowAssignError] = useState(false);
    const alert = useAlert();

    const handleChangeAssignment = async (e: React.ChangeEvent, checked: boolean) => {
      setIsAssigning(true);
  
      const res = await justFetch(endpoints.coursesForClass(classId!), checked ? 'POST' : 'DELETE', { course_id: course.id })
      if (!res.ok) {
        setIsAssigning(false);
        setShowAssignError(true)
      } else {
        // only track on unassignment
        if (!checked) {
          Amplitude.track('Course Unassigned', {
            klassId: klassData?.id,
            courseId: course?.id
          });
        }
  
        await mutate(endpoints.classById(classId!, ['assignments']), {
          ...klassData,
          assignments: {
            ...klassData!.assignments,
            courses: checked ? [...klassData!.assignments!.courses, course.id] : klassData!.assignments!.courses.filter(id => id !== course.id)
          }
        });
        alert.success(`Course ${checked ? 'Assigned' : 'Unassigned'}`);
        setIsAssigning(false);
      }
    }
    const assigned = useMemo(() => assignedCourses?.includes(course.id) || false, [assignedCourses, course])
    const history = useHistory();
    const { currentUser } = useCurrentUser();
    const orderedLessons = useMemo(() => generateOrderedLessons(course, currentUser.plan === 'School'), [course, currentUser]);
    const numGameLessons = orderedLessons.filter(({ lessonType, enabled }) => lessonType === ELessonType.gameContent).length || 0;
    const numMediaLessons = orderedLessons.filter(({ lessonType, enabled }) => lessonType === ELessonType.mediaContent).length || 0;
    const numTeacherMaterials = materials?.filter((material) => material?.instruction_type === 'Teacher Prep').length || 0
    const numGeneralMaterials = materials?.filter((material) => material?.instruction_type !== 'Teacher Prep').length || 0
    
    const onClickCard = () => {
      history.push(routes.courses.viewCourse.materials(course.id), { lastLocation: history.location.pathname })
    }
  
    const [disableAssignments, setDisableAssignments] = useState(true);
    const [disableLessonAssignments, setDisableLessonAssignments] = useState(true);

    useEffect(() => {
      if (classId && course.id) {
        setDisableAssignments(course.id == BasicsCourseID.FUZZTOPIA && assigned);
        setDisableLessonAssignments(course.id == BasicsCourseID.FUZZTOPIA && assigned && unassignedGameLessons == 0);
      }
    }, [classId, course, assigned, unassignedGameLessons])


    return <>
      <EditCourseDialog open={showEditCourseDialog} onClose={() => setShowEditCourseDialog(false)} course={course} />
  
      {classId && <EditLessonAssignmentsDialog open={showEditLessonAssignmentsDialog} onClose={() => setShowEditLessonAssignmentsDialog(false)} klassId={classId} courseId={course.id} />}
      <CourseCardContainer
        assignCoursesView={!!classId}
        assigned={assigned}
        isAssigning={isAssigning}
        handleChangeAssignment={handleChangeAssignment}
        onClickCard={!classId ? onClickCard : undefined}
        disableAssignments={course.meta.disableAssignments || disableAssignments}
      >
        <Dialog open={showAssignError}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>There was an error assigning this course. Please try again.</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={e => { e.stopPropagation(); setShowAssignError(false) }}>Close</Button>
          </DialogActions>
        </Dialog>
        {(galaxyImage || (isBuilding && iconOverride)) && <Box id="floaty-planet" mr={2} flexShrink={0} position="relative" style={{ transition: 'opacity 0.5s', opacity: classId && !assigned && !course.meta.disableAssignments ? 0.5 : 1 }}>
          <img
            alt=""
            src={!!iconOverride ? iconOverride : `images/courses/planets/${galaxyImage}.png`}
            style={{
              height: 100,
            }}
          />
        </Box>}
        <Box display="flex" flexDirection="column" alignItems="flex-start" flexGrow={1} style={{ transition: 'opacity 0.5s', opacity: (classId && !assigned && !course.meta.disableAssignments) ? 0.5 : 1 }}>
          <Typography variant="h1" style={{ fontWeight: 600 }}>{course.meta.dashboardTitle}</Typography>
          <Typography style={{ opacity: 0.65 }} paragraph>{course.meta.dashboardSubtitle}</Typography>
          <Box display="flex" flexDirection="row" className={sharedClasses.hspacing2}>
            {numMediaLessons > 0 && unassignedMediaLessons === 0 && <Box display="flex" alignItems="center">
              <img alt="" style={{ maxHeight: 32 }} src="/images/courses/media_content_icon.png" />&nbsp;<Typography display="inline">{numMediaLessons} video{numMediaLessons > 1 ? 's' : ''}</Typography>
            </Box>}
            {numMediaLessons > 0 && unassignedMediaLessons > 0 && <Box display="flex" alignItems="center">
              <img alt="" style={{ maxHeight: 32 }} src="/images/courses/media_content_icon.png" />&nbsp;<Typography display="inline">{numMediaLessons - unassignedMediaLessons}/{numMediaLessons} video{numMediaLessons > 1 ? 's' : ''} assigned</Typography>
            </Box>}
            {unassignedGameLessons === 0 && <Box display="flex" alignItems="center">
              <img alt="" style={{ maxHeight: 32 }} src="/images/courses/game_content_icon.png" />&nbsp;<Typography display="inline">
                {isBuilding && 'Unlimited Access'}
                {!isBuilding && `${numGameLessons} lesson${numGameLessons > 1 ? 's' : ''}`}
              </Typography>
            </Box>}
            {unassignedGameLessons > 0 && <Box display="flex" alignItems="center">
              <img alt="" style={{ maxHeight: 32 }} src="/images/courses/game_content_icon.png" />&nbsp;<Typography display="inline">{numGameLessons - unassignedGameLessons} / {numGameLessons} lesson{numGameLessons > 1 ? 's' : ''} assigned</Typography>
            </Box>}
            {numGeneralMaterials > 0 && <Box display="flex" alignItems="center">
              <img alt="" style={{ maxHeight: 32 }} src="/images/courses/activities.svg" />&nbsp;<Typography style={{fontSize: '13px'}} display="inline">{numGeneralMaterials} {numGeneralMaterials > 1 ? 'Activities' : 'Activity'}</Typography>
            </Box>
            }
            {numTeacherMaterials > 0 && <Box display="flex" alignItems="center">
              <img alt="" style={{ maxHeight: 32 }} src="/images/courses/clipboard.svg" />&nbsp;<Typography style={{fontSize: '13px'}} display="inline">{numTeacherMaterials} {numTeacherMaterials > 1 ? 'Teacher Resources' : 'Teacher Resource'}</Typography>
            </Box>
            }
          </Box>
        </Box>
        <Box display="flex" flexShrink={0} className={sharedClasses.vspacing2} flexDirection="column" ml={2} minWidth={200}>
          <Button
            color={classId ? 'default' : 'primary'}
            variant={classId ? 'outlined' : 'contained'}
            onClick={onClickCard}
          >View Course</Button>
          {course.meta.disableAssignments && !!classId && <Typography style={{ opacity: 0.65, marginTop: 8, textAlign: 'center' }} variant="body2">{course.meta.dashboardTitle} is required.</Typography>}
          {(currentUser.is_god && !classId && !isBuilding) && <Button
            variant="outlined"
            onClick={e => { e.stopPropagation(); setShowEditCourseDialog(true); }}
            startIcon={<FontAwesomeIcon icon={faEmpire} />}
          >
            Edit course
          </Button>}
  
          {classId && !course.meta.emptyCourse && !(course.meta.disableAssignments || disableLessonAssignments) && <Button
            variant="contained"
            onClick={e => {
              setShowEditLessonAssignmentsDialog(true);
            }}
            disabled={!assigned}
            color={assigned ? "green" : undefined}
          >
            Edit Lesson Assignments
          </Button>}
        </Box>
      </CourseCardContainer>
    </>
}

export default CourseCard