import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link, Paper, Typography } from "@material-ui/core"
import useSharedStyles from "components/useSharedStyles";
import { IGodmodeUserProfile } from "../IGodmodeUserProfile";
import React from "react";

export const CleverDetailsBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
  const sharedClasses = useSharedStyles();

  return <Paper variant="outlined">
    <Box p={2} display="flex" flexDirection="column" alignItems="center" className={sharedClasses.vspacing2}>
      <Typography variant="h5">Clever Details</Typography>
      {teacher.clever_district && <Link target="_blank" href={`https://apps.clever.com/kodable/district/${teacher.clever_district.clever_id}`}>View organization details in Clever</Link>}
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Box display="flex" alignItems="center" mr={2}>
          Signed up with Clever&nbsp;{teacher.has_clever_account ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimesCircle} />}
        </Box>
        <Box display="flex" alignItems="center" ml={2}>
          Imported classes with Clever&nbsp;{teacher.has_clever_klasses ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimesCircle} />}
        </Box>
      </Box>
    </Box>
  </Paper>;
}