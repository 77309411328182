import React from 'react'
import useDialogState from 'hooks/useDialogState'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencil} from '@fortawesome/free-solid-svg-icons/faPencil'
import Button from 'components/ui/buttons/Button'
import ChangeStudentLimitDialog from "./dialogs/ChangeStudentLimitDialog";
import {ISubscription} from "types/ISubscription";

const ChangeStudentLimitButton: React.FC<{subscription: ISubscription, onSuccess: (studentLimit: number) => void}> = ({subscription, onSuccess}) => {
  const dialogState = useDialogState()

  return (
    <>
      <ChangeStudentLimitDialog subscription={subscription} onSuccess={onSuccess} {...dialogState} />
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => dialogState.handleOpen()}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Button>
    </>
  )
}

export default ChangeStudentLimitButton