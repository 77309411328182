import React, { useEffect, useState } from 'react';
import 'utils/Intercom';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useTracking } from 'context/TrackingProvider';
import OverStudentLimit from 'components/banners/OverStudentLimit';
import useCurrentUser from 'loaders/useCurrentUser';
import AppBar from 'components/nav/AppBar';
import { Box, Link, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import useNavStyles from 'components/nav/useNavStyles';
import Drawer from 'components/nav/Drawer';
import { parentHome, teacherHome } from 'AppRoutes';
import ScrollToTop from 'components/misc/ScrollToTop';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import useSharedStyles from 'components/useSharedStyles';
import { routes } from 'routes';
import useSWR from 'swr';
import { ISalesProfile } from 'pages/settings/teacher/ISalesProfile';
import endpoints from 'endpoints';

type UserType = 'teacher' | 'parent';

interface BaseAuthRouteProps extends Omit<RouteProps, 'component'> {
  noUI?: boolean;
  hideAppBar?: boolean;
  trackingPageTitle?: string;
  component: any;
  allowedUsers?: UserType | UserType[];
  redirect?: string;
}

const noBgRoutes = [routes.sales.index, '/valentines/promo-handout', '/curriculum/lesson/'];
const isNoBgRoute = (location: ReturnType<typeof useLocation>) => noBgRoutes.some(route => location.pathname.startsWith(route))

const BaseAuthRoute: React.VFC<BaseAuthRouteProps> = ({ component: Component, hideAppBar = false, noUI = false, trackingPageTitle, allowedUsers, ...rest }) => {
  const location = useLocation<{ showUI: boolean }>();
  const { track } = useTracking();
  const { currentUser } = useCurrentUser();
  const [renewalQuoteLink, setRenewalQuoteLink] = useState<string | null>(null);
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(currentUser?.id));
  const isPrint = useMediaQuery('print')

  useEffect(() => {
    if (trackingPageTitle) {
      track('Viewed Parent Dashboard', { Section: trackingPageTitle })
    }
    // eslint-disable-next-line
  }, [trackingPageTitle]);

  useEffect(() => {
    if (isPrint) {
      return
    }
    const unRenewedSub = salesProfile?.plan_history?.find(plan => plan?.renewed === false)
    if (unRenewedSub) {
      const today = new Date();
      // Create a new date that's 6 months away from today
      const sixMonthsFromNow = new Date(today);
      sixMonthsFromNow.setMonth(today.getMonth() + 6)
      // Parse the endDate string into a Date object
      const endDate = new Date(unRenewedSub?.end_date)
      // Do not show this banner if the subscription endDate is more than 6 months from today
      // This means that they have more than 6 months before their sub expires, so we don't want to bug them yet
      if (endDate > sixMonthsFromNow) {
        return
      }

      // Otherwise, set up the renewal quote link to be displayed
      const renewalQuote = salesProfile?.quotes?.find(quote => quote?.previous_subscription_id === unRenewedSub?.subscription_id)

      if (!renewalQuote) {
        setRenewalQuoteLink(routes?.sales?.quotes?.renewal(unRenewedSub?.subscription_id))
        return
      }
      setRenewalQuoteLink(routes?.sales?.quotes?.view(renewalQuote?.secret_code))
    }
  }, [salesProfile])

  const classes = useNavStyles();
  const sharedClasses = useSharedStyles();
  const theme = useTheme();
  const isScreen = useMediaQuery('screen');

  const isOverLimit = currentUser?.is_parent && currentUser?.over_student_limit;
  const [showHourOfCodeBanner, setShowHourOfCodeBanner] = useState(false)//useLocalStorage('show-hoc-banner-21', true);

  const conditionalStudentLimitClass = (showHourOfCodeBanner || isOverLimit || !!renewalQuoteLink) ? classes.showOverStudentLimitWarning : '';

  const redirect = allowedUsers &&
    currentUser &&
    !(new Array<string>()).concat(allowedUsers).includes(currentUser.is_parent ? 'parent' : 'teacher') &&
    <Redirect to={currentUser.is_parent ? parentHome : teacherHome} />;

  if (rest.redirect) {
    return <Redirect to={rest.redirect} />
  }

  if (hideAppBar) {
    return (
      <Route path={rest.path}>
        {redirect ? redirect : <Component {...rest} />}
      </Route>
    );
  }

  if (noUI && !location.state?.showUI) {
    if (currentUser?.is_parent) {
      return (
        <Route path={rest.path}>
          {redirect ? redirect : <Component {...rest} />}
        </Route>
      );
    }

    return <Route path={rest.path}>
      <ScrollToTop />
      <div style={{ display: 'flex', position: 'relative' }}>
        <AppBar />
        <div style={{ flexGrow: 1, minHeight: '100vh', backgroundColor: theme.palette.background.default, display: 'flex', flexDirection: 'column' }}>
          <Toolbar />
          {redirect ? redirect : <Component {...rest} />}
        </div>
      </div>
    </Route>
  }

  return (
    <Route path={rest.path}>
      <AnimatePresence>
        {<OverStudentLimit isOverLimit={isOverLimit} />}
        {showHourOfCodeBanner && <motion.div
          key="hoc-banner"
          initial={{ height: 45 }}
          animate={{ height: 45 }}
          exit={{ height: 0  }}
          transition={{ duration: 0.25 }}
          style={{
            position: 'fixed',
            width: '100%',
            top: 0,
            left: 0,
            overflow: 'hidden',
            zIndex: 100001
          }}
        >
          <Box
            bgcolor={theme.palette.blue.main}
            height={45}
            width="100%"
            zIndex={10001}
            color="white"
            position="absolute"
            top={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              visibility="hidden"
              width={45}
            >
              <Close />
            </Box>
            <Box
              flexGrow={1}
              textAlign="center"
              color="white"
            >
              A blizzard hit Kodable! Check out the all new winter coding activities. <Link style={{ textDecoration: 'underline' }} color="inherit" href='https://www.kodable.com/learn/the-winter-spectacular-kids-winter-coding-activity/'>Learn how to participate!</Link>
            </Box>
            <Box
              width={45}
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={sharedClasses.hoverCursorPointer}
            >
              <Close
                onClick={() => setShowHourOfCodeBanner(false)}
              />
            </Box>
          </Box>
        </motion.div>}
        {!!renewalQuoteLink &&
          <motion.div
            key="renewal-quote-link-banner"
            initial={{ height: 45 }}
            animate={{ height: 45 }}
            exit={{ height: 0  }}
            transition={{ duration: 0.25 }}
            style={{
              position: 'fixed',
              width: '100%',
              top: 0,
              left: 0,
              overflow: 'hidden',
              zIndex: 100001
            }}
          >
            <Box
              bgcolor={theme.palette.newOrange.main}
              height={45}
              width="100%"
              zIndex={10001}
              color="white"
              position="absolute"
              top={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                visibility="hidden"
                width={45}
              >
                <Close />
              </Box>
              <Box
                flexGrow={1}
                textAlign="center"
                color="white"
                style={{
                  fontWeight: 700
                }}
              >
                Your subscription is expiring soon! <Link style={{ textDecoration: 'underline' }} color="inherit" href={renewalQuoteLink}>View Renewal Quote</Link>
              </Box>
              <Box
                width={45}
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={sharedClasses.hoverCursorPointer}
              >
                <Close
                  onClick={() => setRenewalQuoteLink(null)}
                />
              </Box>
            </Box>
          </motion.div>
        }
      </AnimatePresence>

      <ScrollToTop />
      
      <div style={{ display: 'flex', position: 'relative' }} className={classNames(classes.pageContainer, conditionalStudentLimitClass)}>
        <AppBar classes={{ appBar: conditionalStudentLimitClass + (!isScreen ? ` ${sharedClasses.hide}` : '') }} />
        <Drawer classes={{ drawerContainer: conditionalStudentLimitClass }} />
        <div style={{ flexGrow: 1, minHeight: '100vh', backgroundColor: isNoBgRoute(location) ? undefined : theme.palette.background.default, display: 'flex', flexDirection: 'column', width: 0 }}>
          {isScreen && <Toolbar />}
          {redirect ? redirect : <Component {...rest} />}
        </div>
      </div>
    </Route>
  );
}

export default BaseAuthRoute;
