import {useEffect, useLayoutEffect, useState} from "react";
import {justFetch} from "../../mutations/mutate";
import endpoints from "../../endpoints";
import {Box, Paper} from "@material-ui/core";
import {GridColDef} from "@material-ui/data-grid";
import useDialogState from "../../hooks/useDialogState";
import SyncCleverDialog from "../../components/dialogs/clever/SyncCleverDialog";
import CleverButtonToolbox from "./components/CleverButtonToolbox";
import CleverTable from "./components/CleverTable";

interface CleverTeacher {
  teacher_id: number,
  teacher_name: string,
  klass_name: string,
  school_name: string,
  clever_id: string,
  grade: string,
}

const CleverTeachers: React.VFC = () => {
  const [teachers, setTeachers] = useState<CleverTeacher[]>([])
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0)
  const [isLoading, setLoading] = useState(true);
  const [query, setQuery] = useState<string>('')
  const [filteredTeachers, setFilteredTeachers] = useState<CleverTeacher[]>([])
  const syncCleverDialogState = useDialogState()
  const [totalRowCount, setTotalRowCount] = useState(0)


  const onPageChange = async (page: number) => {
    await getTeachers(page+1, pageSize, query)
    setPage(page)
  }

  const getTeachers = async (page: number, pageSize: number, query?: string) => {
    const resp = await justFetch(endpoints.clever.allTeachers(page, pageSize, false, query), 'GET')
    const data = await resp.json()
    setTotalRowCount(data.total_count)

    setTeachers(data.teachers.map((teacher: CleverTeacher) => {
      return {...teacher, id: `${teacher.teacher_id}`}
    }))
    setFilteredTeachers(data.teachers.map((teacher: CleverTeacher) => {
      return {...teacher, id: `${teacher.teacher_id}`}
    }))
    setLoading(false)
  }

  useLayoutEffect(() => {
    getTeachers(page+1, pageSize)
  }, [])

  useEffect(() => {
    getTeachers(page+1, pageSize, query)
  }, [query])


  const columns: GridColDef[] = [
    { field: 'teacher_name', headerName: 'Teacher Name', width: 250 },
    { field: 'clever_id', headerName: 'Clever ID', width: 250 },
    { field: 'school_name', headerName: 'School', width: 300 },
    { field: 'klass_count', headerName: '# Classes', width: 120, type: 'number' },
    { field: 'student_count', headerName: '# Students', width: 120, type: 'number' },
    { field: 'last_sync', headerName: 'Last Sync', width: 125, type: 'date' },
  ]

  return (
    <Box ml={2} mr={2}>
      <SyncCleverDialog open={(syncCleverDialogState.open)} onClose={syncCleverDialogState.onClose} />
      <CleverButtonToolbox
        searchPlaceholder={"Search Teachers"}
        searchDisabled={teachers.length === 0 && query.length === 0}
        onSearchChange={(searchValue) => setQuery(searchValue)}
        toolTip={'These are the teachers currently shared with Kodable through Clever. If a specific teacher is missing from this list, please contact your Clever admin to ensure they are being shared with us.'}
      />
      <Box component={Paper}>
        <CleverTable
          onPageSizeChange={setPageSize}
          onPageChange={onPageChange}
          pageSize={pageSize}
          page={page}
          columns={columns}
          rows={filteredTeachers}
          loading={isLoading}
          totalRowCount={totalRowCount}
        />
      </Box>
    </Box>
  )
}

export default CleverTeachers