const CleverIcon = () => {
  return (
    <img
      style={{
        width: '21px',
        height: '24px',
        backgroundColor: '#1464ff',
        padding: '4px',
        borderRadius: '4px'
      }}
      alt="clever logo"
      src="/images/clever.svg"
    />
  )
}

export default CleverIcon