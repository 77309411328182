import React from "react";
import {IKlass} from "../../types/IKlass";
import {DialogStateProps} from "../../hooks/useDialogState";
import {IStudentSchoolProfile} from "../../types/IStudentSchoolProfile";
import Tooltip from "../../components/ui/Tooltip";
import Button from "../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export const DeleteStudentsButton: React.FC<{
  klass: IKlass | undefined,
  dialogState: DialogStateProps,
  selectedStudents: IStudentSchoolProfile[]
}> = ({klass, selectedStudents, dialogState}) => {
  const isCleverKlass = klass && klass.clever_id !== null

  if (isCleverKlass) {
    return <Tooltip title={'Deleting students from a Clever class can only be done in Clever'} arrow placement={'top'}>
        <span>
          <Button
            startIcon={<FontAwesomeIcon icon={faTrash}/>}
            variant="contained"
            color="red"
            disabled={selectedStudents.length === 0 || !klass || isCleverKlass}
            onClick={dialogState.handleOpen}
          >
            Delete Selected Students
          </Button>
        </span>
    </Tooltip>
  } else {
    if (!klass) {
      return <Button
        startIcon={<FontAwesomeIcon icon={faTrash}/>}
        variant="contained"
        color="red"
        disabled={selectedStudents.length === 0 || !klass || isCleverKlass}
        onClick={dialogState.handleOpen}
      >
        Delete Selected Students
      </Button>
    }
    return <Button
      startIcon={<FontAwesomeIcon icon={faTrash}/>}
      variant="contained"
      color="red"
      disabled={selectedStudents.length === 0 || !klass || isCleverKlass}
      onClick={dialogState.handleOpen}
    >
      Delete Selected Students
    </Button>
  }
}