import React from "react";
import {IKlass} from "../../types/IKlass";
import Tooltip from "../../components/ui/Tooltip";
import Button from "../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

export const AddStudentsButton: React.FC<{
  klass: IKlass | undefined,
  onAddStudents: (klass_id: number) => void
}> = ({klass, onAddStudents}) => {
  const isCleverKlass = klass && klass.clever_id !== null

  if (isCleverKlass) {
    return <Tooltip title={'Adding Students to a Clever Class can only be done in Clever'} arrow placement={'top'}>
        <span>
          <Button
            startIcon={<FontAwesomeIcon icon={faPlus}/>}
            variant="contained"
            color="primary"
            disableElevation={false}
            disabled={!klass || isCleverKlass}
          >
            Add Students
          </Button>
        </span>
    </Tooltip>
  } else {
    if (!klass) {
      return <Button
        startIcon={<FontAwesomeIcon icon={faPlus}/>}
        variant="contained"
        color="primary"
        disableElevation={false}
        disabled={!klass}
      >
        Add Students
      </Button>
    }
    return <Button
      startIcon={<FontAwesomeIcon icon={faPlus}/>}
      variant="contained"
      color="primary"
      disableElevation={false}
      onClick={() => onAddStudents(klass.id)}
    >
      Add Students
    </Button>
  }
}