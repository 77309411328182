
import React from 'react'
import Button from 'components/ui/buttons/Button'
import useDialogState from 'hooks/useDialogState'
import { SubscriptionDowngradeDialog } from './SubscriptionDowngradeDialog'

export const SubscriptionDowngradeButton: React.FC = () => {
    const dialogState = useDialogState()

    return (
        <>
            <SubscriptionDowngradeDialog {...dialogState} />
            <Button
                variant="contained"
                color="red"
                size="medium"
                onClick={() => dialogState?.handleOpen()}
            >
                {`Downgrade :(`}
            </Button>
        </>
    )
}