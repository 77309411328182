export interface IOrderRequest {
  quote_request_v2_id?: number;

  has_curriculum: boolean;
  num_sites: number;
  min_activation_date?: Date;
  activation_date?: Date | '';
  term_months?: number;
  school_id?: number;
  district_id?: number;
  is_outside_us?: boolean;
  organization_name: string;

  purchase_order_number?: string;
  purchase_order_file_id?: string;
  purchase_order_filename?: string;

  billing_contact_first_name: string;
  billing_contact_last_name: string;
  
  billing_contact_address_street: string;
  billing_contact_address_street2: string;
  billing_contact_address_city: string;
  billing_contact_address_province: string;
  billing_contact_address_code: string;
  billing_contact_address_country: string;

  billing_contact_email: string;
  billing_contact_phone: string;

  license_holder_is_submitter?: boolean;
  license_holder_name: string;
  license_holder_email: string;
  license_holder_id?: number;

  agreed_to_terms: boolean;

  custom_discount?: number;

  site_names?: string[];
}

export const defaultOrderRequest: IOrderRequest = {
  has_curriculum: false,
  num_sites: 1,
  activation_date: '',
  school_id: undefined,
  district_id: undefined,
  organization_name: '',

  billing_contact_first_name: '',
  billing_contact_last_name: '',

  billing_contact_address_street: '',
  billing_contact_address_street2: '',
  billing_contact_address_city: '',
  billing_contact_address_province: '',
  billing_contact_address_code: '',
  billing_contact_address_country: 'United States',

  billing_contact_email: '',
  billing_contact_phone: '',

  license_holder_name: '',
  license_holder_email: '',

  agreed_to_terms: false
}