import { Box, CircularProgress, Paper, Typography, Switch } from "@material-ui/core"
import useSharedStyles from "components/useSharedStyles"
import { useAlert } from "context/AlertProvider"
import { useTracking } from "context/TrackingProvider"
import endpoints from "endpoints"
import { justFetch } from "mutations/mutate"
import React, { useState } from "react"
import useSWR, { mutate } from "swr"
import { IKlass } from "types/IKlass"

export const EnforceAssignmentsAtHome: React.VFC<{ classId: string | number }> = ({ classId }) => {
  const { data: klassData } = useSWR<IKlass>(endpoints.classById(classId, ['assignments']))
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const alert = useAlert();
  const sharedClasses = useSharedStyles();
  const { track } = useTracking();

  const toggleEnforceAssignmentsAtHome = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setLoading(true);
    track('Updated Assignments At Home', { enabled: checked })
    return justFetch(endpoints.classById(classId!), 'PUT', { ...klassData, enforce_assignments_at_home: checked })
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        mutate(endpoints.classById(classId!, ['assignments']), { ...klassData, assignments: { ...klassData?.assignments, enforce_assignments_at_home: checked } });
        alert.success(checked ? `Assignments enforced At Home` : `Assignments for At School only`);
        setLoading(false);
      })
      .catch(() => { setLoading(false); setError(true) });
  }

  return <>
    <Box width="50%" component={Paper} {...{ variant: 'outlined' }} p={2} display="flex" flexDirection="column" className={sharedClasses.vspacing1}>
      <Box display="flex" alignItems="center">
        <Typography variant="h2" style={{ fontWeight: 300 }}>Enforce assignments at home</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" className={sharedClasses.hspacing2}>
        <img alt="" style={{ width: 75 }} src="/images/home_icon.png"></img>
        <Typography>
          Control what students can play at home.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography style={{ fontWeight: klassData?.assignments?.enforce_assignments_at_home ? undefined : 'bold' }}>Off</Typography>
          {
            !loading ? <Switch
              checked={klassData?.assignments?.enforce_assignments_at_home}
              onChange={toggleEnforceAssignmentsAtHome}
            /> : <Box p={1}><CircularProgress size="1.5rem" /></Box>
          }
          <Typography style={{ fontWeight: klassData?.assignments?.enforce_assignments_at_home ? 'bold' : undefined }}>On</Typography>
        </Box>
      </Box>
    </Box>
  </>
}