import mutate from './mutate';
import endpoints from "../endpoints";

type config = {
  password: string
}

const useUpdateAccount = (userId: number) => {
  return (args: config) => {
    if (args.password) {
      mutate(endpoints.password, 'POST', { password: args.password });
    }

    return mutate(endpoints.user(userId), 'PUT', args);
  };
}

export default useUpdateAccount