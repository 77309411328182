import { faFileDownload, faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutlined } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core"
import endpoints from "endpoints";
import { ICourseData, ILevelData } from "pages/courses/ICourseData";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import useSWR from "swr";
import useSharedStyles from "components/useSharedStyles";
import { ITeacherData } from "types/ITeacherData";
import { format } from "date-fns";
import useCurrentUser from "loaders/useCurrentUser";
import { Alert } from "@material-ui/lab";
import classNames from "classnames";
import { generateClassProgressReportView, IProgressReportRawData, IProgressReportView, transformReportToCsv, dateToUTC } from "pages/reports/reportUtilities";
import ClassProgressBar from "./ClassProgressBar";
import { justFetch } from "mutations/mutate";
import Button from "components/ui/buttons/Button";
import FileSaver from "file-saver";

const columnWidth = 220;

const ClassesProgress: React.VFC = () => {
  const { currentUser } = useCurrentUser();

  const { data: teacherData, error: teacherDataError } = useSWR<ITeacherData>(endpoints.teacherInit);
  const { data: courseData, error: courseDataError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const { data: levelData, error: levelDataError } = useSWR<ILevelData[]>(endpoints.allBasicsLevels);
  const [reportData, setReportData] = useState<{ [key: string]: IProgressReportRawData }>();
  const [reportDataError, setReportDataError] = useState(false);

  useEffect(() => {
    if (!teacherData) {
      return;
    }

    justFetch(endpoints.bulkV2Reports, 'POST', {
      klass_ids: teacherData.klasses.map(({ id }) => id)
    })
      .then(res => res.json() as Promise<{ [key: string]: IProgressReportRawData }>)
      .then(setReportData)
      .catch(() => setReportDataError(true));
  }, [teacherData]);

  const loading = (!teacherData && !teacherDataError) || (!courseData && !courseDataError) || (!reportData && !reportDataError) || (!levelData && !levelDataError);
  const error = teacherDataError || courseDataError || reportDataError;

  const klasses = useMemo(() => teacherData?.klasses?.sort(({ id: A }, { id: B }) => A - B), [teacherData]);
  const students = useMemo(() => teacherData?.students.slice().sort((a, b) => a.id! - b.id!) || [], [teacherData]);

  const reportView = useMemo(() => {
    if (!courseData || !teacherData || !reportData || !levelData) {
      return null;
    }

    return generateClassProgressReportView(
      courseData!,
      levelData!,
      students,
      reportData,
      currentUser.plan === 'School',
      true)
  }, [courseData, students, reportData, levelData, currentUser]);

  if (loading) {
    return <Box display="flex" flexDirection="row" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  if (error) {
    return <Alert severity="error">There was an error generating this report.</Alert>
  }

  if (teacherData?.klasses?.length === 0) {
    return <Alert severity="info">You don't have any classes to generate reports.</Alert>
  }

  return <Box display="flex" flexDirection="column" alignItems="flex-start">

    <Button
      variant="outlined"
      startIcon={<FontAwesomeIcon icon={faFileDownload} />}
      onClick={() => {
        FileSaver.saveAs(transformReportToCsv('class', klasses!.map(({ klass_name }) => klass_name), reportView!), 'kodable school progress report.csv');
      }}
    >Download Report</Button>

    <Box mb={1} width="100%" borderBottom="1px solid rgba(0,0,0,0.12)">&nbsp;</Box>

    <Box display="flex" flexDirection="row" marginLeft={`${columnWidth}px`}>
      {reportView?.orderedCourses.map(courseReport => {
        return <CoursesHeader
          key={courseReport.courseId}
          courseReportData={courseReport}
        />
      })}
    </Box>

    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column">
        {klasses?.map(klass => {
          return <ClassProgressStudentRowCell key={klass.id}>
            <Typography variant="subtitle1">{klass.klass_name}</Typography>
          </ClassProgressStudentRowCell>
        })}
      </Box>

      {reportView?.orderedCourses.map(courseReport => {
        return <CourseColumn key={courseReport.courseId} courseReportData={courseReport} />
      })}

    </Box>
  </Box>
}

interface CoursesHeaderProps {
  courseReportData: IProgressReportView['orderedCourses'][0];
  onSelectCourse?: (selectedCourseId?: string) => void
}

const CoursesHeader: React.VFC<CoursesHeaderProps> = ({ courseReportData, onSelectCourse }) => {
  const sharedClasses = useSharedStyles();
  const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();

  return <Box
    mb={2}
    display="flex"
    height={155}
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    width={columnWidth}
    className={onSelectCourse ? classNames(sharedClasses.hoverCursorPointer, classCoursesProgressHeaderStyles.title) : ''}
    onClick={() => {
      onSelectCourse?.(courseReportData.courseId.toString())
    }}
  >
    <img
      src={`images/courses/planets/${courseReportData.galaxyImage}.png`}
      style={{
        height: 100,
      }}
    />
    <Typography variant="h2" color={onSelectCourse ? 'primary' : 'textPrimary'}>{courseReportData.dashboardTitle}</Typography>
  </Box>
}

const CourseColumn: React.VFC<{ courseReportData: IProgressReportView['orderedCourses'][0] }> = ({ courseReportData }) => {
  const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();
  const isBeachCleanup = courseReportData.courseId === 7;

  return <Box className={classCoursesProgressHeaderStyles.column}>
    {
      courseReportData.orderedProgress.map((progress, idx) => {
        const state = (() => {
          if (progress.completionPercentage < 1) {
            return 'inProgress';
          }

          if (progress.threeStarredEverything) {
            return 'fullyCompleted';
          }

          return 'partiallyCompleted';
        })();

        return <ClassProgressStudentRowCell key={idx}>
          {progress.showAsPercent && <Box textAlign="center" width="100%">
            {!isNaN(progress.completionPercentage)
              ? `${Math.round(progress.completionPercentage * 100)}%`
              : (isBeachCleanup ? `${progress.hoc_garbage_pickup_count || 0} pieces collected` : '--')}
          </Box>}
          {!progress.showAsPercent && <ClassProgressBar progress={progress.completionPercentage} state={state} showProgressIndicator={progress.currentLocation}>
            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
              {state === 'fullyCompleted' && <FontAwesomeIcon size="2x" icon={faStar} />}
              {state === 'partiallyCompleted' && <FontAwesomeIcon size="2x" icon={faStarOutlined} />}
              {state !== 'inProgress' && progress.completionDate && <Box ml={1}>Course completed {format(dateToUTC(progress.completionDate), 'M/d/yyyy')}</Box>}
            </Box>
          </ClassProgressBar>}
        </ClassProgressStudentRowCell>
      })
    }
  </Box>
}

const useClassCoursesProgressHeaderStyles = makeStyles(theme => ({
  '@keyframes floaty': {
    '0%': {
      transform: 'translateY(0)',
      filter: 'drop-shadow(gray 0px 2px 4px)'
    },
    '50%': {
      transform: 'translateY(-5px)',
      filter: 'drop-shadow(gray 0px 7px 6px)'
    },
    '100%': {
      transform: 'translateY(0)',
      filter: 'drop-shadow(gray 0px 2px 4px)'
    },
  },
  title: {
    '& img': {
      filter: 'drop-shadow(gray 0px 2px 4px)'
    },
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
      '& img': {
        animation: '$floaty',
        animationDuration: '1.5s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'ease-in-out'
      }
    }
  },
  column: {
    '&:last-child': {
      borderRight: '1px solid rgba(0, 0, 0, 0.2)'
    }
  }
}));

const useClassProgressStudentRowStyles = makeStyles(() => ({
  cell: {
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderRight: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none'
    }
  },
  insideCell: {
    borderTop: `1px solid rgba(0, 0, 0, 0.2)`,
    borderLeft: 'none',
    borderRight: 'none'
  }
}));

const ClassProgressStudentRowCell: React.FC<{ hideBorder?: boolean, width?: number }> = ({ children, width = columnWidth, hideBorder = false }) => {
  const classProgressStudentRowClasses = useClassProgressStudentRowStyles();

  return <Box bgcolor="white" width={width} height={66} p={1} className={hideBorder ? classProgressStudentRowClasses.insideCell : classProgressStudentRowClasses.cell} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
    {children}
  </Box>
}

export default ClassesProgress;