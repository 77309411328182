import { Box, Dialog, DialogContent } from "@material-ui/core"
import { DialogStateProps } from "hooks/useDialogState"
import { AddClassManualForm, AddNewClassManualStep } from "../../../pages/classes/add-class-manual/AddClassManualForm"
import React, { useEffect, useRef, useState } from "react"
import { IKlass } from "types/IKlass"
import { useHistory } from "react-router-dom"
import { useAlert } from "context/AlertProvider"

export const AddClassDialog: React.VFC<DialogStateProps> = (dialogState) => {
    const dialogRef = useRef<HTMLDivElement>(null)
    const history = useHistory()
    const alert = useAlert()
    // This could be modified to handle different kinds of step actions, such as redirecting to a different page
    // We could use this to unify the Onboarding / Modal versions of this form in the future.
    const [step, setStep] = useState<AddNewClassManualStep>('name-grade')

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                // Skip if it's a list item button (inside a select dropdown). This is a sort of hack
                // to work around MUI puts menu items from select dropdowns outside the dialog. It's safe as
                // we can't conceivably click outside the dialog and inside a select dropdown
                if (event.target.classList?.contains('MuiListItem-button')) {
                    return
                }
                // Close the dialog if we click outside of it
                dialogState.onClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dialogState])

    useEffect(() => {
        if (dialogState?.open) {
            return
        }
        // This resets the form step on close. The setTimeout prevents a flash of the form resetting as it's
        // being closed
        setTimeout(() => {
            setStep('name-grade')
        }, 500)
        
    }, [dialogState?.open])

    return (
        <Dialog
            open={dialogState?.open}
            PaperProps={{
                style: {
                  maxWidth: step === 'assign-games' ? 720 : 600 // The design requires this step to be wider
                },
            }}
        >
            <div ref={dialogRef}>
                <DialogContent>
                    <Box
                        style={{
                            padding: '2rem 0'
                        }}
                    >
                        <AddClassManualForm
                            stepper={{step: step, setStep: setStep}}
                            handleSuccess={(createdClass: IKlass) => {
                                dialogState.onClose()
                                alert.success('Class created successfully!')
                                history.push(`/classes/${createdClass?.id}`);
                            }}
                            handleError={(message: string) => {
                                alert.error(message)
                            }}
                        />
                    </Box>
                </DialogContent>
            </div>
        </Dialog>
    )
}