import { useAlert } from "context/AlertProvider";
import { useOrganizationProfile } from "context/OrganizationProfileProvider";
import endpoints from "endpoints";
import { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import { ConfirmationDialog } from "components/dialogs/base/ConfirmationDialog";
import React, { useState } from "react";

export const SubscriptionDowngradeDialog: React.FC<DialogStateProps> = ({ open, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { organization_profile, mutate } = useOrganizationProfile()
  const [error, setError] = useState<string>();
  const alert = useAlert();
  const handleConfirm = () => {
    if (!organization_profile) {
      return
    }
    setIsSubmitting(true);
    setError(undefined);
    justFetch(endpoints.godmode.downgrade(organization_profile?.administrator_id), 'POST')
      .then(res => {
        setIsSubmitting(false);
        if (res.ok) {
            alert.success('Downgraded');
            onClose();
            mutate()
          
        } else {
            res.json().then(body => setError(body.message || body.error || 'Unknown error'));
        }
      })
      .catch(() => setError('Unknown error'));
  }

  return <ConfirmationDialog open={open} onClose={onClose} title={"Downgrade?"} onConfirm={handleConfirm} submitting={isSubmitting} error={error} danger>
  </ConfirmationDialog>;
}