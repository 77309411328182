import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@material-ui/core";
import {IGodmodeSubscription} from "pages/godmode/Users/Teachers/ViewUser/subscription/IGodmodeSubscription";
import endpoints from "endpoints";
import {useAlert} from "context/AlertProvider";
import React, {useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import {justFetch} from "mutations/mutate";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import {ISubscription} from "types/ISubscription";
import {Alert} from "@material-ui/lab";


type ChangeStudentLimitDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: (studentLimit: number) => void
  subscription: ISubscription
}

const ChangeStudentLimitDialog: React.VFC<ChangeStudentLimitDialogProps> = ({open, onClose, onSuccess, subscription}) => {
  const alert = useAlert();
  const [error, setError] = useState<string>();

  const form = useFormik<IGodmodeSubscription>({
    initialValues: subscription ? {
      student_limit: subscription.student_limit
    } : undefined as any,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const v = { ...values }
      if (v.student_limit === subscription?.student_limit) {
        v.student_limit = undefined as any;
      }
      try {
        const res = await justFetch(endpoints.subscriptionById(subscription.id), 'PUT', v)
        if (res.ok) {
          alert.success('Subscription updated');
          if (v.student_limit) {
            onSuccess(v.student_limit);
          }
          onClose();
        } else {
          const err = await res.json();
          setError(err.message || err.error || 'An unknown error occurred')
        }
      } catch (err) {
        setError('An unknown error occurred')
      }
    }
  });
  useEffect(() => {
    if (open) {
      form.resetForm();
      setError(undefined);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
      <DialogTitle>Change Student Limit</DialogTitle>
      <DialogContent>
        <FormikProvider value={form}>
          <Form onSubmit={form.handleSubmit}>
            <TextField
              id="student_limit"
              name="student_limit"
              onChange={form.handleChange}
              value={form.values.student_limit}
              type="number"
              label="Student Limit"
              fullWidth={false}
            />
          </Form>
        </FormikProvider>
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            form.resetForm();
            onClose();
          }}
          disabled={form.isSubmitting}
        >Cancel</Button>
        <Button
          variant="contained"
          color="green"
          onClick={() => form.handleSubmit()}
          disabled={form.isSubmitting}
        >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeStudentLimitDialog