import { Box, Paper, Checkbox, CircularProgress } from "@material-ui/core";
import { useCourseCardStyles } from "./CourseCard";
import React from "react";

interface CourseCardContainerProps {
    assigned?: boolean;
    isAssigning?: boolean;
    assignCoursesView?: boolean;
    onClickCard?: () => void;
    handleChangeAssignment?: (e: React.ChangeEvent, checked: boolean) => void;
    disableAssignments?: boolean;
    onShowContentPaywall?: () => void;
}

const CourseCardContainer: React.FC<CourseCardContainerProps> = ({ assigned: _assigned, isAssigning, assignCoursesView, onClickCard, handleChangeAssignment, disableAssignments, children }) => {
    const courseCardClasses = useCourseCardStyles();
    const assigned = _assigned || (disableAssignments === true && assignCoursesView);
  
    return <Box
      component={Paper}
      {...{
        variant: 'outlined',
        classes: {
          root: assigned ? courseCardClasses.paperOutlined : ''
        }
      }}
      style={{
        borderWidth: assignCoursesView ? 4 : 1,
        transition: 'transform 0.5s, border-color 0.5s'
      }}
      my={2} p={2}
      display="flex" flexDirection="row" alignItems="center"
      className={!assignCoursesView ? courseCardClasses.floaty : ''}
      onClick={onClickCard}
      position="relative"
    >
      {assignCoursesView && !disableAssignments && <Box width={48} height={48} display="flex" flexDirection="column" style={{ backgroundColor: 'white' }} borderRadius="50%" justifyContent="center" alignItems="center" position="absolute" left={-24}>
        {!isAssigning && <Checkbox
          checked={assigned}
          onChange={handleChangeAssignment}
          style={{ backgroundColor: 'white', transform: 'scale(1.2)' }}
          className={assigned ? courseCardClasses.checkbox : ''}
        />}
        {isAssigning && <CircularProgress size="2rem" style={{ color: '#91BC55' }} />}
      </Box>}
      {children}
    </Box>
}
  
  
export default CourseCardContainer;  