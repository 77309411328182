import Button from "../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Box, Divider, Paper, Typography} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import {IRouteParams} from "../../AppRoutes";
import {useLayoutEffect, useState} from "react";
import {ILesson} from "../../types/ILesson";
import AboutTheLesson from "./components/AboutTheLesson";
import LessonMaterials from "./components/LessonMaterials";
import Objectives from "./components/Objectives";
import Vocabulary from "./components/Vocabulary";
import DirectInstruction from "./components/DirectInstruction";
import GuidedPractice from "./components/GuidedPractice";
import LessonHeader from "./components/LessonHeader";
import IndependentPractice from "./components/IndependentPractice";
import CheckUnderstanding from "./components/CheckUnderstanding";
import LessonStandards from "./components/LessonStandards";
import useCurrentUser from "../../loaders/useCurrentUser";
import CurriculumPaywall from "./CurriculumPaywall";


const CurriculumPage = () => {
  const {unitId, lessonId} = useParams<IRouteParams>();
  const [lesson, setLesson] = useState<ILesson>();
  const lessonUrl = `${window.location.toString()}`
  const data = `/json/curriculum/lesson_${lessonId}_${unitId}.json`
  const history = useHistory();
  const {currentUser} = useCurrentUser();

  const getNiceLessonFilename = (lesson: ILesson) => {
    if (!lesson) {
      return;
    }
    const grades = lesson.grades.split(',').sort(function (a, b) {
      const prev = a === 'k' ? 0 : Number(a);
      const next = b === 'k' ? 0 : Number(b);

      return prev - next;
    });
    const grade = grades.length > 1 ? grades[0] + '_' + grades[grades.length - 1] : grades[0];
    //this same name is used when generating the pdf
    return lesson.name.substring(0, 40).replace(/\s/g, '_').replace(/[^a-zA-Z0-9_]/g, '') + '_' + grade;
  }

  useLayoutEffect(() => {
    const getLesson = async () => {
      try {
        const res = await fetch(data)
        const respData: ILesson = await res.json()
        setLesson(respData)
      } catch (e) {
        console.error(e)
      }
    }

    getLesson();
  }, []);

  if (!currentUser && lesson) {
    return <CurriculumPaywall lesson={lesson} />
  }

  return (
    <>
      <Box component={Paper}
           display={'flex'}
           flexDirection={'row'}
           style={{position: "fixed", height: 75, top: 0, width: '100%', zIndex: 55555}}
           alignItems={'center'}
      >
        {history.length > 1 && <Button variant={'contained'} color={'green'} style={{minWidth: 20, height: 40, marginLeft: 15}}><FontAwesomeIcon icon={faArrowLeft}/></Button> }
        <Typography variant={'h1'} style={{marginLeft: 20}}>{lesson?.name}</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Box>
          {lesson?.name && lesson?.niceGrades && lesson?.thumbnail_url &&
            <LessonHeader name={lesson.name} niceGrades={lesson.niceGrades} thumbnailUrl={lesson.thumbnail_url}/>
          }
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} style={{marginTop: 50}}>
          {lesson?.description &&
            <AboutTheLesson shortDescription={lesson.description}
                            downloadUrl={`http://resources.kodable.com/curriculum_lessons/${getNiceLessonFilename(lesson)}.pdf`}
                            shareUrl={lessonUrl} /> }
          <Divider style={{width: '80%', marginTop: 25}} />
          {lesson?.objectives && <Objectives objectives={lesson.objectives} /> }
          {lesson?.strands && <LessonStandards standards={lesson.strands} /> }
          {lesson?.materials && <LessonMaterials materials={lesson.materials} resourceUrl={lesson.teaching_materials.resource_url}/> }
          {lesson?.vocabulary && <Vocabulary vocabulary={lesson.vocabulary}/> }
          {lesson?.direct_instruction && lesson?.direct_instruction_title &&
            <DirectInstruction title={lesson?.direct_instruction_title} body={lesson?.direct_instruction} duration={lesson.direct_instruction_time}/>
          }
          {lesson?.guided_practice_description && lesson?.guided_practice_title && lesson?.guided_practice_activity &&
            <GuidedPractice title={lesson.guided_practice_title} duration={lesson.guided_practice_time} activity={lesson.guided_practice_activity} description={lesson.guided_practice_description}/>
          }
          {lesson?.independent_practice_title && lesson?.independent_practice &&
            <IndependentPractice title={lesson.independent_practice_title} description={lesson.independent_practice}/>
          }
          {lesson?.check_understanding && lesson?.check_understanding_title && lesson?.check_understanding_time &&
            <CheckUnderstanding title={lesson.check_understanding_title} description={lesson.check_understanding} duration={lesson.check_understanding_time}/>
          }
          <Box style={{paddingTop: 25, paddingBottom: 25}}>
            <Typography variant={'caption'}>Lesson plans are copyright of SurfScore, Inc. {new Date().getFullYear()}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CurriculumPage