import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, LinearProgress, Link, MenuItem, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import Select from "components/ui/Select";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useDialogState from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import React, { useEffect } from "react";
import { useState } from "react";
import { mutate } from "swr";
import AddMaterialDialog from "./AddMaterialDialog";
import courseSections from "../../../pages/courses/courseSections";
import { ICourseData } from "../../../pages/courses/ICourseData";

const EditCourseDialog: React.VFC<{ open: boolean, onClose: () => void, course: ICourseData }> = ({ open, onClose, course }) => {
  const sharedClasses = useSharedStyles();
  const [submitError, setSubmitError] = useState(false);
  const form = useFormik({
    enableReinitialize: true,
    initialValues: course.meta,
    onSubmit: values => {
      return justFetch(endpoints.course(course.id), 'PUT', { meta: values })
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          mutate(endpoints.allBasicsCourses);
          onClose();
        })
        .catch(() => setSubmitError(true))
    }
  });

  useEffect(() => {
    if (open) {
      setSubmitError(false);
      form.resetForm();
    }
  }, [open]);

  const addMaterialDialogState = useDialogState();

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <AddMaterialDialog {...addMaterialDialogState} course={course} add="courseCertificate" onAddCourseCertificate={courseCertificateUrl => form.setFieldValue('courseCertificateUrl', courseCertificateUrl)} />
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Edit Course</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="flex-start" className={sharedClasses.vspacing2}>
          <TextField
            label="Dashboard Title"
            id="dashboardTitle"
            name="dashboardTitle"
            value={form?.values?.dashboardTitle}
            onChange={form.handleChange}
            disabled={form.isSubmitting}
          />
          <TextField
            label="Dashboard Subtitle"
            id="dashboardSubtitle"
            name="dashboardSubtitle"
            value={form?.values?.dashboardSubtitle}
            onChange={form.handleChange}
            disabled={form.isSubmitting}
          />
          <Select
            label="Section"
            variant="outlined"
            fullWidth
            displayEmpty
            onChange={(e) =>
              form.setFieldValue("section", e.target.value)
            }
            value={form.values.section}
            id="section"
            margin="dense"
            disabled={form.isSubmitting}
          >
            <MenuItem value={undefined}>
              No section
            </MenuItem>
            {Object.entries(courseSections).map(([key, details]) => {
              return <MenuItem key={key} value={key}>{details.title}</MenuItem>
            })}
          </Select>
          <TextField
            id="inSectionOrder"
            name="inSectionOrder"
            label="Order in section"
            type="number"
            value={form.values.inSectionOrder}
            onChange={form.handleChange}
            disabled={form.isSubmitting}
          />
          <FormControlLabel
            control={<Checkbox checked={form.values.unlimitedLevels} onChange={form.handleChange} name="unlimitedLevels" id="unlimitedLevels" />}
            label="Unlimited levels"
          />
          <FormControlLabel
            control={<Checkbox checked={form.values.showDeepLink} onChange={form.handleChange} name="showDeepLink" id="showDeepLink" />}
            label="Show deep link to game"
          />
          {form.values.showDeepLink && <TextField
            id="overrideScene"
            name="overrideScene"
            label="Override scene"
            placeholder="GalaxyMap"
            value={form.values.overrideScene}
            onChange={form.handleChange}
            disabled={form.isSubmitting}
          />}
          <FormControlLabel
            control={<Checkbox checked={form.values.hideAnswerKeys} onChange={form.handleChange} name="hideAnswerKeys" id="hideAnswerKeys" />}
            label="Hide answer keys"
          />
          <FormControlLabel
            control={<Checkbox checked={form.values.emptyCourse} onChange={form.handleChange} name="emptyCourse" id="emptyCourse" />}
            label="Empty course (e.g. Beach Cleanup)"
          />
          <Typography variant="subtitle1">Course Certificate</Typography>
          {form.values.courseCertificateUrl && <Box display="flex" alignItems="center">
            <Link href={form.values.courseCertificateUrl} target="_blank">View</Link>&nbsp;&nbsp;<FontAwesomeIcon icon={faTrash} className={sharedClasses.hoverCursorPointer} onClick={() => form.setFieldValue('courseCertificateUrl', undefined)} />
          </Box>}
          {!form.values.courseCertificateUrl && <Box display="flex" alignItems="center">
            <Button
              onClick={addMaterialDialogState.handleOpen}
              variant="outlined"
            >Select</Button>
          </Box>}
          <FormControlLabel
            control={<Checkbox checked={form.values.premium} onChange={form.handleChange} name="premium" id="premium" />}
            label="Premium course"
          />
          <FormControlLabel
            control={<Checkbox checked={form.values.disableAssignments} onChange={form.handleChange} name="disableAssignments" id="disableAssignments" />}
            label="Disable assignments"
          />
          {submitError && <Alert severity="error">There was an error submitting this form.</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="reset"
          onClick={onClose}
          disabled={form.isSubmitting}
        >Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={form.isSubmitting}
        >Save</Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default EditCourseDialog;