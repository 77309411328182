import { Box, CircularProgress, Divider, Typography } from "@material-ui/core"
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import React, { useMemo } from "react";
import useSWR from "swr";
import { generateOrderedLessons, ICourseData, isPaidNode } from "./ICourseData";

const CourseOutline: React.VFC<{ course: ICourseData, variant: 'full' | 'mini' }> = ({ course, variant }) => {
  const courseId = course.id;
  const { data: allCourses, error: allCoursesError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const courseDetails = useMemo(() => {
    return allCourses?.find(({ id }) => id === courseId);
  }, [allCourses]);
  const courseDetailsLoading = !courseDetails && !allCoursesError;
  const orderedLessons = useMemo(() => courseDetails ? generateOrderedLessons(courseDetails) : [], [courseDetails]);
  const { currentUser } = useCurrentUser();

  if (courseDetailsLoading) {
    return <CircularProgress />;
  }

  if (allCoursesError) {
    return null;
  }

  return <Box>
    <Box maxWidth={variant === 'full' ? undefined : orderedLessons.length * 64 + (course.meta?.unlimitedLevels ? 128 : 0)}>
      <Divider style={{ backgroundColor: 'black', transform: `translateY(${variant === 'full' ? 35 : 16}px)`, margin: '0 auto', width: variant === 'full' ? `${100 - 100 / orderedLessons.length}%` : `calc(100% - 32px)` }} />
      <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ transform: 'translate(0)' }}>
        {orderedLessons.map((lesson, i) => {
          const showKodableHeart = (isPaidNode(lesson) || course.meta.premium) && currentUser.plan === 'Free';
          const icon = `images/courses/lesson_icons/${course.configuration.theme}/${lesson.image}.png`;

          return <Box
            key={lesson.nodeID}
            {...(variant === 'full' ? { flexGrow: 1, flexBasis: 0 } : {})} flexShrink={0} display="flex" flexDirection="column" alignItems="center"
            textAlign="center" position="relative"
          >
            <Box display="flex" flexDirection="column" justifyContent="center" bgcolor="#fafafa" boxShadow="0px 0px 8px 4px #fafafa" borderRadius="50%" height={variant === 'full' ? 64 : 32}>
              <img
                style={{
                  height: 60
                }}
                src={icon}
              />
            </Box>
            {showKodableHeart && <img src="/images/courses/kodable_home.png" style={{ width: variant === 'full' ? '33%' : '50%', maxWidth: 36, position: 'absolute', top: 0, left: '60%' }} />}
          </Box>
        })}
        {course.meta?.unlimitedLevels && variant === 'mini' && <Box display="flex" alignItems="center" style={{ backgroundColor: 'white' }}>
          <Typography variant="overline">Unlimited</Typography>
        </Box>}
      </Box>
    </Box>
  </Box>
}

export default CourseOutline;