import React, { useState } from "react";
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, LinearProgress, Link, Typography } from '@material-ui/core';
import MenuItem from 'components/ui/MenuItem';
import Button from "components/ui/buttons/Button";
import { useFormik } from "formik";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Select from "components/ui/Select";
import endpoints from "endpoints";
import useSWR, { mutate } from "swr";
import { ITeacherData } from "types/ITeacherData";
import useSharedStyles from "components/useSharedStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import TextField from "components/ui/TextField";
import * as Yup from 'yup';
import { useEffect } from "react";
import { justFetch } from "mutations/mutate";
import KeyValuePair from "components/data/KeyValuePair";
import CoppaDialog from "components/dialogs/CoppaDialog";
import { useAlert } from "context/AlertProvider";

interface AddStudentsDialogProps {
  open: boolean;
  onClose: (...args: any[]) => any,
  targetClassId?: string;
}

export const CLASS_STUDENT_LIMIT = 50;

const AddStudentsDialog: React.VFC<AddStudentsDialogProps> = ({ open, targetClassId = '', onClose }) => {
  const { data: teacherData } = useSWR<ITeacherData>(endpoints.teacherInit);
  const [submitError, setSubmitError] = useState<string | false>(false);
  const alert = useAlert();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      klass_id: targetClassId,
      student: '',
      multipleStudentsStr: '',
      students: undefined,
      addMode: 'one',
      is_teacher: false
    },
    validationSchema: Yup.object({
      klass_id: Yup.string().required('Please select a class to add students'),
      student: Yup.string()
        .when('addMode', {
          is: 'one',
          then: Yup.string().required("Enter a student's name")
        }),
      students: Yup.array().when('addMode', {
        is: 'multiple',
        then: Yup.array().of(Yup.string()).required("Enter one student per line")
      })
    }),
    onSubmit: values => {
      setSubmitError(false);

      return justFetch(values.addMode === 'one' ? endpoints.addStudent : endpoints.addStudents, 'POST', {
        klass_id: values.klass_id,
        name: values.addMode === 'one' ? values.student.trim() : undefined,
        students: values.addMode === 'one' ? undefined : (values.students as unknown as string[]).map(student => student.trim()),
        is_teacher: values.addMode === 'one' ? values.is_teacher : undefined
      })
        .then(res => {
          if (res.ok) {
            mutate(endpoints.teacherInit);
            alert.success(`Student${values.addMode === 'one' ? '' : 's'} Created`);
            onClose();
          } else {
            res.json().then(body => setSubmitError(body.message || body.error));
          }
        })
        .catch(() => setSubmitError('An unknown error occurred.'))
    }
  });
  const sharedClasses = useSharedStyles();

  useEffect(() => {
    if (open) {
      form.handleReset(null);
    }
    // eslint-disable-next-line
  }, [open]);
  const [showCoppaDialog, setShowCoppaDialog] = useState(false);
  const [showStudentLimitWarning, setShowStudentLimitWarning] = useState(false);

  useEffect(() => {
    const values = form.values;

    const currentNumStudents = teacherData?.students.filter(student => student.klasses.includes(Number(values.klass_id)))?.length || 0;
    const numNewStudents = (values.addMode === 'one' ? 1 : (values.students as unknown as string)?.length) || 0;

    if ((currentNumStudents + numNewStudents) > CLASS_STUDENT_LIMIT) {
      setShowStudentLimitWarning(true);
    } else {
      setShowStudentLimitWarning(false);
    }
  }, [teacherData?.students, form.values.klass_id, form.values.addMode, form.values.students]);


  return <>
    <CoppaDialog open={showCoppaDialog} onClose={() => setShowCoppaDialog(false)} />
    <Dialog open={open} fullWidth>
      <form onSubmit={form.handleSubmit}>
        <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
        <DialogTitle>
          Add Students
        </DialogTitle>
        <DialogContent className={sharedClasses.vspacing2}>
          {targetClassId === '' && <Select
            label="Class"
            onChange={e => form.setFieldValue('klass_id', e.target.value)}
            value={form.values.klass_id}
            displayEmpty
            fullWidth
            error={form.submitCount > 0 && !!form.errors.klass_id}
            helperText={form.errors.klass_id}
          >
            <MenuItem value="" disabled>Select a class</MenuItem>
            {teacherData?.klasses.map(klass => <MenuItem key={klass.id} value={klass.id}>
              {klass.klass_name}
            </MenuItem>)}
          </Select>}
          {
            teacherData && targetClassId !== '' && <KeyValuePair title="Class" value={teacherData?.klasses.find(({ id }) => id.toString() === targetClassId)?.klass_name!} />
          }
          <Box>
            <Typography variant="subtitle1">Mode</Typography>
            <ToggleButtonGroup
              value={form.values.addMode}
              exclusive
              onChange={(e, value) => value && form.setFieldValue('addMode', value)}
            >
              <ToggleButton
                size="small"
                value="one"
              >
                <FontAwesomeIcon icon={faUser} />
                &nbsp;
                One student
              </ToggleButton>
              <ToggleButton
                size="small"
                value="multiple"
              >
                <FontAwesomeIcon icon={faUsers} />
                &nbsp;
                Multiple students
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {form.values.addMode === 'one' && <Box>
            <Box mb={1}>
              <TextField
                value={form.values.student}
                label="Student Name"
                id="student"
                onChange={form.handleChange}
                error={form.submitCount > 0 && !!form.errors.student}
                helperText={form.errors.student}
                autoFocus
              />
            </Box>
            <FormControlLabel
              control={<Checkbox checked={form.values.is_teacher} onChange={form.handleChange} name="is_teacher" id="is_teacher" />}
              label="Make this a teacher profile"
            />
            <Typography variant="body2">This profile will have all lessons unlocked and completed.</Typography>
          </Box>}
          {form.values.addMode === 'multiple' && <Box className={sharedClasses.vspacing2}>
            <Typography variant="body2">Add one student per line. You can copy/paste from a spreadsheet into the text area!</Typography>
            <TextField
              value={form.values.multipleStudentsStr}
              label="Students"
              multiline
              rows={8}
              id="multipleStudentsStr"
              placeholder="Enter one student per line"
              onChange={e => {
                form.handleChange(e);
                const studentsArray = e.target.value.split('\n').map(entry => entry.trim()).filter(Boolean);
                form.setFieldValue('students', studentsArray.length > 0 ? studentsArray : undefined);
              }}
              error={form.submitCount > 0 && !!form.errors.students}
              helperText={form.submitCount > 0 && form.errors.students}
              autoFocus
            />
          </Box>}
          {!form.isValid && form.submitCount > 0 && <Alert severity="error">Please correct the errors above to continue.</Alert>}
          {showStudentLimitWarning && <Alert severity="error">You cannot have more than 50 students in a class. Please delete some before adding more.</Alert>}
          {submitError && <Alert severity="error">{submitError}</Alert>}
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
              disabled={form.isSubmitting || showStudentLimitWarning}
            >
              Add Student{form.values.addMode === 'multiple' && 's'}
            </Button>
          </Box>
          <Divider />
          <Box textAlign="center" pt={2} pb={4}>
            {teacherData?.district?.no_pii &&
              <Alert severity="error">
                <>Due to your district's privacy restrictions, you may not use student names for your student profiles. Please use an anonymous identifier. <strong>This does not affect your student's experience with Kodable.</strong></>
              </Alert>
            }
            {!teacherData?.district?.no_pii &&
              <>
                <Typography paragraph variant="h2">
                  We care about student safety!
                </Typography>
                <Typography variant="body2">
                  Pursuant to COPPA regulations and our <Link href="https://www.kodable.com/privacy" target="_blank">Terms of Service</Link>, <span style={{ fontWeight: 'bold' }}>do not</span> use any Personally Identifiable Information when creating student profiles.
                </Typography>
              </>
            }
          </Box>
        </DialogContent>
      </form>
    </Dialog>
  </>;
}

export default AddStudentsDialog;