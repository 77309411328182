import { makeStyles } from "@material-ui/core";

const useNavStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: 0,
    transition: 'margin-top 0.25s'
  },
  drawer: {
    flexShrink: 0,
  },
  drawerContainer: {
    overflow: 'auto',
    transition: 'margin-top 0.25s'
  },
  showOverStudentLimitWarning: {
    marginTop: '44px !important',
  },
  pageContainer: {
    marginTop: 0,
    transition: 'margin-top 0.25s'
  },
  drawerRootTeacher: {
    width: 200
  },
  drawerRootParent: {
    width: 220
  },
  drawerPaperTeacher: {
    width: 200
  },
  drawerPaperParent: {
    width: 220
  }
}));

export default useNavStyles;