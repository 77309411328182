import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { Alert } from "@material-ui/lab";
import classNames from "classnames";
import useSharedStyles from "components/useSharedStyles";
import { useFormik } from 'formik';
import useCurrentUser, { mutate } from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { ITeacher } from "types/ITeacher";
import { DialogStateProps } from "hooks/useDialogState";
import { useAlert } from "context/AlertProvider";
import endpoints from "../../../endpoints";

const EditProfileDialog: React.FC<DialogStateProps & { teacher?: ITeacher }> = ({ open = false, onClose = () => { }, teacher }) => {
  const { currentUser } = useCurrentUser();
  const sharedClasses = useSharedStyles();
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const alert = useAlert();

  const target = teacher || currentUser;

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: target.name,
      username: target.username,
    },
    validationSchema: Yup.object({
      name: Yup
        .string()
        .required('Please enter your name.'),
      username: Yup
        .string()
        .email('Please enter a valid email address.')
        .required('Please enter your email address.')
    }),
    onSubmit: values => {
      return justFetch(endpoints.user(target.id), 'PUT', values)
        .then(async res => {
          const body = await res.json();
          if (!res.ok) {
            if (res.status === 422) {
              form.setFieldError('username', body.message);
              setSubmitError(false);
            } else {
              form.setFieldError('username', undefined);
              setSubmitError(true);
            }
          } else {
            mutate();
            onClose();
            alert.success('Profile Saved');
          }
        })
        .catch(() => setSubmitError(true))
    }
  });

  useEffect(() => {
    if (open) {
      form.handleReset(null);
      setShowValidationMessage(false);
      setSubmitError(false);
    }
  // eslint-disable-next-line
  }, [open]);

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
    <form onSubmit={form.handleSubmit}>  
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent className={classNames(sharedClasses.vspacing2)}>
        <label htmlFor="name">
          <Typography
            variant="subtitle1"
          >Name</Typography>
        </label>
        <TextField
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.errors.name}
          error={!!form.errors.name}
          disabled={form.isSubmitting}
          id="name"
          type="text"
          variant="outlined"
          fullWidth
          margin="dense"
        />
        <label htmlFor="email">
          <Typography
            variant="subtitle1"
          >Email</Typography>
        </label>
        <TextField
          value={form.values.username}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.errors.username}
          error={!!form.errors.username}
          disabled={form.isSubmitting}
          id="username"
          type="email"
          variant="outlined"
          fullWidth
          margin="dense"
        />
        {!form.isValid && showValidationMessage && <Alert severity="error">Please correct the errors above to continue.</Alert>}
        {submitError && <Alert severity="error" action={
          <Button
            color="inherit"
            size="small"
            onClick={() => form.submitForm()}
          >Try again</Button>
        } >There was an error trying to submit this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={onClose}
            type="reset"
            disabled={form.isSubmitting}
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => { setShowValidationMessage(true); }}
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            disabled={form.isSubmitting}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </form>
  </Dialog>
}

export default EditProfileDialog;