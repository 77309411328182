export const accountTrackedDate = (trackedDate: string) : string => {
    const date = new Date(trackedDate);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
    });
}

export const formatDateToUSLocale = (dateString: Date): string => {
    // This function will format the date as "03/12/2024"
    return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }).format(dateString);
}

export const subscriptionExpiresSoon = (dateString: string, daysBeforeExpiration: number): boolean => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    // Get the difference in milliseconds
    const difference = inputDate.getTime() - currentDate.getTime();
    const daysDifference = difference / (1000 * 60 * 60 * 24);
    return daysDifference < daysBeforeExpiration
}

export const daysUntil = (dateString: string): number => {
    const inputDate = Date.parse(dateString);
    const today = new Date().toDateString();
    const currentDate = Date.parse(today);
    // Get the difference in milliseconds
    const difference = inputDate - currentDate;
    return Math.floor(difference / (1000 * 60 * 60 * 24))+1;
}

export const accountDateToTimeAgo = (dateString: string): string => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    
    // Get the difference in milliseconds
    const difference = currentDate.getTime() - inputDate.getTime();
    
    // Less than 60 seconds
    if (difference < 60000) {
        const seconds = Math.floor(difference / 1000);
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`
    }
    
    // Less than 60 minutes
    if (difference < 3600000) {
        const minutes = Math.floor(difference / (1000 * 60));
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
    }
    
    // Less than 24 hours
    if (difference < 86400000) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        return `${hours} hour${hours > 1 ? 's' : ''} ago`
    }
    
    // Less than 2 weeks
    const daysDifference = difference / (1000 * 60 * 60 * 24);
    if (daysDifference < 14) {
        const days = Math.floor(daysDifference);
        return `${days} day${days > 1 ? 's' : ''} ago`
    }
    
    // More than 2 weeks
    return inputDate.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    }).replace(',', '').replace(/:\d+ /, ' ')
}

export const parseUTCDate = (dateString: string) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return Date.UTC(year, month - 1, day);
}

const getMonthName = (monthIndex: number): string => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[monthIndex];
}

export const formatRevenueStatsLabel = (interval: 'daily' | 'weekly' | 'monthly' | 'quarterly', date: Date): string => {
    // Adjust the date backward by one day
    const adjustedDate = new Date(date.getTime() - 1000 * 60 * 60 * 24)

    const year = adjustedDate.getUTCFullYear()
    const month = adjustedDate.getUTCMonth()
    const day = adjustedDate.getUTCDate()

    switch (interval) {
        case 'daily':
            return `${day}/${getMonthName(month)}`
        case 'weekly':
            // Calculate the start of the week considering the adjusted date
            const weekStartDate = new Date(adjustedDate.getTime() - adjustedDate.getUTCDay() * 24 * 60 * 60 * 1000 + (adjustedDate.getUTCDay() === 0 ? -6 * 24 * 60 * 60 * 1000 : 24 * 60 * 60 * 1000))
            return `${weekStartDate.getUTCDate()}/${getMonthName(weekStartDate.getUTCMonth())}`

        case 'monthly':
            return `${getMonthName(month)} '${year.toString().slice(-2)}`

        case 'quarterly':
            const quarterStartMonth = month - (month % 3)
            const quarterEndMonth = quarterStartMonth + 2
            return `${getMonthName(quarterStartMonth)} - ${getMonthName(quarterEndMonth)} '${year.toString().slice(-2)}`

        default:
            return ''
    }
}
    
    
