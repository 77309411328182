import React from 'react';
import { Box, Grid, Typography } from "@material-ui/core";
import { ISalesProfile } from "./ISalesProfile";
import { PlanHistoryCard } from "./PlanHistoryCard";

export const PlanHistory: React.FC<{ salesProfile: ISalesProfile, godmode?: boolean }> = ({ salesProfile, godmode }) => {
  const hasActivePlan = salesProfile.plan_history.slice(-1)[0].active;

  return <Box>
    {godmode && hasActivePlan && <Typography variant="h5" paragraph>Current Subscription Details</Typography>}
    {godmode && !hasActivePlan && <Typography variant="h5">Plan History</Typography>}
    <Grid container spacing={2} style={{ maxWidth: 600 }}>
      {salesProfile?.plan_history?.slice().reverse().map((planHistory, idx, arr) => {
        return <React.Fragment key={idx}>
          <Grid item xs={12} key={planHistory.start_date}>
            <PlanHistoryCard planHistory={planHistory} salesProfile={salesProfile} godmode={godmode} />
          </Grid>
          {(godmode && idx === 0 && arr.length > 1 && hasActivePlan) && <Grid item xs={12} key="header-1">
            <Typography variant="h5">Plan History</Typography>
          </Grid>}
        </React.Fragment>
      })}
    </Grid>
  </Box>;
}