import endpoints from "endpoints";
import useSWR from "swr";
import { ITeacherData } from "types/ITeacherData";
import useCurrentUser from "./useCurrentUser";

const useTeacherInit = () => {
  const { currentUser } = useCurrentUser();
  const { data: teacherData, error, revalidate, isValidating } = useSWR<ITeacherData>(currentUser ? endpoints.teacherInit : null);

  return { teacherData, error, revalidate, isValidating };
}

export default useTeacherInit;