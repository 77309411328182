import React from "react";
import useSharedStyles from "components/useSharedStyles";
import {useOrganizationProfile} from "context/OrganizationProfileProvider";
import {Box, Typography} from "@material-ui/core";
import {SubscriptionStateCardsListing} from "pages/godmode/Subscriptions/SubscriptionStateCardsListing";
import OrganizationProfileBillingDetailsModal from "./OrganizationProfileBillingDetailsModal";

export const OrganizationProfileDetailBillingTab: React.FC = () => {
  const sharedClasses = useSharedStyles()
  const {organization_profile} = useOrganizationProfile()

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box className={sharedClasses?.vspacing4} width="48%">
        <Typography variant="h1">Payments</Typography>
        <SubscriptionStateCardsListing organizationProfile={organization_profile}/>
      </Box>
      <Box className={sharedClasses?.vspacing4}
        style={{
          width: '48%',
          backgroundColor: 'white',
          padding: '2rem'
        }}
      >
        <Typography variant="h1">Billing Details</Typography>
        <Box className={sharedClasses?.vspacing1}>
          <Typography variant="h2">Billing Contact</Typography>
          <Typography variant="body1"><strong>{organization_profile?.billing_contact_name}</strong></Typography>
          <Typography variant="body1">{organization_profile?.billing_contact_email}</Typography>
          <Box>
            <Typography variant="body1">{organization_profile?.display_name}</Typography>
            <Typography variant="body1">{organization_profile?.billing_contact_address_street}</Typography>
            <Typography
              variant="body1">{organization_profile?.billing_contact_address_city}, {organization_profile?.billing_contact_address_province} {organization_profile?.billing_contact_address_zip}</Typography>
          </Box>
        </Box>
        <OrganizationProfileBillingDetailsModal />
      </Box>
    </Box>
  )
}