import { Box, Typography } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import { GameSelectDropdown } from "components/games/GameSelectDropdown";
import useSharedStyles from "components/useSharedStyles";
import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import {GameTitle} from "../../../enums/GameTitle";
import CoursesListBasics from "pages/courses/components/CoursesListBasics";
import { CoursesListCreator } from "pages/courses/components/CoursesListCreator";

const ViewClassCourses = () => {
  const sharedClasses = useSharedStyles();
  const { params } = useRouteMatch<IRouteParams>();
  const [selectedGame, setSelectedGame] = useState<GameTitle>(GameTitle.BASICS);

  return (
    <Box py={4} px={6}>
      <Box className={sharedClasses?.vspacing4} maxWidth={1080}>
        <Box className={sharedClasses?.vspacing1}>
          <Typography variant="body2" style={{textTransform: 'uppercase'}}>
            <strong>Course Assignments For</strong>
          </Typography>
          <GameSelectDropdown
            klassId={Number(params.classId)}
            selectedGame={selectedGame}
            setSelectedGame={setSelectedGame}
          />
        </Box>
        {selectedGame === GameTitle.BASICS &&
          <CoursesListBasics classId={params.classId} hideCreativeBuildings={true} />
        }
        {selectedGame === GameTitle.CREATOR &&
          <CoursesListCreator classId={Number(params.classId)} />
        }
      </Box>
    </Box>
  )
}

export default ViewClassCourses;