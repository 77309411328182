interface Episode {
  name: string;
  id: number;
  world?: number;
  order: number;
}

interface Unit {
  name: string;
}

export function getDescription(episode: Episode): string {
  const descriptions: { [key: string]: string } = {
    Sequence: 'An order of events that is read and executed by a computer exactly as written.',
    Conditions: 'An exception to a rule (guideline) that allows a program to perform different actions, depending on the condition being true or false.',
    Loops: 'A command used to repeat a part of code until a desired process is complete.',
    Functions: 'A set of steps given a simple name that a programmer can easily call on and reuse again and again in a program.',
    'Variables - Strings': 'A type of value that can be stored in a variable and is made up of characters.',
    'Variables - Integers': 'A numeric value typically stored in a variable and can be used in mathematical expressions.',
    'Variables - Arrays': 'An ordered list that can contain integers and strings.',
    Classes: 'A definition, or blueprint, from which individual objects are created.',
    Properties: 'A characteristic of an object.',
    BWFunctions: ' A block of code designed to perform a particular task.',
  };
  return descriptions[episode.name];
}

export function getVideoLink(episode: Episode): string | undefined {
  const videos: { [key: number]: string } = {
    2000: 'https://youtu.be/LcIJJdbG9VE',
    2002: 'https://youtu.be/nsQ2605KSa8',
    2004: 'https://youtu.be/uVVSopz56ek',
    2006: 'https://youtu.be/ZEsCla92mek',
    5: 'https://youtu.be/afRXifi57ws',
    6: 'https://youtu.be/-sD_Xl0YW-s',
    7: 'https://youtu.be/yA-Qn1pvOmM',
    9: 'https://youtu.be/vFLZHx3L190',
    10: 'https://youtu.be/BZ8r7pC9bHY',
    41: 'https://youtu.be/hhVoBsDqJXI',
  };

  return videos[episode.id];
}

export function getDisplay(episode: Episode, level: { order: number }): string {
  const episodeOrder = episode.world === 1 ? episode.order + 13 : episode.order;
  return `${(episodeOrder + 1).toString()}.${(level.order + 1).toString()}`;
}

export function getImageName(unit: Unit): string {
  const names: { [key: string]: string } = {
    Sequence: 'Sequence',
    Conditions: 'Conditions',
    Loops: 'Loops',
    Functions: 'Functions',
    'Variables - Strings': 'VariablesStrings',
    'Variables - Integers': 'VariablesIntegers',
    'Variables - Arrays': 'VariablesArrays',
    Classes: 'Classes',
    Properties: 'Properties',
    BWFunctions: 'BWFunctions',
  };

  return names[unit.name];
}

export function getName(unit: Unit): string {
  const names: { [key: string]: string } = {
    Sequence: 'Sequence',
    Conditions: 'Conditions',
    Loops: 'Loops',
    Functions: 'Functions',
    'Variables - Strings': 'Variables - Strings',
    'Variables - Integers': 'Variables - Integers',
    'Variables - Arrays': 'Variables - Arrays',
    Classes: 'Classes',
    Properties: 'Properties',
    BWFunctions: 'Functions (Bug World)',
  };

  return names[unit.name];
}
