import useSWR from "swr";
import { useParams } from "react-router-dom";
import { ELessonType, generateOrderedLessons, ICourseData, ILevelCompletion, ILevelData } from "pages/courses/ICourseData";
import endpoints from "endpoints";
import useCurrentUser from "./useCurrentUser";
import useOrderedCourses from "hooks/useOrderedCourses";
import { useMemo } from "react";
import useParentInit from "./useParentInit";
import { levelCompletionMapping } from "pages/reports/reportUtilities";

const useV2StudentProgress = () => {
  const { currentUser } = useCurrentUser();
  const { studentId } = useParams<{ studentId: string }>();
  const { data: courseData, error: courseDataError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const { data: levelCompletions, error: levelCompletionsError } = useSWR<ILevelCompletion[]>(endpoints.studentLevelCompletions(studentId));
  const orderedCourses = useOrderedCourses(courseData);
  const { data: allBasicsLevels } = useSWR<ILevelData[]>(endpoints.allBasicsLevels);
  const { students } = useParentInit();


  const playlist: IPlaylist[] = useMemo(() => {
    const student = students?.find((student: any) => student.id.toString() === studentId);
    return [{
      episodes: orderedCourses.map((course, idx) => {
        const orderedLessons = generateOrderedLessons(course, currentUser.plan === 'School');

        return {
          id: course.id,
          img_name: '/' + course.galaxyImage!,
          name: course.meta.dashboardTitle || course.name,
          world: 0,
          order: idx,
          garbage_count: course.id === 7 ? student?.hoc_garbage_pickup_count : 0,
          levels: !levelCompletions || !allBasicsLevels ? [] : orderedLessons.reduce((acc, cur) => {
            if (cur.lessonType === ELessonType.gameContent && cur.levels) {
              return acc.concat(cur.levels!);
            } else if (cur.lessonType === ELessonType.mediaContent && cur.mediaLevelID) {
              return acc.concat(cur.mediaLevelID!);
            }

            return acc;
          }, [] as number[])
          .map(levelId => {
            return allBasicsLevels.find(({ id }) => id === levelId)!
          })
          .filter(Boolean)
          .reduce((acc, cur) => {
            return [
              ...acc,
              {
                level: cur,
                completions: levelCompletions!.filter(({ level_id }) => (levelCompletionMapping[level_id] || level_id) === cur.id)
              }             
            ]
          }, [] as { level: ILevelData, completions: ILevelCompletion[] }[])
          .map((levelContainer, idx) => {
            return {
              level_number: levelContainer.level.id,
              order: idx,
              fullsize_url: levelContainer.level.fullsize_url,
              studentData: {
                id: levelContainer.level.id,
                attempts: levelContainer.completions.length,
                star_count: levelContainer.completions.slice().sort((a, b) => a.score - b.score).pop()?.score || 0,
                number_of_wins: levelContainer.completions.filter(({ score }) => score > 0).length,
                created_at: levelContainer.completions.slice().sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).pop()?.created_at || '',
                updated_at: ''
              }
            }
          })
        }
      })
    }];
  }, [currentUser, orderedCourses, levelCompletions, allBasicsLevels]);

  const [totalLessons, completedLessons, threeStarLessons] = useMemo(() => {
    const totalLessons = playlist[0].episodes.reduce((acc, cur) => {
      return acc.concat(cur.levels);
    }, [] as IPlaylistEpisodeLevel[]);

    const completedLessons = totalLessons.filter(lesson => lesson.studentData.star_count > 0);
    const threeStarLessons = completedLessons.filter(lesson => lesson.studentData.star_count === 3);

    return [totalLessons.length, completedLessons.length, threeStarLessons.length];
  }, [playlist]);

  return {
    playlist,
    completedLessons,
    totalLessons,
    threeStarLessons,
    loading: !courseData && !courseDataError && !levelCompletions && !levelCompletionsError,
    error: courseDataError || levelCompletionsError
  }
}

export interface IPlaylist {
  episodes: IPlaylistEpisode[];
}

export interface IPlaylistEpisode {
  id: number;
  img_name: string;
  name: string;
  levels: IPlaylistEpisodeLevel[];
  garbage_count?: number;
  order: number;
}

export interface IPlaylistEpisodeLevel {
  level_number: number;
  studentData: {
    id: number;
    attempts: number;
    created_at: string;
    updated_at: string;
    star_count: number;
    number_of_wins?: number;
  }
}


export default useV2StudentProgress;