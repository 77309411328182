import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Paper} from "@material-ui/core";
import Button from "../../../components/ui/buttons/Button";
import {DataGrid, GridColDef, GridRowId} from "@material-ui/data-grid";
import TextField from "../../../components/ui/TextField";
import {useEffect, useState} from "react";
import {CleverKlass} from "../CleverClasses";
import {justFetch} from "../../../mutations/mutate";
import endpoints from "../../../endpoints";


const HiddenKlassDialog: React.VFC<{open: boolean, onClose: () => void, onEnabledKlass: (klassIds: number[]) => void, klasses: CleverKlass[]}> = ({open, onClose, onEnabledKlass, klasses}) => {
  const [selectedKlasses, setSelectedKlasses] = useState<GridRowId[]>([])
  const [filteredKlasses, setFilteredKlasses] = useState<any>(klasses)
  const [query, setQuery] = useState<string>("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const columns: GridColDef[] = [
    { field: 'klass_name', headerName: 'Class', width: 250 },
    { field: 'school_name', headerName: 'School', width: 200 },
  ]

  useEffect(() => {
    setFilteredKlasses(klasses.filter(klass =>
      klass.klass_name.toLowerCase().includes(query.toLowerCase()) ||
      klass.school_name.toLowerCase().includes(query.toLowerCase())))
  }, [query, klasses]);

  const onShowClass = async () => {
    setIsSubmitting(true)
    const reenabledKlasses = selectedKlasses.map((klass_id) => Number(klass_id))
    const resp = await justFetch(endpoints.bulkShowKlasses, 'POST', {klass_id: reenabledKlasses})
    if (resp.ok) {
      setIsSubmitting(false)
      setSelectedKlasses([])
      onEnabledKlass(reenabledKlasses)
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Hidden Classes
      </DialogTitle>
      <Box component={Paper} flexGrow={1} mt={2} mb={2} ml={3} mr={3}>
        <TextField
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search"
          fullWidth
        />
      </Box>
      <DialogContent>
        {filteredKlasses.length > 0 && (
          <DataGrid
            rows={filteredKlasses}
            columns={columns}
            onSelectionModelChange={(data) => setSelectedKlasses(data)}
            checkboxSelection={true}
            disableColumnMenu
            disableColumnSelector
            pagination
            autoHeight
            rowCount={filteredKlasses.length}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <Button variant={'contained'} onClick={onClose}>Close</Button>
          <Button disableElevation color="primary" variant="contained" onClick={onShowClass} disabled={selectedKlasses.length === 0 || isSubmitting}>
            {selectedKlasses.length > 1 ? 'Show Classes' : 'Show Class'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default HiddenKlassDialog