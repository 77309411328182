import { Box } from "@material-ui/core";
import PageContainer from "components/ui/PageContainer"
import React, { useState } from "react";
import useSharedStyles from "components/useSharedStyles";
import { GameSelectDropdown } from "components/games/GameSelectDropdown";
import {GameTitle} from "../../enums/GameTitle";
import CoursesListHeader from "./components/CoursesListHeader";
import CoursesListBasics from "./components/CoursesListBasics";
import { CoursesListCreator } from "./components/CoursesListCreator";

const CoursesIndex: React.FC = () => {
  const [selectedGame, setSelectedGame] = useState<GameTitle>(GameTitle.BASICS)
  const sharedClasses = useSharedStyles()
  return (
    <PageContainer>
      <CoursesListHeader game={selectedGame} />
      <Box className={sharedClasses?.vspacing4}>
        <GameSelectDropdown
          selectedGame={selectedGame}
          setSelectedGame={setSelectedGame}
        />
        {selectedGame === GameTitle.BASICS &&
          <CoursesListBasics />
        }
        {selectedGame === GameTitle.CREATOR &&
          <CoursesListCreator />
        }
      </Box>
    </PageContainer>
  )
}

export default CoursesIndex





