import TextField from "components/ui/TextField"
import { Autocomplete, AutocompleteRenderOptionState, createFilterOptions } from "@material-ui/lab";
import { useOrganizationSelection } from "context/OrganizationSelectionProvider";
import endpoints from "endpoints";
import { ISchoolSearchResultItem } from "pages/settings/teacher/ISchoolSearchResultItem";
import React, { useEffect, useState } from "react";
import { ISimpleSchool } from "types/IDistrict";
import { Box, FormControl, InputLabel } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import Button from "components/ui/buttons/Button";
import Select from "components/ui/Select";
import MenuItem from "components/ui/MenuItem";
import states from "states-us/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { justFetch } from "mutations/mutate";
import useDialogState from "hooks/useDialogState";
import ExistingQuoteDialog from "./quotes/ExistingQuoteDialog";
import {useHistory} from "react-router-dom";
import {routes} from "routes";

const schoolFilter = createFilterOptions<ISimpleSchool>();

interface SchoolSelectionProps {
    isOrder: boolean;
}

export const SalesSchoolSelectionAutocomplete: React.FC<SchoolSelectionProps> = ({isOrder}) => {
    const { zipCode, city, state } = useOrganizationSelection()
    const [schools, setSchools] = useState<ISchoolSearchResultItem[]>([])
    const { setSchoolId, setDisplayName, setIsLocationSet } = useOrganizationSelection()
    const [loadingSchools, setLoadingSchools] = useState<boolean>(true);
    const [error, setError] = useState<string>('')
    const existingQuoteDialogState = useDialogState();
    const [existingQuoteContactName, setExistingQuoteContactName] = useState('')
    const [existingQuoteContactEmail, setExistingQuoteContactEmail] = useState('')
    const history = useHistory();

    const sharedClasses = useSharedStyles()

    useEffect(() => {
        const fetchSchools = async () => {
            setLoadingSchools(true)
            const endpoint = zipCode ? endpoints.schoolSearchByZip(zipCode.toString()) : endpoints.schoolSearchByCity(city || '', state || '')
            justFetch(endpoint, 'GET')
            .then(async (res) => {
                if (res.ok) {
                    const data = await res.json()
                    setSchools(data)
                    setLoadingSchools(false)
                    return
                }
                setError('Could not find any schools with the provided location information')
                setLoadingSchools(false)
            })
            .catch((error) => {
                setLoadingSchools(false)
                setError('Could not load schools')
                console.log(error)
            })
            
        }
        fetchSchools()
    }, [zipCode, city, state])

    return (
        <>
            <ExistingQuoteDialog open={existingQuoteDialogState.open} onClose={existingQuoteDialogState.onClose} name={existingQuoteContactName} email={existingQuoteContactEmail} />
            <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" alignItems="flex-end" width="100%">
                <Box className={sharedClasses?.vspacing1} width="100%">
                    <InputLabel htmlFor="school_id" style={{color: '#000', fontWeight: 600}}>School Name</InputLabel>
                    <Autocomplete
                        id="school_id"
                        disabled={loadingSchools}
                        options={
                            schools?.map((item) => {
                                return {
                                    school_name: item?.school_name,
                                    id: item?.id,
                                    city: item?.school_location_city,
                                    state: item?.school_location_state,
                                    address: item?.school_location_address
                                }
                            }).sort((a: ISimpleSchool, b: ISimpleSchool) => a?.school_name.localeCompare(b?.school_name)) || []
                        }
                        getOptionLabel={(option: ISimpleSchool) => option?.school_name}
                        getOptionSelected={(option: ISimpleSchool, value: ISimpleSchool) => option.id === value.id}
                        onChange={async (e, value) => {
                            if (value && typeof value !== 'string') {
                                if (!isOrder)
                                {
                                    const existResp = await justFetch(endpoints.sales.quote.exists, 'POST',
                                    {organization_name: value.school_name})
                                    const existingQuotes = await existResp.json()
                                    if (existingQuotes?.name) {
                                        setExistingQuoteContactEmail(existingQuotes.email)
                                        setExistingQuoteContactName(existingQuotes.name)
                                        existingQuoteDialogState.handleOpen()
                                        return
                                    }
                                }
                                // This will trigger another step to show, due to school id / name being set
                                setSchoolId(value.id)
                                setDisplayName(value.id === 0 ? '' : value.school_name)
                                setIsLocationSet(true);
                                history.push(isOrder? routes.sales.orders.submit.plan : routes.sales.quotes.create.form)
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = schoolFilter(options, params);

                            filtered.unshift({
                                school_name: `I don't see my school`,
                                id: 0,
                                city: '',
                                state: '',
                                address: ''
                            });

                            return filtered;
                        }}
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        renderOption={(option: ISimpleSchool, state: AutocompleteRenderOptionState) => (
                            <span {...state} style={option.id === 0 ? { color: '#26a9e2', fontWeight: 'bold', padding: '.75rem 0' } : {}} id={option.id.toString()}>
                                <strong style={{marginRight: '.5rem'}}>{option.school_name}</strong>
                                {option.id === 0 &&
                                    <FontAwesomeIcon icon={faArrowRight} />
                                }
                                {option?.id !== 0 &&
                                    <Box>
                                        <span>{option?.address ? `${option?.address}, ` : ''}{option.city ? `${option.city}, ` : ''}{option.state ? `${option.state}` : ''}</span>
                                    </Box>
                                }
                            </span>
                        )}
                        renderInput={(params) => <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            placeholder={loadingSchools ? 'Loading Schools...' : "Please select your school from the list"}
                        />}
                    />
                </Box>
            </Box>
        </>
    )
}

export const SalesCityStateSelection: React.VFC = () => {
    const { setZipCode, setCity: setOrgCity, setState: setOrgState } = useOrganizationSelection()
    const sharedClasses = useSharedStyles()
    const [city, setCity] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [submitting, setSubmitting] = useState<boolean>(false)

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCity(e.target.value)
    }

    const handleStateChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setState(e.target.value as string)
    }

    const handleSearchSubmit = () => {
        setSubmitting(true)
        setZipCode(null)
        setOrgCity(city)
        setOrgState(state)
        setSubmitting(false)
    }


    return (
        <Box display="flex" alignItems="flex-end" className={sharedClasses.hspacing2}>
            <Box mr={2} flex={1} display="flex">
                <TextField
                    label="City or Town Name"
                    autoFocus
                    onChange={handleCityChange}
                    value={city}
                    id="city"
                    variant="outlined"
                    placeholder="Enter a town or city"
                    fullWidth
                    margin="dense"
                    style={{ background: 'white' }}
                />
            </Box>
            <Box>
                <FormControl
                    error={!state}
                >
                    <Select
                        label="State"
                        variant="outlined"
                        fullWidth
                        displayEmpty
                        onChange={handleStateChange}
                        value={state}
                        id="state"
                        margin="dense"
                        style={{ background: 'white' }}
                    >
                    <MenuItem disabled value="">
                        Select your state
                    </MenuItem>
                    {states
                        .filter(({ territory }) => !territory)
                        .map(({ name }) => (
                        <MenuItem key={name} value={name}>{name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box>
                <Button
                    style={{marginBottom: '2px'}}
                    onClick={handleSearchSubmit}
                    color="primary"
                    variant="contained"
                    endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                >
                    Search
                </Button>
            </Box>
        </Box>
    )
}

export const SalesZipSelection: React.VFC = () => {
    const { zipCode, setZipCode, setCity, setState } = useOrganizationSelection()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCity(null)
        setState(null)
        setZipCode(parseInt(e.target.value.toString().replace(/\D/g, '')))
    }
    
    return (
        <TextField
            value={zipCode || ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Please enter your zip code"
            type="text"
            label="Zip Code"
            helperText={zipCode && zipCode.toString().length !== 5 ? 'Please enter a valid zip code' : ''}
        />
    )
}