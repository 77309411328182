import { faEmpire } from "@fortawesome/free-brands-svg-icons";
import { faPlayCircle, faGamepad, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Paper, Typography, Button } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import useCurrentUser from "loaders/useCurrentUser";
import React, { useState } from "react";
import { ICourseData, ILessonNode, isPaidNode, ELessonType } from "../ICourseData";
import LessonMediaContent from "./LessonMediaContent";
import LessonGameContent from "./LessonGameContent";
import EditLessonModal from "../../../components/modal/EditLessonModal";

const LessonCard: React.VFC<{ course: ICourseData, lesson: ILessonNode, order: number }> = ({ course, lesson, order }) => {
    const { currentUser } = useCurrentUser();
    const sharedClasses = useSharedStyles();
    const premiumLesson = isPaidNode(lesson) || course.meta.premium;
    const [isEditingLesson, setIsEditingLesson] = useState(false);

    return <>
      <EditLessonModal open={isEditingLesson} course={course} lesson={lesson} onClose={() => setIsEditingLesson(false)} />
      <Box component={Paper} {...{ variant: 'outlined' }} p={3}>
        <Box display="flex" flexDirection="row" alignItems="center" className={sharedClasses.hspacing2}>
          <Box display="flex" justifyContent="center" position="relative" width={80}>
            <img
              style={{
                maxHeight: 60
              }}
              alt=""
              src={`/images/courses/lesson_icons/${course.configuration.theme}/${lesson.image}.png`}
            />
            {premiumLesson && <img src="/images/courses/kodable_home.png" style={{ width: '40%', position: 'absolute', top: 0, right: '-10%' }} />}
          </Box>
          <Box flexGrow={1} display="flex" flexDirection="column">
            <Typography variant="h2">{order + 1}. {lesson.meta?.dashboardTitle}</Typography>
            {premiumLesson && <Typography variant="body2" style={{ color: '#666', fontStyle: 'italic' }}>This lesson is available with Kodable Premium</Typography>}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <FontAwesomeIcon icon={lesson.lessonType === ELessonType.mediaContent ? faPlayCircle : faGamepad} />&nbsp;
            {lesson.lessonType === ELessonType.mediaContent ? 'Video' : `${lesson.levels?.length || 'Game'} Levels`}
          </Box>
          {lesson.meta?.lengthMinutes && <Box display="flex" flexDirection="row" alignItems="center" ml={2}>
            <FontAwesomeIcon icon={faClock} />&nbsp;
            {lesson.meta.lengthMinutes} minutes
          </Box>}
          {currentUser.is_god && <Box ml={2}>
            <Button
              variant="outlined"
              onClick={e => { e.stopPropagation(); setIsEditingLesson(true); }}
              startIcon={<FontAwesomeIcon icon={faEmpire} />}
            >Edit lesson</Button>
          </Box>}
        </Box>
        <Box
          style={{
            maxHeight: `${1000}px`,
            overflow: 'hidden',
            transition: 'max-height 0.5s'
          }}
        >
          {lesson.lessonType === ELessonType.mediaContent ? <LessonMediaContent lesson={lesson} /> : <LessonGameContent hideAnswerKeys={course.meta.hideAnswerKeys} lesson={lesson} />}
        </Box>
      </Box>
    </>
}

export default LessonCard;