import { Box, Typography } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import React, { useMemo, useState } from "react";
import ReactImageFallback from "react-image-fallback";
import useSWR from "swr";
import { ILessonNode, ILevelData } from "../ICourseData";
import LevelSolutionModal from "../../../components/modal/LevelSolutionModal";

const LessonGameContent: React.VFC<{ hideAnswerKeys?: boolean, lesson: ILessonNode }> = ({ hideAnswerKeys = false, lesson }) => {
    const { data: levels } = useSWR<ILevelData[]>(endpoints.allBasicsLevels);
  
    const lessonLevels = useMemo(() => {
      return lesson.levels?.map(levelId => levels?.find(({ id }) => id === levelId)!).filter(Boolean)
    }, [lesson, levels]);
  
    const [showLevelSolutionModalForLevel, setShowLevelSolutionModalForLevel] = useState<ILevelData | undefined>();
    const sharedClasses = useSharedStyles();
  
    return <Box pt={2}>
      <LevelSolutionModal open={!!showLevelSolutionModalForLevel} level={showLevelSolutionModalForLevel} onClose={() => setShowLevelSolutionModalForLevel(undefined)} />
      {lesson.meta?.helperText && <Box mb={2} maxWidth={600}>
        <Typography variant="subtitle1">Teacher Prompt</Typography>
        <Typography>{lesson.meta?.helperText}</Typography>
      </Box>}
      {(lessonLevels?.length || 0) > 0 && !hideAnswerKeys && <Box>
        <Typography variant="subtitle1" paragraph>Answer Keys</Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" maxWidth={(lessonLevels?.length || 0) * 200}>
          {lessonLevels?.map(level => <Box key={level.id} display="flex" mx={1} flexDirection="column" alignItems="center" onClick={() => setShowLevelSolutionModalForLevel(level)} className={sharedClasses.hoverCursorPointer}>
            <ReactImageFallback
              style={{ maxHeight: 128 }}
              src={level?.thumbnail_url || undefined}
              fallbackImage="/images/student-creations-page/no-maze.png"
            />
          </Box>)}
        </Box>
      </Box>}
    </Box>
}

export default LessonGameContent;