import {
  faCaretDown,
  faEdit,
  faExclamationTriangle,
  faLock,
  faPaperPlane,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useDialogState from "hooks/useDialogState";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import EditPasswordDialog from "components/dialogs/settings/EditPasswordDialog";
import EditProfileDialog from "components/dialogs/settings/EditProfileDialog";
import React, {useEffect, useMemo} from "react";
import { useRef, useState } from "react";
import useSWR, { mutate } from "swr";
import { ITeacher } from "types/ITeacher";
import { ITeacherData } from "types/ITeacherData";
import * as Yup from 'yup';

const TeachersTable: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const { data: teacherData } = useSWR<ITeacherData>(endpoints.teacherInit);
  const sharedClasses = useSharedStyles();
  const inviteTeachersDialog = useDialogState();
  const [teacherBeingEdited, setTeacherBeingEdited] = useState<ITeacher>();
  const [teacherChangingPassword, setTeacherChangingPassword] = useState<ITeacher>();

  const alert = useAlert();

  const [inviteTeachersError, setInviteTeachersError] = useState(false);
  const inviteTeachersForm = useFormik({
    validateOnChange: false,
    initialValues: {
      teacher_emails: ''
    },
    validate: async values => {
      let errorMessage;
      const emails = values.teacher_emails.split('\n').map(email => email.trim()).filter(Boolean);

      if (emails.length === 0) {
        errorMessage = 'Enter the email addresses of teachers you would like to invite'
      }

      for (let i = 0; i < emails.length; i++) {
        try {
          await Yup.string().email().validate(emails[i])
        } catch (e) {
          errorMessage = `${emails[i]} is not a valid email address`;
          break;
        }
      }

      return errorMessage && {
        teacher_emails: errorMessage
      }
    },
    onSubmit: values => {
      return justFetch(endpoints.inviteTeachers, 'POST', {
        teacher_emails: values.teacher_emails.split('\n').map(email => email.trim()).filter(Boolean)
      })
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          mutate(endpoints.teacherInit);
          inviteTeachersDialog.onClose();
        })
        .catch(() => {
          setInviteTeachersError(true);
        });
    }
  });

  useEffect(() => {
    if (inviteTeachersDialog.open) {
      inviteTeachersForm.resetForm();
      setInviteTeachersError(false);
    }
  }, [inviteTeachersDialog.open]);

  const teacherRowData = useMemo(() => {
    return teacherData?.teachers
      .map(teacher => {
        const adminToThisTeacher = teacherData?.teachers
            .find((searchTeacher) => searchTeacher.id === teacher.administrator_id);

        return {
          ...teacher,
          admin: adminToThisTeacher
        }
      })
      .filter(teacher => currentUser.id !== teacher.id) || [];
  }, [teacherData]);

  const [unlinking, setUnlinking] = useState(false);
  const [unlinkError, setUnlinkError] = useState<string>();
  const [teacherUnlinking, setTeacherUnlinking] = useState<ITeacher>();

  useEffect(() => {
    if (!teacherUnlinking) {
      setUnlinking(false);
      setUnlinkError(undefined);
    }
  }, [teacherUnlinking]);

  const unlinkTeacher = () => {
    if (!teacherUnlinking) {
      return;
    }
    setUnlinking(true);
    setUnlinkError(undefined);

    justFetch(endpoints.unlinkAccount(teacherUnlinking.id), 'POST')
      .then(res => {
        setUnlinking(false);
        if (res.ok) {
          mutate(endpoints.teacherInit);
          alert.success('Teacher unlinked');
          setTeacherUnlinking(undefined);
        } else {
          res.json().then(body => setUnlinkError(body?.message || body?.error || 'An unknown error occurred'));
        }
      })
      .catch(() => { setUnlinking(false); setUnlinkError('An unknown error occurred') })
  }

  if (!teacherData) {
    return <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  return <>
    <Dialog {...inviteTeachersDialog} fullWidth>
      <LinearProgress style={{ visibility: inviteTeachersForm.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
      <DialogTitle>Link Teacher Accounts</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Typography>Enter the email addresses of teachers you would like to link to your account.</Typography>

        <TextField
          multiline
          minRows={8}
          name="teacher_emails"
          id="teacher_emails"
          value={inviteTeachersForm.values.teacher_emails}
          onChange={inviteTeachersForm.handleChange}
          placeholder="Enter one email per line"
          error={!!inviteTeachersForm.errors.teacher_emails}
          helperText={inviteTeachersForm.errors.teacher_emails}
          disabled={inviteTeachersForm.isSubmitting}
        />
        {inviteTeachersError && <Alert severity="error">There was an error submitting this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={inviteTeachersDialog.onClose}
          disabled={inviteTeachersForm.isSubmitting}
        >Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => inviteTeachersForm.handleSubmit()}
          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          disabled={inviteTeachersForm.isSubmitting}
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
    <EditProfileDialog
      open={!!teacherBeingEdited}
      onClose={() => setTeacherBeingEdited(undefined)}
      teacher={teacherBeingEdited}
    />
    <EditPasswordDialog
      open={!!teacherChangingPassword}
      onClose={() => setTeacherChangingPassword(undefined)}
      teacher={teacherChangingPassword}
    />
    <Dialog open={!!teacherUnlinking}>
    <LinearProgress style={{ visibility: unlinking ? 'visible' : 'hidden' }}></LinearProgress>
      <DialogTitle>Remove {teacherUnlinking?.name || teacherUnlinking?.username} from your subscription?</DialogTitle>
      <DialogContent>
        They will no longer have access to your subscription. You will not be able to view their classes and students.

        {unlinkError && <Alert severity="error">{unlinkError}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setTeacherUnlinking(undefined)}
          disabled={unlinking}
        >Cancel</Button>
        <Button
          color="orange"
          variant="contained"
          onClick={unlinkTeacher}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          disabled={unlinking}
        >
          Unlink
        </Button>
      </DialogActions>
    </Dialog>
    <Box pb={2} display="flex" flexDirection="row" className={sharedClasses.hspacing2}>
      <Button
        color="primary"
        variant="contained"
        onClick={inviteTeachersDialog.handleOpen}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        Invite Teachers
      </Button>
    </Box>
    <TableContainer component={Paper} {...{ variant: 'outlined' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              School
            </TableCell>
            <TableCell>
              Admin
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teacherRowData
              .map(teacher => <TeacherRow
            key={teacher.id}
            teacher={teacher}
            onEdit={setTeacherBeingEdited}
            onChangePassword={setTeacherChangingPassword}
            onUnlink={setTeacherUnlinking}
          />)}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

interface TeacherRowProps {
  teacher: ITeacher & {
    admin?: ITeacher;
  };
  onEdit: (teacher: ITeacher) => void;
  onChangePassword: (teacher: ITeacher) => void;
  onUnlink: (teacher: ITeacher) => void;
}

const TeacherRow: React.VFC<TeacherRowProps> = ({ teacher, onEdit, onChangePassword, onUnlink }) => {
  const sharedClasses = useSharedStyles();
  const actionsButtonRef = useRef(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false);

  return (<>
    <Menu
      anchorEl={actionsButtonRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={actionsMenuOpen}
      onClose={() => setActionsMenuOpen(false)}
    >
      <MenuItem onClick={() => { setActionsMenuOpen(false); onEdit(teacher); }}><FontAwesomeIcon icon={faEdit} />&nbsp;Edit</MenuItem>
      <MenuItem onClick={() => { setActionsMenuOpen(false); onChangePassword(teacher); }}><FontAwesomeIcon icon={faLock} />&nbsp;Change Password</MenuItem>
      <MenuItem onClick={() => { setActionsMenuOpen(false); onUnlink(teacher); }}><FontAwesomeIcon icon={faTrash} />&nbsp;Unlink</MenuItem>
    </Menu>
    <TableRow
      className={actionsMenuOpen ? sharedClasses.tableRowHoverButtonOpenMenu : sharedClasses.tableRowHoverButton}
      key={teacher.id}
      onClick={() => setActionsMenuOpen(true)}
    >
      <TableCell>{teacher.name}</TableCell>
      <TableCell>{teacher.school?.school_name || 'None'}</TableCell>
      <TableCell>{teacher.admin?.name}</TableCell>
      <TableCell style={{ width: 0 }}>
        <Button
          onClick={e => { e.stopPropagation(); setActionsMenuOpen(true); }}
          ref={actionsButtonRef}
        >
          Actions&nbsp;&nbsp;<FontAwesomeIcon icon={faCaretDown} />
        </Button>
      </TableCell>
    </TableRow>
  </>)
}

export default TeachersTable;