import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import { DialogStateProps } from "hooks/useDialogState";
import React from "react";

const SyncCleverDialog: React.VFC<DialogStateProps> = ({ open = false, onClose = () => { } }) => {
  const handleSyncCleverClasses = () => {
    const redirect_uri = window.location.origin + '/sync_clever';
    window.location.href = process.env.REACT_APP_CLEVER_AUTH_URL + "&redirect_uri=" + encodeURIComponent(redirect_uri);
  }

  return <Dialog open={open} fullWidth>
    <DialogTitle>
      Sync Clever Classes?
    </DialogTitle>
    <DialogContent>
      <Alert severity="warning">This will remove any classes that are no longer in your Clever account.</Alert>
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={onClose}
      >Cancel</Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSyncCleverClasses()}
      >
        Sync Clever Classes
      </Button>
    </DialogActions>
  </Dialog>
}

export default SyncCleverDialog;