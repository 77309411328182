import { Box, Paper, Checkbox, CircularProgress, Typography } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import React, { useState } from "react";
import { IKlass } from "types/IKlass";
import { useCourseCardStyles } from "./CourseCard";

enum CourseCardCreatorCourses { HighFlyer = 1, RunAndJump = 2, OpenCanvas = 3 }

export interface ICreatorCourseData {
    id: number,
    name: string,
    configuration: {
        enabled: boolean
    }
    meta: {
        dashboardTitle: string,
        dashboardImage: string,
        inSectionOrder: number,
        dashboardSubtitle: string,
        description: string
    }
}

const CourseCardCreator: React.FC<{ course: ICreatorCourseData, klass?: IKlass }> = ({ course, klass }) => {
    const [assigned, setAssigned] = useState(klass?.assignments?.creator_courses.includes(course.id) || false);
    const [isAssigning, setIsAssigning] = useState(false);
    const courseCardClasses = useCourseCardStyles();
    const sharedClasses = useSharedStyles()
    const alert = useAlert()
  
    const handleChangeAssignment = async (e: React.ChangeEvent, checked: boolean) => {
      if (!klass) {
        return
      }
      setIsAssigning(true);
  
      const res = await justFetch(endpoints.creatorCoursesForClass(klass?.id), checked ? 'POST' : 'DELETE', { course_id: course.id });
      if (!res.ok) {
        alert.error("There was an error assigning this course. Please try again.")
      } else {
        setAssigned(checked);
      }
      setIsAssigning(false);
    }
  
    return (
      <Box
        component={Paper}
        {...{
          variant: 'outlined',
          classes: {
            root: assigned ? courseCardClasses.paperOutlined : ''
          }
        }}
        style={{
          transition: 'transform 0.5s, border-color 0.5s'
        }}
        my={2} p={2}
        display="flex" flexDirection="row" alignItems="center"
        className={courseCardClasses.floaty}
        position="relative"
      >
        {klass &&
          <Box width={48} height={48} display="flex" flexDirection="column" style={{ backgroundColor: 'white' }} borderRadius="50%" justifyContent="center" alignItems="center" position="absolute" left={-24}>
            {!isAssigning && <Checkbox
              checked={assigned}
              onChange={!assigned ? handleChangeAssignment : undefined}
              style={{ backgroundColor: 'white', transform: 'scale(1.2)' }}
              className={assigned ? courseCardClasses.checkbox : ''}
            />}
            {klass && isAssigning && <CircularProgress size="2rem" style={{ color: '#91BC55' }} />}
          </Box>
        }
        <Box
          className={sharedClasses?.hspacing4}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '1rem'
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 125
            }}
          >
            <img
              style={{
                height: course?.id === CourseCardCreatorCourses.OpenCanvas ? 85 : 75,
                width: 'auto'
              }}
              alt=""
              src={`images/courses/creator_icons/${course?.meta?.dashboardImage}.png`}
            />
          </Box>
          <Box className={sharedClasses?.vspacing1}>
            <Typography variant="h1" style={{fontWeight: 600}}>{course?.meta?.dashboardTitle}</Typography>
            <Typography variant="body1" style={{opacity: .65}}>{course?.meta?.description}</Typography>
          </Box>
        </Box>
      </Box>
    )
}

export default CourseCardCreator;