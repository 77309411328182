import { faArrowLeft, faCheckCircle, faDownload, faFileDownload, faMapMarkerAlt, faRecycle, faStar, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutlined } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, makeStyles, Paper, Typography } from "@material-ui/core"
import endpoints from "endpoints";
import { ELessonType, ICourseData, ILevelData } from "pages/courses/ICourseData";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import useSWR from "swr";
import useSharedStyles from "components/useSharedStyles";
import { ITeacherData } from "types/ITeacherData";
import { format } from "date-fns";
import useCurrentUser from "loaders/useCurrentUser";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import classNames from "classnames";
import { generateBulkStudentsProgressReportView, IProgressReportRawData, IProgressReportView, transformReportToCsv, dateToUTC } from "pages/reports/reportUtilities";
import ClassProgressBar from "./ClassProgressBar";
import { justFetch } from "mutations/mutate";
import ResetClassProgressDialog from "components/dialogs/class/ResetClassProgressDialog";
import useDialogState from "hooks/useDialogState";
import TeacherPaywallDialog from "components/dialogs/paywalls/TeacherPaywallDialog";
import FileSaver from "file-saver";

const columnWidth = 220;
const levelColumnWidth = 100;

const StudentsProgress: React.VFC<{ classIds: number[], selectedCourseId?: string, onSelectCourse?: (courseId?: string) => void }> = ({ classIds, selectedCourseId, onSelectCourse }) => {
  const { currentUser } = useCurrentUser();

  const { data: teacherData, error: teacherDataError } = useSWR<ITeacherData>(endpoints.teacherInit);
  const { data: courseData, error: courseDataError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const { data: levelData, error: levelDataError } = useSWR<ILevelData[]>(endpoints.allBasicsLevels);
  const [reportData, setReportData] = useState<{ [key: string]: IProgressReportRawData }>();
  const [reportDataError, setReportDataError] = useState(false);

  useEffect(() => {
    justFetch(endpoints.bulkV2Reports, 'POST', {
      klass_ids: classIds
    })
      .then(res => res.json() as Promise<{ [key: string]: IProgressReportRawData }>)
      .then(setReportData)
      .catch(() => setReportDataError(true));
  }, [classIds]);

  const loading = (!teacherData && !teacherDataError) || (!courseData && !courseDataError) || (!reportData && !reportDataError) || (!levelData && !levelDataError);
  const error = teacherDataError || courseDataError || reportDataError;

  const students = useMemo(() => teacherData?.students.filter((student) => student.klasses.some(klassId => classIds.includes(klassId))).sort((a, b) => a.id! - b.id!) || [], [teacherData]);

  const reportView = useMemo(() => {
    if (!courseData || !teacherData || !reportData || !levelData) {
      return null;
    }

    return generateBulkStudentsProgressReportView(
      courseData!,
      levelData!,
      students,
      reportData,
      currentUser.plan === 'School',
      classIds.length > 1)
  }, [courseData, students, reportData, levelData, currentUser]);

  const selectedCourse = useMemo(() => reportView?.orderedCourses.find(orderedCourse => orderedCourse.courseId.toString() === selectedCourseId), [reportView, selectedCourseId])
  const sharedClasses = useSharedStyles();
  const studentsProgressStyles = useStudentsProgressStyles();

  const resetProgressDialogState = useDialogState();
  const teacherPaywallDialogState = useDialogState();

  if (loading) {
    return <Box display="flex" flexDirection="row" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  if (error) {
    return <Alert severity="error">There was an error generating this report.</Alert>
  }

  if (students?.length === 0) {
    return <Alert severity="info">You don't have any students in this class.</Alert>
  }

  return <Box display="flex" flexDirection="column" alignItems="flex-start">
    <ResetClassProgressDialog {...resetProgressDialogState} classId={classIds[0]} />
    <TeacherPaywallDialog {...teacherPaywallDialogState} plan="Premium" copy="Tool" />
    <Box display="flex" flexDirection="row" className={sharedClasses.hspacing4}>
      {classIds.length === 1 && <Box position="relative">
        <Button
          variant="contained"
          color="red"
          startIcon={<FontAwesomeIcon icon={faSyncAlt} />}
          onClick={() => {
            if (currentUser.plan === 'School') {
              resetProgressDialogState.handleOpen()
            } else {
              teacherPaywallDialogState.handleOpen();
            }
          }}
        >Reset Students' Progress</Button>
        {currentUser.plan === 'Free' && <img width={36} src="/images/school_badge.png" style={{ position: 'absolute', top: -20, right: -20, filter: 'drop-shadow(0 0 3px rgba(0,0,0,0.2))' }} />}
      </Box>}
      <Button
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faFileDownload} />}
        onClick={() => {
          FileSaver.saveAs(transformReportToCsv('student', students.map(({ name }) => name), reportView!), 'kodable progress report.csv');
        }}
      >Download Report</Button>
    </Box>

    <Box mb={1} width="100%" borderBottom="1px solid rgba(0,0,0,0.12)">&nbsp;</Box>

    {selectedCourseId && <Button
      variant="outlined"
      startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
      onClick={() => onSelectCourse?.()}
    >Back</Button>}

    <Box display="flex" component={Paper} {...{ elevation: 0 }} flexDirection="row" className={studentsProgressStyles.stickyCourses}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width={`${columnWidth}px`} style={{textAlign: 'center'}}>
        <Typography variant="h2" style={{marginBottom: '.5rem'}}>Student Progress</Typography>
        <Typography variant="body2" style={{marginBottom: '.75rem'}}>Download student progress trackers so student can track their own learning!</Typography>
        <a
          href="http://resources.kodable.com/public/activities/StudentProgressTrackers.pdf"
          target="_blank" rel="noreferrer"
        >
          <Button
            startIcon={<FontAwesomeIcon icon={faDownload} />}
            color="blue"
            variant="contained"
          >
            Progress Tracker
          </Button>
        </a>
      </Box>
      {!selectedCourseId && reportView?.orderedCourses.map(courseReport => {
        return <CoursesHeader
          key={courseReport.courseId}
          courseReportData={courseReport}
          onSelectCourse={onSelectCourse ? (selectedCourseId => onSelectCourse?.(selectedCourseId)) : undefined}
        />
      })}
      {selectedCourseId && <LessonsHeader courseId={selectedCourseId} report={reportView!} />}
    </Box>

    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column">
        {students.map(student => {
          return <ClassProgressStudentRowCell key={student.id}>
            <Typography variant="subtitle1">{student.name}</Typography>
            Last seen {student.tracked_date ? format(new Date(student.tracked_date), 'M/d/yyyy') : 'never'}
          </ClassProgressStudentRowCell>
        })}
      </Box>

      {!selectedCourseId && reportView?.orderedCourses.map(courseReport => {
        return <CourseColumn key={courseReport.courseId} courseReportData={courseReport} />
      })}

      {selectedCourse?.orderedLessons?.map(lesson => {
        return <LessonsColumn key={lesson.lessonId} lesson={lesson} />
      })}
    </Box>

    {classIds.length === 1 && <Key />}
  </Box>
}

interface CoursesHeaderProps {
  courseReportData: IProgressReportView['orderedCourses'][0];
  onSelectCourse?: (selectedCourseId?: string) => void
}

const CoursesHeader: React.VFC<CoursesHeaderProps> = ({ courseReportData, onSelectCourse }) => {
  const sharedClasses = useSharedStyles();
  const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();

  return <Box
    mb={2}
    display="flex"
    height={155}
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    width={columnWidth}
    className={onSelectCourse ? classNames({ [sharedClasses.hoverCursorPointer]: !courseReportData.emptyCourse, [classCoursesProgressHeaderStyles.clickableTitle]: !courseReportData.emptyCourse }, classCoursesProgressHeaderStyles.title) : ''}
    onClick={() => {
      if (!courseReportData.emptyCourse) {
        onSelectCourse?.(courseReportData.courseId.toString())
      }
    }}
  >
    <img
      src={`images/courses/planets/${courseReportData.galaxyImage}.png`}
      style={{
        height: 100,
      }}
    />
    <Typography variant="h2" color={onSelectCourse ? 'primary' : 'textPrimary'}>{courseReportData.dashboardTitle}</Typography>
  </Box>
}

const CourseColumn: React.VFC<{ courseReportData: IProgressReportView['orderedCourses'][0] }> = ({ courseReportData }) => {
  const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();
  const isBeachCleanup = courseReportData.courseId === 7;

  return <Box className={classCoursesProgressHeaderStyles.column}>
    {
      courseReportData.orderedProgress.map((progress, idx) => {
        const state = (() => {
          if (isBeachCleanup) {
            return 'noProgress';
          }

          if (progress.completionPercentage < 1) {
            return 'inProgress';
          }

          if (progress.threeStarredEverything) {
            return 'fullyCompleted';
          }

          return 'partiallyCompleted';
        })();

        const completionPercentage = (() => {
          if (isBeachCleanup) {
            return (progress?.hoc_garbage_pickup_count || 0) > 0 ? 1 : 0;
          }

          return progress.completionPercentage;
        })();

        return <ClassProgressStudentRowCell key={idx}>
          {progress.showAsPercent && <Box textAlign="center" width="100%">
            {!isNaN(progress.completionPercentage)
              ? `${Math.round(progress.completionPercentage * 100)}%`
              : (isBeachCleanup ? `${progress.hoc_garbage_pickup_count || 0} pieces collected` : '--')}
          </Box>}
          {!progress.showAsPercent && <ClassProgressBar progress={completionPercentage} state={state} showProgressIndicator={progress.currentLocation}>
            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
              {state === 'fullyCompleted' && <FontAwesomeIcon size="2x" icon={faStar} />}
              {state === 'partiallyCompleted' && <FontAwesomeIcon size="2x" icon={faStarOutlined} />}
              {(state === 'partiallyCompleted' || state === 'fullyCompleted') && <Box ml={1}>Course completed {format(dateToUTC(progress.completionDate!), 'M/d/yyyy')}</Box>}
              {isBeachCleanup && (progress?.hoc_garbage_pickup_count || 0) > 0 && <>
                <FontAwesomeIcon size="2x" icon={faRecycle} />
                <Box
                  ml={1}
                  display="flex"
                  alignItems="center"
                >
                  <Box display="inline" fontWeight="bold" fontSize="0.85rem">{progress.hoc_garbage_pickup_count}</Box>&nbsp;trash pieces collected
                </Box>
              </>}
            </Box>
          </ClassProgressBar>}
        </ClassProgressStudentRowCell>
      })
    }
  </Box>
}

const LessonsHeader: React.VFC<{ courseId: string, report: IProgressReportView }> = ({ courseId, report }) => {
  const courseReportData = useMemo(() => {
    return report.orderedCourses.find(courseReportView => courseReportView.courseId.toString() === courseId)!;
  }, [courseId, report]);

  const lessons = courseReportData.orderedLessons!;

  return <Box display="flex" flexDirection="row" alignItems="flex-end">
    <Box
      my={2}
      display="flex"
      height={155}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      maxWidth={columnWidth}
      minWidth={columnWidth}
    >
      <img
        src={`images/courses/planets/${courseReportData.galaxyImage}.png`}
        style={{
          height: 100,
        }}
      />
      <Typography variant="h2">{courseReportData.dashboardTitle}</Typography>
    </Box>
    <Box display="flex" flexDirection="row">
      {lessons?.map(lesson => {
        return <Box key={lesson.lessonId} display="flex" flexDirection="column" alignItems="center" textAlign="center" justifyContent="space-between" mb={1}>
          <img src={lesson.type === ELessonType.mediaContent ? "/images/courses/media_content_icon.png" : "/images/courses/game_content_icon.png"} style={{ maxHeight: 32 }} />
          <Box mt={1} display="flex" minWidth={levelColumnWidth}>
            {lesson.type === ELessonType.mediaContent && <Typography variant="body2">{lesson.dashboardTitle}</Typography>}
            {lesson.type === ELessonType.gameContent && lesson.orderedLevels.map(level => <Box key={level.levelId} width={levelColumnWidth}>
              <Typography
                style={{wordWrap: 'break-word', padding: '.5rem'}}
                variant="body2"
              >
                {`${lessons.indexOf(lesson) + 1}.${lesson.orderedLevels.indexOf(level) + 1}`}
              </Typography>
            </Box>)}
          </Box>
        </Box>
      })}
    </Box>
  </Box>;
}

const LessonsColumn: React.VFC<{ lesson: Required<IProgressReportView['orderedCourses'][0]>['orderedLessons'][0] }> = ({ lesson }) => {
  const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();

  return <>
    {lesson.orderedLevels.map((level, levelIdx) => {
      return <Box key={level.levelId} className={classCoursesProgressHeaderStyles.column}>
        {level.orderedProgress.map((progress, idx) => {
          return <ClassProgressStudentRowCell key={idx} width={levelColumnWidth} hideBorder={levelIdx > 0}>
            <ClassProgressBar
              state={progress.stars === 3 ? 'fullyCompleted' : (progress.stars > 0 ? 'partiallyCompleted' : 'inProgress')}
              progress={progress.stars > 0 ? 1 : 0}
            >
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" height="100%" px={2}>
                {lesson.type === ELessonType.mediaContent && progress.stars > 0 && <FontAwesomeIcon icon={faCheckCircle} />}
                {lesson.type === ELessonType.gameContent && progress.stars >= 0 && new Array(progress.stars).fill(true).map((node, i) => <FontAwesomeIcon key={i} icon={faStar} />)}
              </Box>
            </ClassProgressBar>
          </ClassProgressStudentRowCell>
        })}
      </Box>
    })}
  </>
}

const useClassCoursesProgressHeaderStyles = makeStyles(theme => ({
  '@keyframes floaty': {
    '0%': {
      transform: 'translateY(0)',
      filter: 'drop-shadow(gray 0px 2px 4px)'
    },
    '50%': {
      transform: 'translateY(-5px)',
      filter: 'drop-shadow(gray 0px 7px 6px)'
    },
    '100%': {
      transform: 'translateY(0)',
      filter: 'drop-shadow(gray 0px 2px 4px)'
    },
  },
  title: {
    '& img': {
      filter: 'drop-shadow(gray 0px 2px 4px)'
    }
  },
  clickableTitle: {
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
      '& img': {
        animation: '$floaty',
        animationDuration: '1.5s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'ease-in-out'
      }
    }
  },
  column: {
    '&:last-child': {
      borderRight: '1px solid rgba(0, 0, 0, 0.2)'
    }
  }
}));

const useClassProgressStudentRowStyles = makeStyles(theme => ({
  cell: {
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderRight: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none'
    }
  },
  insideCell: {
    borderTop: `1px solid rgba(0, 0, 0, 0.2)`,
    borderLeft: 'none',
    borderRight: 'none'
  }
}));

const useStudentsProgressStyles = makeStyles(theme => ({
  stickyCourses: {
    position: 'sticky',
    top: '-2rem',
    zIndex: 1
  }
}));

const ClassProgressStudentRowCell: React.FC<{ hideBorder?: boolean, width?: number }> = ({ children, width = columnWidth, hideBorder = false }) => {
  const classProgressStudentRowClasses = useClassProgressStudentRowStyles();

  return <Box bgcolor="white" width={width} height={66} p={1} className={hideBorder ? classProgressStudentRowClasses.insideCell : classProgressStudentRowClasses.cell} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
    {children}
  </Box>
}

const Key = () => {
  const sharedClasses = useSharedStyles();

  return <Box component={Paper} {...{ variant: 'outlined' }} p={2} mt={4}>
    <Typography variant="subtitle1">Key</Typography>
    <Box mt={2}><FontAwesomeIcon icon={faMapMarkerAlt} /> Last Activity</Box>
    <Box mt={2} display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column" alignItems="flex-end" className={sharedClasses.vspacing2} width={220} >
        <ClassProgressBar
          state="fullyCompleted"
          progress={1}
        >
          <Box display="flex" flexDirection="row" alignItems="center" height="100%" px={2}>
            <FontAwesomeIcon icon={faStar} />&nbsp;Completed all with 3 stars
          </Box>
        </ClassProgressBar>
        <ClassProgressBar
          state="partiallyCompleted"
          progress={1}
        >
          <Box display="flex" flexDirection="row" alignItems="center" height="100%" px={2}>
            <FontAwesomeIcon icon={faStarOutlined} />&nbsp;Completed all with less than 3 stars
          </Box>
        </ClassProgressBar>
      </Box>
      <Box ml={2} width={220} display="flex" flexDirection="column" alignItems="flex-end" className={sharedClasses.vspacing2}>
        <ClassProgressBar
          state="inProgress"
          progress={0.66}
        >
          <Box display="flex" flexDirection="row" alignItems="center" height="100%" px={2}>
            In progress
          </Box>
        </ClassProgressBar>
        <ClassProgressBar
          state="inProgress"
          progress={0}
        >
          <Box display="flex" flexDirection="row" alignItems="center" height="100%" px={2} color="black">
            Not yet attempted
          </Box>
        </ClassProgressBar>
      </Box>
    </Box>
  </Box>
}

export default StudentsProgress;
