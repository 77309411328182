import { Box, CircularProgress } from "@material-ui/core";
import endpoints from "endpoints";
import useSWR from "swr";
import { IKlass } from "types/IKlass";
import CourseCardCreator, { ICreatorCourseData } from "./CourseCardCreator";
import React from "react";

export const CoursesListCreator: React.VFC<{ classId?: number }> = ({ classId }) => {
    const { data: courseData, error: courseDataError } = useSWR<ICreatorCourseData[]>(endpoints.allCreatorCourses);
    const { data: klassData } = useSWR<IKlass>(classId !== undefined ? endpoints.classById(classId, ['assignments']) : null)
  
    const courseDataLoading = (!courseData && !courseDataError) || (classId && !klassData);
  
    if (!courseData || courseDataLoading) {
      return <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    }
  
    return (
      <Box>
        {courseData.map(course => 
          <CourseCardCreator key={course.id} course={course} klass={klassData} />
        )}
      </Box>
    )
  }