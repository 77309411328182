import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import { justFetch } from "mutations/mutate";
import { ICourseData, ILessonNode } from "pages/courses/ICourseData";
import React, { useEffect } from "react";
import { useState } from "react";
import { mutate } from "swr";

const EditLessonModal: React.VFC<{ open: boolean, onClose: () => void, course: ICourseData, lesson: ILessonNode }> = ({ open, onClose, course, lesson }) => {
  const sharedClasses = useSharedStyles();
  const [submitError, setSubmitError] = useState(false);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: lesson.meta || {},
    onSubmit: values => {
      return justFetch(endpoints.courseDetails(course.id), 'GET')
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          return res.json() as Promise<ICourseData>;
        }).then(course => justFetch(endpoints.course(course.id), 'PUT', {
          ...course,
          configuration: {
            ...course.configuration,
            lessonNodes: course.configuration.lessonNodes?.map(lessonNode => lessonNode.nodeID === lesson.nodeID ? { ...lessonNode, meta: values } : lessonNode)
          }
        }))
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          mutate(endpoints.allBasicsCourses);
          mutate(endpoints.courseDetails(course.id));
          onClose();
        })
        .catch(() => setSubmitError(true))
    }
  });

  useEffect(() => {
    if (open) {
      setSubmitError(false);
      form.resetForm();
    }
  }, [open]);

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Edit Lesson</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <TextField
          label="Dashboard Title"
          id="dashboardTitle"
          name="dashboardTitle"
          value={form.values.dashboardTitle}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
        />
        <TextField
          id="gameTitle"
          name="gameTitle"
          label="In-Game Title"
          value={form.values.gameTitle}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
        />
        <TextField
          id="lengthMinutes"
          name="lengthMinutes"
          label="Estimated length in minutes"
          type="number"
          value={form.values.lengthMinutes}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
        />
        <TextField
          id="helperText"
          name="helperText"
          multiline
          rows={4}
          label="Helper text"
          placeholder="This is the about text for videos and lesson prompt for game content"
          value={form.values.helperText}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
        />
        <TextField
          id="analyticsName"
          name="analyticsName"
          label="Analytics Name"
          value={form.values.analyticsName}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
        />
        {submitError && <Alert severity="error">There was an error submitting this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="reset"
          onClick={onClose}
          disabled={form.isSubmitting}
        >Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={form.isSubmitting}
        >Save</Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default EditLessonModal;