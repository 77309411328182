import { Box, Typography } from "@material-ui/core"
import { ILessonNode } from "../ICourseData"
import React from "react";

const createYtEmbedUrl = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
  
    const code = (match && match[2].length === 11)
      ? match[2]
      : null;
  
    return `//www.youtube.com/embed/${code}`
}

const LessonMediaContent: React.VFC<{ lesson: ILessonNode }> = ({ lesson }) => {
    return <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} pt={2}>
      <Box mr={{ xs: 0, md: 4 }} mb={{ xs: 4, md: 0 }} alignSelf="center">
        <iframe width="400" height={225} src={createYtEmbedUrl(lesson.url || '')} title="4 video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Box>
      {lesson.meta?.helperText && <Box maxWidth={600}>
        <Typography variant="subtitle1">About</Typography>
        <Typography>{lesson.meta?.helperText}</Typography>
      </Box>}
    </Box>
}

export default LessonMediaContent