import { faEmpire } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import { HocActivities } from "pages/hoc/HocLandingPage";
import React, { useMemo } from "react";
import useSWR from "swr";
import { creativeBuildingsData } from "../CreativeBuildingsData";
import { IKlass } from "types/IKlass";
import { CreativeAreaCoursesAssignment } from "../CreativeAreaCourses";
import { EnforceAssignmentsAtHome } from "../EnforceAssignmentsAtHome";
import { ICourseData } from "../ICourseData";
import { getOrderedCoursesBySection, convertCreativeBuildingsToSectionedCourses, orderedSections } from "../courseSections";
import CourseCard from "./CourseCard";
import CourseSection from "./CourseSection";

export const CoursesListBasics: React.VFC<{ classId?: string, hideCreativeBuildings?: boolean }> = ({ classId, hideCreativeBuildings = false }) => {
    const { currentUser } = useCurrentUser();
    const { data: courseData, error: courseDataError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
    const { data: klassData } = useSWR<IKlass>(classId !== undefined ? endpoints.classById(classId, ['assignments']) : null)
    const courseAssignments = klassData?.assignments?.courses; 
  
    const courseDataLoading = (!courseData && !courseDataError) || (classId && !klassData);
    const sharedClasses = useSharedStyles();
  
    const coursesBySection = useMemo(() => {
      return getOrderedCoursesBySection(courseData);
    }, [courseData]);
  
    const coursesWithoutSection = useMemo(() => {
      return courseData?.filter(course => !course.meta.section) || [];
    }, [courseData]);
  
    const creativeBuildingsSectioned = useMemo(() => {
      const convertedBuildings = convertCreativeBuildingsToSectionedCourses(creativeBuildingsData)
      return getOrderedCoursesBySection(convertedBuildings as ICourseData[])
    }, [creativeBuildingsData])
  
    if (courseDataLoading) {
      return <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    }
  
    const orderedSectionNodes = orderedSections.map(section => {
      return <CourseSection
        key={section}
        section={section}
        courses={coursesBySection[section] || []}
        creativeBuildings={hideCreativeBuildings ? [] : creativeBuildingsSectioned[section] || []}
        classId={classId ? parseInt(classId) : undefined}
        courseAssignments={courseAssignments}
      />
    })
  
    return <>
      {classId && <Box pb={2} display="flex" className={sharedClasses.hspacing2}>
        <CreativeAreaCoursesAssignment classId={classId} />
        <EnforceAssignmentsAtHome classId={classId} />
      </Box>}
      {!!classId && <Box component={Paper} {...{ variant: 'outlined' }} p={2}>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h1">Manage Assignments</Typography>
        </Box>
        {orderedSectionNodes}
      </Box>}
      {!classId && orderedSectionNodes}
      {!classId && currentUser.is_god && <Box className={sharedClasses.vspacing2}>
        <Box display="flex" flexDirection="row" alignItems="center" className={sharedClasses.hspacing2}>
          <FontAwesomeIcon icon={faEmpire} />
          <Typography variant="h2">Courses Without Sections</Typography>
        </Box>
        <Alert severity="info">Only godmode users can see courses not assigned to a section. To make a course available to the public, assign it to a section.</Alert>
        {coursesWithoutSection.map(course => <CourseCard key={course.id} course={course} />)}
      </Box>}
      {!classId && <HocActivities />}
    </>
}

export default CoursesListBasics;