import { Box, CircularProgress, Typography } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import CourseOutline from "./CourseOutline";
import { generateOrderedLessons, ICourseData } from "./ICourseData";
import LessonCard from "./components/LessonCard";

const ViewCourseLessons = () => {
  const { params: { courseId } } = useRouteMatch<IRouteParams>();
  const { data: courseData, error: courseDetailsError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const courseDetails = useMemo(() => {
    return courseData?.find(({ id }) => id.toString() === courseId);
  }, [courseId, courseData]);
  const courseDetailsLoading = !courseDetails && !courseDetailsError;
  const orderedLessons = useMemo(() => courseDetails ? generateOrderedLessons(courseDetails) : [], [courseDetails]);
  const sharedClasses = useSharedStyles();

  if (courseDetailsLoading) {
    return <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  return <Box>
    {((courseDetails?.configuration?.lessonNodes?.length || 0) > 1) && <Box mb={4}>
      <Typography variant="subtitle1" paragraph>Course Overview</Typography>
      <CourseOutline course={courseDetails!} variant="full" />
    </Box>}
    <Box className={sharedClasses.vspacing2}>
      <Typography variant="subtitle1" paragraph>Lessons</Typography>
      <Typography paragraph>Students have automatic access to the game content below.</Typography>
      {orderedLessons.map((lesson, i) => <LessonCard course={courseDetails!} key={i} order={i} lesson={lesson} />)}
    </Box>
  </Box>;
}

export default ViewCourseLessons;

