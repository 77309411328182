import { Box, Tab, Tabs } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import PageHeader from "components/ui/PageHeader"
import useSharedStyles from "components/useSharedStyles";
import useTeacherInit from "loaders/useTeacherInit";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import LicensesTable from "./LicensesTable";
import TeachersTable from "./TeachersTable";
import {routes} from "../../routes";
import React from "react";

const AdminIndex: React.VFC = () => {
  const { teacherData } = useTeacherInit();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const sharedClasses = useSharedStyles();

  return <Box>
    <PageHeader title="Admin" inAppBar></PageHeader>
    <Tabs
      value={pathname}
      onChange={(ev, newPage) => history.push(newPage)}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="Teachers" value={url} />
      {teacherData?.is_super_admin && <Tab label="School Licenses" value={routes.admin.licenses(url)} />}
    </Tabs>
    <Box p={4} className={sharedClasses.vspacing2}>
      <Route
        component={TeachersTable}
        path={`${path}`}
        exact
      />
      <Route
        component={LicensesTable}
        path={`${routes.admin.licenses(path)}`}
        exact
      />
    </Box>
  </Box>
}

export default AdminIndex;