import { IRouteParams } from "AppRoutes";
import { useHistory, useRouteMatch } from "react-router-dom";
import StudentsProgress from "pages/reports/StudentsProgress";
import { Box, makeStyles } from "@material-ui/core";
import {routes} from "../../../routes";
import React from "react";

const useClassProgressStyles = makeStyles(() => ({
  overflowScrollWithHeight: {
    overflowY: 'scroll',
    height: '700px'
  }
}));

const ClassProgress: React.VFC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();
  const classProgressStyles = useClassProgressStyles();

  return <Box py={4} px={6} className={classProgressStyles.overflowScrollWithHeight}>
    <StudentsProgress
      classIds={[parseInt(params.classId)]}
      selectedCourseId={params.courseId}
      onSelectCourse={selectedCourseId => {
        if (selectedCourseId) {
          history.push(routes.class.progress(params.classId, selectedCourseId))
        } else {
          history.push(routes.class.progress(params.classId, ''))
        }
      }}
    />
  </Box>
}

export default ClassProgress;