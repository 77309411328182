import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core"
import Button from "components/ui/buttons/Button"
import TextField from "components/ui/TextField"
import useSharedStyles from "components/useSharedStyles"
import { useAlert } from "context/AlertProvider"
import endpoints from "endpoints"
import { FormikProvider, useFormik } from "formik"
import useDialogState from "hooks/useDialogState"
import { justFetch } from "mutations/mutate"
import { formatCurrency } from "pages/godmode/utils/numbers"
import React, { useEffect, useState } from "react"
import useSWR from "swr"
import { ISchoolSubscriptionPricing, ISubscription } from "types/ISubscription"

const OrganizationPlanUpgradeModal: React.FC<{subscription: ISubscription | undefined}> = ({subscription}) => {
    const dialogState = useDialogState()
    const { data: subscriptionPricing } = useSWR<ISchoolSubscriptionPricing>(endpoints.godmode.subscriptions.schoolPricing)
    const [isPaid, setIsPaid] = useState<boolean>(false)
    const sharedClasses = useSharedStyles()
    const alert = useAlert()

    const form = useFormik({
        initialValues: {
            custom_discount: 0
        },
        onSubmit: async ({ custom_discount }) => {
            if (getInvoiceTotal() < 0) {
                alert.error('Discount cannot exceed the total invoice amount')
                return
            }
            const res = await justFetch(endpoints.godmode.subscriptions.upgradeToPremiumPlus(subscription?.id), 'POST', {
                custom_discount: custom_discount,
                prorated_credit: getProratedCredit()
            })

            if (res.ok) {
                dialogState.onClose()
                alert.success('Subscription upgraded successfully')
                return
            }

            const error = await res.json()

            alert.error(error?.message || 'An error occurred while upgrading the subscription')
        }
    })

    const monthsRemaining = ( endDate: string | undefined) =>
    {
        if (!endDate)
            return 0;

        // calculate months from current month to end month of subscription inclusive
        const today = new Date();
        const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const end = new Date(endDate);
        const monthsBetween = (end.getFullYear() - currentMonthStart.getFullYear()) * 12 + (end.getMonth() - currentMonthStart.getMonth());
        return Math.max(0, monthsBetween);
    }

    const getContractDuration = () =>
    {
        return Number(subscription?.contract_duration) || 12;
    }

    const getNewContractPrice = () =>
    {
        if (!subscriptionPricing)
            return 0;
        return (subscriptionPricing?.premium_plus || 0) * getContractDuration() / 12;
    }

    const getProratedCredit = (): number => {
        if (!subscription)
            return 0
        const monthsConsumed = getContractDuration() - monthsRemaining(subscription?.end_date);
        return (getNewContractPrice() - subscription.contract_amount) * (monthsConsumed/getContractDuration());
    }

    const getInvoiceTotal = () : number => {
        if (!subscription || !subscriptionPricing) {
            return 0
        }
        let invoiceTotal = getNewContractPrice() - getProratedCredit() - form.values.custom_discount;
        if (isPaid && subscription?.contract_amount) {
            invoiceTotal -= subscription?.contract_amount;
        }
        return Math.max(invoiceTotal, 0);
    }

    useEffect(() => {
        if (!subscription) {
            return
        }
        if (subscription?.purchase_process?.state === 'paid') {
            setIsPaid(true)
        }
    }, [subscription])

    if (!subscriptionPricing) {
        return null
    }
    
    return (
        <Box>
            <Dialog open={dialogState.open}>
                <DialogTitle>Upgrade to Premium+</DialogTitle>
                <DialogContent>
                    <Box className={sharedClasses?.vspacing2}>
                        <Typography variant="h6" align="center">Are you sure you would like to upgrade this Organization to Premium+?</Typography>
                        <FormikProvider value={form}>
                            <TextField
                                label="Custom Discount"
                                name="custom_discount"
                                type="number"
                                helperText="Custom Discount will only be applied to the current term. Subsequent renewals will be processed at full price."
                                value={form.values.custom_discount}
                                onChange={form.handleChange}
                            />
                        </FormikProvider>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Months Remaining</TableCell>
                                        <TableCell>{monthsRemaining(subscription?.end_date)} Months</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Old Price</TableCell>
                                        <TableCell>{formatCurrency(subscription?.contract_amount)} {`(${isPaid ? 'Paid' : 'Unpaid'})`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>New Subscription Base Price</TableCell>
                                        <TableCell>{formatCurrency(getNewContractPrice())}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Prorated Credit</TableCell>
                                        <TableCell>{formatCurrency(-getProratedCredit())}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Invoice Net Price</strong></TableCell>
                                        <TableCell>
                                            <strong>{formatCurrency(getInvoiceTotal())} </strong>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box>
                            {isPaid &&
                                <Typography align="center">A new invoice will be sent for the difference in price between the paid invoice and the new plan</Typography>
                            }
                            {!isPaid &&
                                <Typography align="center">The existing invoice will be updated to reflect the new price</Typography>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            variant="contained"
                            color="red"
                            onClick={() => dialogState.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="blue"
                            onClick={() => form.handleSubmit()}
                        >
                            Confirm Upgrade
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Button
                variant="outlined"
                color="blue"
                onClick={() => dialogState.handleOpen()}
            >
                Upgrade to Premium+
            </Button>
        </Box>
    )
}

export default OrganizationPlanUpgradeModal