import {Alert} from "@material-ui/lab";

const InfoBox: React.FC<{style?: any}> = ({children, style}) => {
  const alertStyle = {
    ...style,
    fontWeight: '600',
  }

  return (
    <Alert severity="info" style={alertStyle}>
      {children}
    </Alert>
  )
}

export default InfoBox