import { faEnvelope, faBriefcase, faSchool } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, CircularProgress, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import useSharedStyles from "components/useSharedStyles";
import { useOrganizationProfile } from "context/OrganizationProfileProvider";
import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { ILicense } from "types/ILicense";
import { ISubscription } from "types/ISubscription";
import { ITeacher } from "types/ITeacher";
import { accountTrackedDate } from "pages/godmode/utils/time";
import Button from "components/ui/buttons/Button";
import { SubscriptionAssignLicenseButton } from "pages/godmode/Subscriptions/assign-license/SubscriptionAssignLicenseButton";
import { AssignLicenseDialog, UnassignLicenseDialog } from "pages/admin/LicensesTable";
import useDialogState from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import endpoints from "endpoints";

export const OrganizationProfileDetailLicensesTab: React.FC = () => {
    const sharedClasses = useSharedStyles()
    const { params } = useRouteMatch<IRouteParams>();
    const { organization_profile, mutate: refreshProfile } = useOrganizationProfile();
    const [subscription, setSubscription] = useState<ISubscription>()
    const [licenses, setLicenses] = useState<ILicense[]>()
    const [teachers, setTeachers] = useState<ITeacher[]>()

    const assignLicenseDialogState = useDialogState();
    const unassignLicenseDialogState = useDialogState();
  
    const [teacherBeingUnassigned, setTeacherBeingUnassigned] = useState<ITeacher>();
    const [siteBeingUnassigned, setSiteBeingUnassigned] = useState<string>();
    const [unassignLicenseError, setUnassignLicenseError] = useState(false);
    
    const [initialValues, setInitialValues] = useState({ school_id: 0, site_name: '' });
  
    useEffect(() => {
        const sub = organization_profile?.subscriptions?.find((sub) => sub.id === Number(organization_profile?.current_subscription?.id))
        if (!sub) {
            return
        }
        console.log(sub)
        setSubscription(sub)

        return () => {
            setSubscription(undefined)
        }
    }, [organization_profile, params])

    useEffect(() => {
        if (!subscription || !subscription?.licenses) {
            return
        }
        const sortedLicenses = subscription?.licenses?.sort((a, b) => {
            // null or undefined site_name at the end
            if (!a.site_name) return 1;
            if (!b.site_name) return -1;
            return a.site_name.localeCompare(b.site_name)});
        setLicenses(sortedLicenses)
    }, [subscription])

    useEffect(() => {
        if (!organization_profile?.teachers) {
            return
        }
        setTeachers(organization_profile?.teachers)
    }, [organization_profile])

    const licenseTeacher = (teacher_id: number) => {
        return teachers?.find(teacher => teacher.id === teacher_id);
    };

    const assignedLicenses = () => {
        return licenses?.filter(license => license.teacher_id);
    };

    useEffect(() => {
        if (teacherBeingUnassigned) {
          setUnassignLicenseError(false);
        }
      }, [teacherBeingUnassigned])
    
    const handleUnassignLicense = () => {
        justFetch(endpoints.unassignLicense(subscription?.purchase_process?.id || 0), 'PUT', {
          subscription_id: subscription?.id,
          teacher_id: teacherBeingUnassigned!.id
        })
          .then(res => {
            if (!res.ok) {
              throw new Error();
            }
            refreshProfile();
            setTeacherBeingUnassigned(undefined);
            setSiteBeingUnassigned(undefined);
            unassignLicenseDialogState.onClose();
          })
          .catch(() => setUnassignLicenseError(true));
      };
    
      const handleAssignLicense = (values: any, setError: React.Dispatch<React.SetStateAction<string | null>>) => {
        setError(null);
        const body = {
          email: values.email,
          school_id: values.school_id,
          site_name: values.site_name,
          subscription_id: subscription?.id,
          secret_key: subscription?.secret_key,
          send_mail: true
        };
        console.log("BODY: ", body);
        return justFetch(endpoints.assignLicense(subscription?.purchase_process?.id || 0), 'POST', body)
          .then(async res => {
            if (!res.ok) {
              const error = await res.json();
              throw new Error(error?.message || 'An unknown error occurred.');
            }
            refreshProfile();
            assignLicenseDialogState.onClose();
          })
          .catch((error) => {
            setError(error?.message);
          });
      };
    
    return (
        <Box className={sharedClasses?.vspacing2}>
            <Typography variant="h2">All - {subscription?.license_count} site{subscription?.license_count !== 1 ? 's': ''}</Typography>
            <Box display="flex" p={2} style={{border: '1px solid #eee', background: "white"}} width="65%">
                <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" width="50%">
                    <Typography variant="h1">Account Super Admin</Typography>
                    <Typography>{organization_profile?.administrator?.name} | Admin Code: <strong>{organization_profile?.administrator?.administrator_code}</strong></Typography>
                    <Typography><FontAwesomeIcon icon={faEnvelope} /> {organization_profile?.administrator?.username}</Typography>
                    <Typography><FontAwesomeIcon icon={faBriefcase} /> {organization_profile?.administrator?.occupation}</Typography>
                    <Typography><FontAwesomeIcon icon={faSchool} /> {organization_profile?.display_name}</Typography>
                </Box>
                <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column" width="50%" alignItems="flex-end">
                    <Box textAlign="right">
                        <Typography><strong>Signed Up</strong></Typography>
                        <Typography>{organization_profile?.administrator?.created_at ? accountTrackedDate(organization_profile?.administrator?.created_at) : ''}</Typography>
                    </Box>
                    <Box textAlign="right">
                        <Typography><strong>Last Active</strong></Typography>
                        <Typography>{organization_profile?.administrator?.updated_at ? accountTrackedDate(organization_profile?.administrator?.updated_at) : ''}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <AssignLicenseDialog 
                    open={assignLicenseDialogState.open}
                    onClose={assignLicenseDialogState.onClose}
                    onSubmit={handleAssignLicense}
                    initialValues={initialValues}
                />
                <UnassignLicenseDialog 
                    open={unassignLicenseDialogState.open}
                    onClose={unassignLicenseDialogState.onClose}
                    teacherBeingUnassigned={teacherBeingUnassigned}
                    siteBeingUnassigned={siteBeingUnassigned}
                    onUnassign={handleUnassignLicense}
                    unassignLicenseError={unassignLicenseError}
                />

                <Box display="flex" alignItems="center" justifyContent="center" p={2} style={{background: '#5e2fac', color: 'white'}} mb={2}>
                    <Typography variant="h2">Assigned Licenses {`(${assignedLicenses()?.length} / ${subscription?.license_count})`}</Typography>
                </Box>
                {(false && (subscription?.license_count || 0 )> 0) &&
                    <SubscriptionAssignLicenseButton subscriptionId={subscription?.id || 0} />
                }
                {!licenses &&
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" p={4}>
                        <CircularProgress />
                    </Box>
                }
                {licenses && licenses?.length === 0 &&
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" p={4}> 
                        <Typography variant="body1" align="center">No licenses assigned</Typography>
                    </Box>
                }
                {licenses && licenses?.length > 0 &&
                <Box className={sharedClasses?.vspacing2} mt={2} mb={2}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        placeholder="Search by email or name"
                    />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>School Name</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {licenses?.map((license) => {
                                    const teacher = teachers?.find((teacher) => teacher.id === license?.teacher_id)
                                    return (
                                        <TableRow>
                                            <TableCell>{license?.site_name}</TableCell>
                                            <TableCell>{teacher?.name}</TableCell>
                                            <TableCell>{teacher?.username}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    color="red"
                                                    size="small"
                                                    onClick={()=>{
                                                        if (teacher)
                                                        {
                                                            setTeacherBeingUnassigned(licenseTeacher(license.teacher_id)!);
                                                            setSiteBeingUnassigned(license.site_name);
                                                            unassignLicenseDialogState.handleOpen();
                                                        }
                                                        else
                                                        {
                                                            setInitialValues({ school_id: license.school_id, site_name: license.site_name });
                                                            assignLicenseDialogState.handleOpen();
                                                        }
                                                    }}
                                                >
                                                    {teacher? "Unassign" : "Assign"}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                }
            </Box>
        </Box>
    )
}