import { Box, useTheme } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import { useTracking } from "context/TrackingProvider";
import {useHistory} from "react-router-dom";
import { routes } from "routes";
import {IPlan} from "../../types/IPlan";
import React from "react";

export interface PlanChipProps {
  plan: IPlan,
  isParent: boolean
  hasCurriculum: boolean
}

export const PlanChip: React.VFC<PlanChipProps> = ({plan, isParent, hasCurriculum}) => {
  const theme = useTheme();
  const sharedClasses = useSharedStyles();
  const { track } = useTracking();
  const history = useHistory();

  const getButtonConfig = () => {
    const config = {
      title: isParent ? 'Upgrade' : 'Get Premium!',
      color: theme.palette.purple.main,
      route: isParent ? routes.upgrade.info : `${process.env.REACT_APP_KODABLE_WEBSITE}/plans`
    }

    if (plan !== 'Free') {
      config.route = routes.settings.billing;
      if (isParent) {
        config.title = 'Pro Plan';
        config.color = theme.palette.teal.main;
      } else {
        config.title = hasCurriculum ? 'Premium+ Plan' : 'Premium Plan';
        config.color = hasCurriculum ? theme.palette.purple.main : theme.palette.teal.main;
        config.route = routes.settings.billing;
      }
    }
    return config;
  }

  const config = getButtonConfig();
  const goToPlansPage = plan === 'Free';
  const schoolPlanProps = !isParent ? {
    ...{ href: config.route,
      target: goToPlansPage ? "_blank" : undefined }
  } : undefined;

  return <Box
    bgcolor={config.color}
    color="white"
    style={{ width: '100%', textDecoration: "none" }}
    textAlign="center"
    p="6px 12px"
    borderRadius={16}
    component="a"
    className={sharedClasses.hoverCursorPointer}
    onClick={() => {
      if (plan === 'Free') {
        track('Clicked "Get Premium"', {}, ['amplitude', 'intercom']);
      }
      if (isParent) {
        history.push(config.route);
      }
    }}
      {...schoolPlanProps}
  >
    {config.title}
  </Box>
}