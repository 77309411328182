import { IQuote } from "pages/sales/IQuote";

export interface ISalesProfile {
  organization_name?: string;
  license_holder?: {
    id: number;
    name: string;
    username: string;
    eligible_for_order: boolean;
  }
  administrator?: {
    id: number;
    name: string;
    username: string;
  }
  plan_history: {
    num_sites: number;
    licenses: {
      school_id: number;
      site_name: string;
    }[],
    has_curriculum: boolean;
    start_date: string;
    end_date: string;
    paid: boolean;
    active: boolean;
    renewed: boolean;
    quickbooks_invoice_link?: string;
    payment_due?: string;
    delinquent: boolean;
    subscription_id: number;
    client_source?: string;
    purchase_process_id: number;
  }[];

  billing_contact_name?: string;
  billing_contact_email?: string;
  billing_contact_phone?: string;

  billing_contact_address_city?: string;
  billing_contact_address_zip?: string;
  billing_contact_address_street?: string;
  billing_contact_address_street2?: string;
  billing_contact_address_country?: string;
  billing_contact_address_province?: string;

  school_mailing_zip?: number;

  quotes: IQuote[];
}

export const getPlanForRenewal = (salesProfile: ISalesProfile) => salesProfile?.plan_history.find(planHistory => planHistory.active && !planHistory.renewed) || salesProfile?.plan_history.slice(-1)[0];
