import React, { Fragment, useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import LevelModal from 'components/modal/LevelModal';
import Loading from 'components/loading/Loading';
import { getDescription, getDisplay } from './progressHelpers';
import Amplitude from 'utils/Amplitude';
import useV2StudentProgress, {IPlaylist} from 'loaders/useV2StudentProgress';
import { faRecycle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClassProgressBar from "../../reports/ClassProgressBar";
import { Box } from '@material-ui/core';

interface HomeProgressHeaderProps {
  color: string;
  levelsCompleted?: number;
  maxLevels: number;
}

const HomeProgressHeader: React.FC<HomeProgressHeaderProps> = ({ color, levelsCompleted, maxLevels }) => (
  <div className="flex mb-4 justify-between items-center">
    <h1 className="text-3xl font-light">Progress Overview</h1>
    {levelsCompleted !== undefined && (
      <div className="text-right">
        <p className="text-3xl text-gray-800 font-light">
          <span>{levelsCompleted}</span> /{' '}
          <span className={color}>{maxLevels}</span>
        </p>
        <p className="text-sm font-light">Levels Completed</p>
      </div>
    )}
  </div>
);

interface HomeProgressTableProps {
  playlist: IPlaylist[];
  furthestHomeUnitCompleted?: { id: number };
}

const HomeProgressTable: React.FC<HomeProgressTableProps> = ({ playlist, furthestHomeUnitCompleted }) => (
  <div>
    <table className="w-full">
      <thead>
      <tr className="border-b">
        {playlist.map((list) => list.episodes.map((episode) => (
          <th key={episode.id} className="py-2">
            <img
              className="mx-auto"
              alt={episode.name}
              width="60px"
              src={`/images/students/curriculum_images/UnitIcon_${episode.img_name}.png`}
            />
          </th>
        )))}
      </tr>
      </thead>
      <tbody>
      <tr className="border-b">
        {playlist.map((list) => list.episodes.map((episode) => {
          const lessonCount = episode.levels.length;
          const completedLessonCount = episode.levels.filter(level => level.studentData?.number_of_wins).length;
          const percentageCompleted = lessonCount > 0 ? (completedLessonCount / lessonCount) * 100 : 0;

          if (episode.garbage_count && episode.garbage_count > 0) {
            return (
              <Box my="12px" key={episode.id}>
                <ClassProgressBar progress={1} state="noProgress" showProgressIndicator={false} >
                  <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                    <FontAwesomeIcon size="2x" icon={faRecycle} />
                    <Box ml={1} display="flex" alignItems="center">
                      <Box display="inline" fontWeight="bold" fontSize="0.85rem">{episode.garbage_count}</Box>&nbsp;trash pieces collected
                    </Box>
                  </Box>
                </ClassProgressBar>
              </Box>
            );
          }

          return (
            <td key={episode.id} className="py-3">
              <div className="relative border border-gray-400 bg-gray-100 h-8" style={{ borderRadius: 7 }}>
                {lessonCount !== completedLessonCount || lessonCount === 0 ? (
                  <div
                    style={{
                      width: `${percentageCompleted}%`,
                      borderRadius: 7
                    }}
                    className="barber_pole absolute h-full bg-gradient-to-r from-blue-500 to-blue-400">
                    {furthestHomeUnitCompleted &&
                      furthestHomeUnitCompleted.id === episode.id && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          style={{
                            bottom: '15px',
                            right: '-16px',
                            zIndex: '10',
                          }}
                          className="absolute right-0 w-4 h-4 lg:h-8 lg:w-8 text-kodable-orange">
                          <path
                            fill="currentColor"
                            d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                        </svg>
                      )}
                  </div>
                ) : (
                  <div
                    style={{ width: '100%', borderRadius: 7 }}
                    className="relative h-full bg-kodable-green flex justify-center items-center">
                    <svg
                      className="w-6 h-6 lg:w-10 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </div>
                )}
              </div>
            </td>
          );
        }))}
      </tr>
      </tbody>
    </table>
    <div className="flex justify-end mt-4 text-sm text-gray-600">
      <div className="flex justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          className="h-4 w-4 mr-2"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          />
        </svg>
        Aligned with CSK12 Framework
      </div>
    </div>
  </div>
);

interface LevelProps {
  level: { fullsize_url: string; order: number, studentData?: { created_at?: string; attempts?: number; star_count?: number; number_of_wins?: number } };
  unit: any;
}

const Level: React.FC<LevelProps> = ({ level, unit }) => {
  const [open, setOpen] = useState(false);
  const starCount = level.studentData?.star_count || 0;

  return (
    <Fragment>
      {open && (
        <LevelModal
          unit={unit}
          level={level}
          closeModal={() => setOpen(false)}
        />
      )}
      <button
        onClick={() => setOpen(true)}
        style={{ height: '3.5rem' }}
        className={`unit-shadow unit-shadow-${starCount}-star m-4 ${starCount > 0 && starCount < 3 && 'bg-kodable-orange text-white'
        } cursor relative w-16 ${starCount === 3 && 'bg-kodable-green text-white'
        } ${starCount === 0 && 'bg-gray-100'
        } rounded z-1 flex justify-center items-center`}>
        <div className="flex flex-col">
          <div className="flex justify-center items-center">
            {starCount === 0 && (
              <div className="relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  style={{ left: '-4px', top: '-4px' }}
                  className="w-6 h-6 absolute"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="w-4 h-4"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  />
                </svg>
              </div>
            )}
            {starCount > 0 &&
              Array(starCount)
                .fill(null)
                .map((_, i) => (
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
          </div>
          <p className="font-bold text-center">{getDisplay(unit, level)}</p>
        </div>
      </button>
    </Fragment>
  );
};

interface UnitProps {
  unit: {
    id: number;
    name: string;
    img_name: string;
    levels: any[];
    order: number;
  };
  unitIndex: number;
}

const Unit: React.FC<UnitProps> = ({ unit, unitIndex }) => {
  const [isOpen, setIsOpen] = useState(unit.name === 'Sequence');

  const toggleOpen = () => setIsOpen(!isOpen);

  if (unit.id === 7) {
    return null;
  }

  return (
    <div className="mb-12">
      <button
        onClick={toggleOpen}
        className="flex w-full justify-between items-center px-4 py-4 bg-gray-100 border">
        <div className="flex">
          <div>
            <img
              alt={unit.name}
              width="30px"
              src={`images/students/curriculum_images/UnitIcon_${unit.img_name}.png`}
            />
          </div>
          <span className="text-lg ml-2">{unit.name}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="w-4 h-4 text-gray-800"
          stroke="currentColor">
          {isOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          )}
        </svg>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            style={{ overflow: 'hidden' }}
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}>
            <div className="mt-8 mx-2">
              <div className="flex justify-between items-center">
                <p className="text-gray-700 text-sm lg:text-base flex-1 italic">
                  {getDescription(unit)}
                </p>
              </div>
              <div className="my-4 flex flex-wrap">
                {unit.levels.map((level, index) => (
                  <Level
                    key={`${unitIndex}.${index + 1}`}
                    unit={unit}
                    level={level}
                  />
                ))}
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

interface HomeUnitsSectionProps {
  playlist: IPlaylist[];
}

const HomeUnitsSection: React.FC<HomeUnitsSectionProps> = ({ playlist }) => (
  <div className="my-10">
    {playlist.map((list) => list.episodes.map((unit, index) => (
      <Unit key={unit.id} unit={unit} unitIndex={index + 1} />
    )))}
  </div>
);

const HomeV2Progress: React.FC = () => {
  const { playlist, loading, error } = useV2StudentProgress();

  useEffect(() => {
    Amplitude.track('Viewed Home Progress');
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center mt-32 h-64">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <HomeProgressHeader
        color="text-kodable-orange"
        levelsCompleted={undefined}
        maxLevels={150}
      />
      <HomeProgressTable
        playlist={playlist}
        furthestHomeUnitCompleted={undefined}
      />
      <HomeUnitsSection playlist={playlist} />
    </div>
  );
};

export default HomeV2Progress;
