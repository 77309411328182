import GameCard from "components/games/GameCard";
import {Box, Divider, Paper, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {justFetch} from "../../../mutations/mutate";
import endpoints from "../../../endpoints";
import {IKlass} from "../../../types/IKlass";
import useTeacherInit from "../../../loaders/useTeacherInit";
import {GameTitle} from "../../../enums/GameTitle";

type GameSelectionProps = {
  klass: IKlass
}

type GameConfig = {
  basics: boolean,
  creator: boolean
}

const GameSelection: React.VFC<GameSelectionProps> = ({klass}) => {
  const { revalidate } = useTeacherInit();
  const assignedGames = klass.klass_settings_data.AssignedGames;

  const [gameConfig, setGameConfig] = useState<GameConfig>({
    basics: assignedGames.includes(GameTitle.BASICS),
    creator: assignedGames.includes(GameTitle.CREATOR)
  })

  const updateAssignedCourses = async (config: GameConfig) => {
    const assignedCourses = [];
    if (config.basics) {
      assignedCourses.push(GameTitle.BASICS);
    }
    if (config.creator) {
      assignedCourses.push(GameTitle.CREATOR);
    }

    await justFetch(endpoints.classById(klass.id), 'PUT', {assigned_games: assignedCourses})
  }

  const toggleBasics = async () => {
    let config: GameConfig;
    if (gameConfig.creator) {
      config = {...gameConfig, basics: !gameConfig.basics};
    } else {
      config = {creator: true, basics: false};
    }
    setGameConfig(config)
    await updateAssignedCourses(config)
    await revalidate()
  }

  const toggleCreator = async () => {
    let config: GameConfig;
    if (gameConfig.basics) {
      config = {...gameConfig, creator: !gameConfig.creator};
    } else {
      config = {creator: false, basics: true};
    }
    setGameConfig(config)
    await updateAssignedCourses(config)
    await revalidate()
  }

  return (
    <Box m={4} maxWidth={800} marginBottom={'3rem'}>
      <Typography paragraph variant="h1">Assigned Courses</Typography>
      <Box component={Paper} p={4} {...{ variant: 'outlined' }}>
        <Box display={"flex"} flexDirection="row" justifyContent="space-evenly">
          <GameCard
            game={GameTitle.BASICS}
            onClick={toggleBasics}
            enabled={gameConfig.basics}
          />
          <GameCard
            game={GameTitle.CREATOR}
            onClick={toggleCreator}
            enabled={gameConfig.creator}
          />
        </Box>
        <Box display={'flex'} justifyContent={'center'} mb={2}>
          <Typography variant={"caption"}>(Click on the game to enable or disable access.)</Typography>
        </Box>
        <Divider />
        <Box display={"flex"} alignItems={'center'} justifyContent={"space-evenly"} mt={4}>
          {(gameConfig.basics && gameConfig.creator) && <Typography>Students have access to both Kodable Basics and Kodable Creator.</Typography>}
          {(gameConfig.basics && !gameConfig.creator) && <Typography>Students have access to Kodable Basics.</Typography>}
          {(gameConfig.creator && !gameConfig.basics) && <Typography>Students have access to Kodable Creator.</Typography>}
        </Box>
      </Box>
    </Box>
  )
}

export default GameSelection