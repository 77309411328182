import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { IGodmodeUserProfile } from "../IGodmodeUserProfile";
import { UICollapsingBlock } from "../UICollapsingBlock";

export const StudentsBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
  const [filterString, setFilterString] = useState('');
  const [numToShow, setNumToShow] = useState(5);
  const sharedClasses = useSharedStyles();
  const students = useMemo(() => {
    return teacher.students.slice().sort((a, b) => a.name.localeCompare(b.name)).filter((student, index) => {
      return (student.name.includes(filterString) || student.student_code.includes(filterString)) && (index < numToShow || filterString.length > 0);
    })
  }, [filterString, numToShow, teacher.students])

  return <UICollapsingBlock color="rgb(143, 209, 79)" title={`Students (${teacher.students.length})`}>
    <Box className={sharedClasses.vspacing2} p={2}>
      <Box>
        <TextField
          placeholder="Search"
          value={filterString}
          onChange={e => setFilterString(e.target.value)}
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "rgb(143, 209, 79)" }}>Name</TableCell>
              <TableCell style={{ color: "rgb(143, 209, 79)" }}>Student Code</TableCell>
              <TableCell style={{ color: "rgb(143, 209, 79)" }}>Linked</TableCell>
              <TableCell style={{ color: "rgb(143, 209, 79)" }}>Created on</TableCell>
              <TableCell style={{ color: "rgb(143, 209, 79)" }}>Last seen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map(student => {
              return <TableRow key={student.id}>
                <TableCell>{student.name}</TableCell>
                <TableCell>{student.student_code}</TableCell>
                <TableCell>{student.linked_parent ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</TableCell>
                <TableCell>{format(new Date(student.created_at), 'PP')}</TableCell>
                <TableCell>{!!student.tracked_date ? format(new Date(student.tracked_date), 'PP') : 'Never'}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {students.length === 0 && <Alert severity="info">No students to show.</Alert>}
      {teacher.students.length > students.length && <Box display="flex" justifyContent="center"><Button
        onClick={() => setNumToShow(numToShow + 10)}
        variant="outlined"
      >Show More</Button></Box>}
    </Box>
  </UICollapsingBlock>;
}