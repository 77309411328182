import { Route, useRouteMatch } from "react-router-dom";
import { routes } from "routes";
import { SalesOrderApprovalQueue } from "./Sales/Orders/SalesOrderApprovalQueue";
import { TeachersIndex } from "./Users/Teachers/TeachersIndex";
import { ViewUserPage } from "./Users/Teachers/ViewUser/ViewUserPage";
import { EventsIndex } from "./Events/EventsIndex";
import { CleverDistrictsIndex } from "./Organizations/CleverDistricts/CleverDistrictsIndex";
import { StudentsIndex } from "./Users/Students/StudentsIndex";
import { IRouteParams } from "AppRoutes";
import { GodmodePageWrapper } from "./GodmodePageWrapper";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, Typography, makeStyles } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import Button from "components/ui/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faPlus } from "@fortawesome/free-solid-svg-icons";
import useSWR from "swr";
import endpoints from "endpoints";
import useDialogState from "hooks/useDialogState";
import { Form, FormikProvider, useFormik, useFormikContext } from "formik";
import * as Yup from 'yup'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { format, parse } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import useCurrentUser from "loaders/useCurrentUser";
import React, { useEffect } from "react";
import { justFetch } from "mutations/mutate";
import { useAlert } from "context/AlertProvider";
import { SchoolsIndex } from "./Organizations/Schools/SchoolsIndex";
import { AmbassadorsIndex } from "./Users/Ambassadors/AmbassadorsIndex";
import { ResourcesIndex } from "./Administration/Resources/ResourcesIndex";
import { RevenueIndex } from "./Sales/Revenue/RevenueIndex";
import SalesDashboard from "./Sales/SalesDashboard";
import { QuotesIndex } from "./Sales/Quotes/QuotesIndex";
import GiftCodesIndex from "./Administration/GiftCodes/GiftCodesIndex";
import OrganizationProfilesIndex from "./Sales/Organizations/OrganizationProfilesIndex";
import OrganizationProfileDetailIndex from "./Sales/Organizations/OrganizationProfileDetailIndex";
import OrderRequestReviewPage from "./Sales/Orders/OrderRequestReviewPage";

interface IGodmodeTimeOff {
  start_date: string;
  end_date: string;
  reason: string;
  teacher_name: string;
}

interface IGodmodeTimeOffRequest {
  start_date: string;
  end_date: string;
  reason: string;
  teacher_id: number;
}

const validationSchema = Yup.object().shape({
  start_date: Yup.string().required('Start Date is required'),
  end_date: Yup.string().required('End Date is required'),
  reason: Yup.string().required('Reason is required'),
  teacher_id: Yup.number().required('You must have a real teacher account'),
})

const useGodmodeIndexStyles = makeStyles(theme => ({
  timeOffWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'auto',
  },
  timeOffInterior: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  timeOffItem: {
    width: '250px',
    minWidth: '250px',
    background: 'white',
    border: '1px solid #eee',
    textAlign: 'center',
    padding: '1rem',
    marginRight: '1rem'
  }
}))

export const GodmodeIndex: React.VFC = () => {
  const { isExact } = useRouteMatch<IRouteParams>();

  return (
    <>
      {isExact &&
        <GodmodePageWrapper title="Godmode" loading={false}>
          <GodmodeIndexContents />
        </GodmodePageWrapper>
      }
      <Route
        component={AmbassadorsIndex}
        path={routes.godmode.ambassadors}
      />
      <Route
        component={SalesOrderApprovalQueue}
        path={routes.godmode.sales.orders}
      />
      <Route
        component={StudentsIndex}
        path={routes.godmode.students.index}
      />
      <Route
        component={TeachersIndex}
        path={routes.godmode.userLookup}
      />
      <Route
        component={ViewUserPage}
        path={routes.godmode.user.index()}
      />
      <Route
        component={EventsIndex}
        path={routes.godmode.events.index}
      />
      <Route
        component={CleverDistrictsIndex}
        path={routes.godmode.cleverDistricts}
      />
      <Route
        component={SchoolsIndex}
        path={routes.godmode.schools.index}
      />
      <Route
        component={ResourcesIndex}
        path={routes.godmode.resources.index}
      />
      <Route
        component={RevenueIndex}
        path={routes.godmode.revenue.index}
      />
      <Route
        component={SalesDashboard}
        path={routes.godmode.sales.index}
        exact={true}
      />
      <Route
        component={QuotesIndex}
        path={routes.godmode.sales.quotes}
        exact={true}
      />
      <Route
        component={OrderRequestReviewPage}
        path={routes.godmode.sales.accountSetup()}
        exact={true}
      />
      <Route
        component={GiftCodesIndex}
        path={routes.godmode.giftCodes}
        exact={true}
      />
      <Route
        component={OrganizationProfilesIndex}
        path={routes?.godmode?.organizationProfiles?.index}
        exact={true}
      />
      <Route
        component={OrganizationProfileDetailIndex}
        path={routes.godmode.organizationProfiles?.view()}
      />
    </>
  )
}

const GodmodeIndexContents: React.VFC = () => {
  const sharedClasses = useSharedStyles()

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" className={sharedClasses.vspacing2}>
        <Typography variant="h1">Welcome to Godmode</Typography>
        <Button
          href="https://form.asana.com/?k=BCfZuo-hvvzCM0gUSpIwGA&d=32318468793698"
          color="green"
          variant="contained"
          startIcon={<><FontAwesomeIcon icon={faBug} /><FontAwesomeIcon icon={faPlus} /></>}
          size="large"
        >
          <Typography variant="subtitle1">New Basics Bug Report</Typography>
        </Button>
      </Box>
      <GodmodeIndexTimeOff />
    </Box>
  )
}

const GodmodeIndexTimeOff: React.VFC = () => {
  const sharedClasses = useSharedStyles()
  const classes = useGodmodeIndexStyles()
  const {data, error} = useSWR<IGodmodeTimeOff[]>(endpoints.godmode.timeOff)

  if (!data) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" p={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Typography>There was an error loading time off data</Typography>
    )
  }

  return (
    <Box className={sharedClasses.vspacing2}>
      <Typography variant="h1">Time Off</Typography>
      <GodmodeIndexTimeOffRequest />
      <Box className={`${sharedClasses.hspacing2} ${classes.timeOffWrapper}`}>
        <Box className={classes.timeOffInterior}>
          {data.map((timeOff, index) => {
            return (
              <Box className={`${sharedClasses.vspacing2} ${classes.timeOffItem}`} key={index}>
                <Typography><strong>{timeOff?.teacher_name}</strong></Typography>
                <Typography>{timeOff?.start_date} - {timeOff?.end_date}</Typography>
                <Typography>Reason: {timeOff?.reason}</Typography>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

const GodmodeIndexTimeOffRequest: React.VFC = () => {
  const timeOffRequestDialogState = useDialogState()
  const sharedClasses = useSharedStyles()
  const { currentUser } = useCurrentUser()
  const alert = useAlert()

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
        start_date: format(new Date(), 'yyyy-MM-dd'),
        end_date: format(new Date(), 'yyyy-MM-dd'),
        reason: "",
        teacher_id: 0
    },
    validationSchema,
    onSubmit: async (values, actions) => {
        try {
            const response = await justFetch(endpoints.godmode.timeOff, 'POST', {
              start_date: values.start_date,
              end_date: values.end_date,
              reason: values.reason,
              teacher_id: values.teacher_id
            })

            if (response.ok) {
              alert.success("Time off request added!")
              form.resetForm()
              timeOffRequestDialogState.onClose()
              return
            }

            throw new Error()
        } catch (e) {
            alert.error("Unable to submit time off request")
        }
    }
  })

  useEffect(() => {
    if (form.values.teacher_id > 0 || !currentUser) {
      return
    }
    form.setFieldValue("teacher_id", currentUser?.id)
  }, [currentUser, form])

  return (
    <>
      <Dialog open={timeOffRequestDialogState?.open}>
        <DialogTitle>Create Time Off Request</DialogTitle>
        <DialogContent className={sharedClasses?.vspacing2}>
          <Typography variant="h2" align="center">Did you ask Jon first?</Typography>
          <FormikProvider value={form}>
            <GodmodeIndexTimeOffRequestForm />
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Button
              color="orange"
              variant="contained"
              onClick={() => {
                form.resetForm()
                timeOffRequestDialogState.onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              color="green"
              variant="contained"
              onClick={() => {form.submitForm()}}
              disabled={form.isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Button
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        color="green"
        variant="contained"
        onClick={() => timeOffRequestDialogState.handleOpen()}
      >
        {`New Request (Ask Jon First)`}
      </Button>
    </>
  )
}

const GodmodeIndexTimeOffRequestForm: React.VFC = () => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<IGodmodeTimeOffRequest>();
  const sharedClasses = useSharedStyles()

  return (
    <Form>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2} mb={2}>
          <DatePicker
            label="Start Date"
            onChange={date => { setFieldValue('start_date', format(date as Date, 'yyyy-MM-dd')); }}
            name="start_date"
            id="start_date"
            value={parse(values.start_date!, 'yyyy-MM-dd', new Date())}
            format="MM/dd/yyyy"
            inputVariant="outlined"
            InputProps={{
              margin: 'dense'
            }}
            disablePast={true}
            helperText={touched.start_date && errors.start_date}
          />
          <DatePicker
            label="End Date"
            onChange={date => { setFieldValue('end_date', format(date as Date, 'yyyy-MM-dd')); }}
            name="end_date"
            id="end_date"
            value={parse(values.end_date!, 'yyyy-MM-dd', new Date())}
            format="MM/dd/yyyy"
            inputVariant="outlined"
            InputProps={{
              margin: 'dense'
            }}
            disablePast={true}
            helperText={touched.end_date && errors.end_date}
            minDate={values.start_date ? parse(values.start_date, 'yyyy-MM-dd', new Date()) : undefined}
          />
        </Box>
      </MuiPickersUtilsProvider>
      <TextareaAutosize
        style={{width: '100%', padding: '1rem', border: '1px solid #c4c4c4', borderRadius: '4px'}}
        placeholder="Reason"
        minRows={5}
        name="reason"
        id="reason"
        value={values.reason}
        onChange={handleChange}
      />
    </Form>
  )
}