import {IFuzzData} from "./IFuzzData";

export enum ELeadState {
  invited = "invited",
  linked = "linked",
  not_invited = "not_invited"
}

export interface IStudentSchoolProfile {
  id: number;
  name: string;
  klasses: number[];
  student_code: string;
  student_codes: {
    klass_id: number,
    code: string
  }[],
  updated_at: string;
  created_at: string;
  social_service?: string;
  lead?: {
    state?: ELeadState;
    email?: string;
  }
  is_teacher: boolean;
  tracked_date?: string;
  fuzzes?: IFuzzData[];
}