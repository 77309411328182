import { ICreativeBuildingsData } from "types/ICreativeBuildingsData";

export const creativeBuildingsData: ICreativeBuildingsData[] = [
    {
        id: 1,
        dashboard_title: "Maze Maker",
        dashboard_subtitle: "Application of Sequence and Conditions",
        icon_url: "/images/courses/creative_area_icons/MazeMaker.png",
        course_section: "introductory"
    },
    {
        id: 2,
        dashboard_title: "Fuzz Builder",
        dashboard_subtitle: "Application of Sequence and Conditions",
        icon_url: "/images/courses/creative_area_icons/FuzzBuilder.png",
        course_section: "introductory"
    },
    {
        id: 3,
        dashboard_title: "Game Designer",
        dashboard_subtitle: "Application of String and Integer Variables",
        icon_url: "/images/courses/creative_area_icons/GameDesigner.png",
        course_section: "intermediate"
    },
    {
        id: 4,
        dashboard_title: "Pets",
        dashboard_subtitle: "Application of Conditions and Functions",
        icon_url: "/images/courses/creative_area_icons/Pets.png",
        course_section: "intermediate"
    }
];

