import React, { ComponentType } from 'react';

import SentryProvider from 'context/SentryProvider';
import AuthProvider from 'context/AuthProvider';
import SWRProvider from 'context/SWRProvider';
import DashboardThemeProvider from 'context/DashboardThemeProvider';
import AlertProvider from 'context/AlertProvider';
import { BrowserRouter } from 'react-router-dom';
import NavProvider from 'context/NavProvider';
import TrackingProvider from 'context/TrackingProvider';
import AppleIDProvider from 'context/AppleIDProvider';
import PromoProvider from 'context/PromoProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import EventsProvider from 'context/EventsProvider';
import ABTestsProvider from 'context/ABTestProvider';

export const ContextCompose: React.FC<{ components: (ComponentType | [ComponentType, {}])[] }> = ({ components, children }) => (
  <>
    {components
      .reverse()
      .reduce((acc, _component) => {
        let Provider, props = {};
        if (Array.isArray(_component)) {
          Provider = _component[0];
          props = _component[1];
        } else {
          Provider = _component;
        }
        return <Provider {...props}>{acc}</Provider>;
      }, children)
    }
  </>
);

const AppContexts: React.FC = ({ children }) => (
  <GoogleOAuthProvider clientId='174894017176-0agntgejqqasph3ptcnoclvhahe00pv9.apps.googleusercontent.com'>
    <ContextCompose components={[
      React.StrictMode,
      SentryProvider,
      BrowserRouter,
      PromoProvider,
      SWRProvider,
      AlertProvider,
      AppleIDProvider,
      AuthProvider,
      DashboardThemeProvider,
      ABTestsProvider,
      NavProvider,
      EventsProvider,
      TrackingProvider
    ]}>
      {children}
    </ContextCompose>
  </GoogleOAuthProvider>
);

export default AppContexts;