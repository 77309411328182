import {Box, CircularProgress} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {IRouteParams} from "AppRoutes";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import {justFetch} from "mutations/mutate";
import React, {useEffect, useMemo, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import useSWR from "swr";
import {IFuzzData} from "types/IFuzzData";
import {IStudentSchoolProfile} from "types/IStudentSchoolProfile";
import {ITeacherData} from "types/ITeacherData";
import {IUserCreatedContent} from "types/IUserCreatedContent";
import {StudentWork} from "./StudentWork";

export type IStudentWorkData = IStudentSchoolProfile & { fuzzes: IFuzzData[], content: IUserCreatedContent[] };

const ClassStudentWork: React.VFC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const [userCreatedContent, setUserCreatedContent] = useState<IUserCreatedContent[]>([])
  const [loadingContent, setLoadingContent] = useState<boolean>(true)
  const [contentError, setContentError] = useState<string>()

  const { data: teacherData, error: teacherDataError, revalidate: revalidateTeacherData } = useSWR<ITeacherData>(endpoints.teacherInit);
  const { data: fuzzData, error: fuzzDataError, revalidate: revalidateFuzzes } = useSWR<{ [key: number]: IFuzzData[] }>(endpoints.fuzzesByClass(params.classId));
  const fuzzLoading = !fuzzData && !fuzzDataError;

  const error = teacherDataError || fuzzDataError || contentError
  const loading = fuzzLoading || loadingContent
  
  const revalidate = () => {
    revalidateTeacherData();
    revalidateFuzzes();
  }

  useEffect(() => {
    setContentError(undefined)
    justFetch(endpoints.getUserCreatedContent, 'POST', {
      conditions: [{
        "column": "student_id", "operator": "in", "value": teacherData?.students.filter((student) => student.klasses.includes(Number(params.classId))).map(student => {
          return student.id
        })
      }]
    })
    .then(async res => {
      if (!res.ok) {
        setContentError('Could not load user created content')
        setLoadingContent(false)
        return
      }
      const data = await res.json()
      setLoadingContent(false)
      setUserCreatedContent(data)
    })
    .catch(error => {
      setContentError(error?.message)
      setLoadingContent(false)
    })
  }, [params, teacherData])

  const students = useMemo<IStudentWorkData[]>(() => {
    if (!teacherData) {
      return [];
    }

    return teacherData?.students
      .filter((student) => student.klasses.includes(Number(params.classId)))
      .map(student => ({
        ...student,
        fuzzes: fuzzData ? fuzzData[student.id] || [] : [],
        content: userCreatedContent ? userCreatedContent.filter((content) => content?.student_id === student.id) || [] : []
      }));
  }, [teacherData, fuzzData, userCreatedContent, params]);


  const sharedClasses = useSharedStyles();

  if (!loading && !error && students.length === 0) {
    return <Alert severity="info">You don't have any students in this class.</Alert>;
  }

  return <Box className={sharedClasses.vspacing2} py={4} px={6}>
    {loading && <Box display="flex" justifyContent="center"><CircularProgress /></Box>}
    {error && <Alert severity="error" action={<Button size="small" color="inherit" onClick={() => revalidate()}>try again</Button>}>Couldn't load student work.</Alert>}
    {!loading && !error && students.map((student, index) => <StudentWork student={student} key={index} />)}
  </Box>;
}



export default ClassStudentWork;
