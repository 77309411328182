import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Paper,
  Switch,
  Typography,
  useTheme
} from "@material-ui/core";
import TeacherPaywallDialog from "components/dialogs/paywalls/TeacherPaywallDialog";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import { useTracking } from "context/TrackingProvider";
import endpoints from "endpoints";
import useDialogState from "hooks/useDialogState";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import React, { useState } from "react";
import useSWR, { mutate } from "swr";
import { IKlass } from "types/IKlass";
import DogEarBox from "../../components/ui/DogEarBox";

export const creativeAreaCourses = [
  {
    key: 'Pets',
    title: 'Pets',
    description: 'Bacon ipsum dolor amet meatloaf chislic meatball boudin, jowl pork shankle ham hock strip steak ham shank tail fatback pork loin rump. Corned beef doner shoulder, leberkas tongue chislic venison sirloin jerky pig. Tail pork loin cow strip steak sausage filet mignon ball tip.'
  },
  {
    key: 'MazeMaker',
    title: 'Maze Maker',
    description: 'Bacon ipsum dolor amet meatloaf chislic meatball boudin, jowl pork shankle ham hock strip steak ham shank tail fatback pork loin rump. Corned beef doner shoulder, leberkas tongue chislic venison sirloin jerky pig. Tail pork loin cow strip steak sausage filet mignon ball tip.'
  },
  {
    key: 'FuzzBuilder',
    title: 'Fuzz Builder',
    description: 'Bacon ipsum dolor amet meatloaf chislic meatball boudin, jowl pork shankle ham hock strip steak ham shank tail fatback pork loin rump. Corned beef doner shoulder, leberkas tongue chislic venison sirloin jerky pig. Tail pork loin cow strip steak sausage filet mignon ball tip.'
  },
  {
    key: 'GameDesigner',
    title: 'Game Designer',
    description: 'Bacon ipsum dolor amet meatloaf chislic meatball boudin, jowl pork shankle ham hock strip steak ham shank tail fatback pork loin rump. Corned beef doner shoulder, leberkas tongue chislic venison sirloin jerky pig. Tail pork loin cow strip steak sausage filet mignon ball tip.'
  }
]

export const CreativeAreaCoursesAssignment: React.VFC<{ classId: number | string }> = ({ classId }) => {
  const { currentUser } = useCurrentUser();
  const { data: klassData } = useSWR<IKlass>(endpoints.classById(classId, ['assignments']));
  const [creativeLockData, setCreativeLockData] = useState(klassData?.assignments?.creative_locks!);
  const [isAssigning, setIsAssigning] = useState(false);
  const [showAssignError, setShowAssignError] = useState(false);
  const alert = useAlert();
  const creativeAreaDialogState = useDialogState();
  const teacherPaywallDialogState = useDialogState();
  const sharedClasses = useSharedStyles();
  const { track } = useTracking();
  const theme = useTheme()

  const handleChangeAssignment = (area: string) => (e: React.ChangeEvent, checked: boolean) => {
    setCreativeLockData(data => ({ ...data, [area]: !checked }));
  }

  const handleSubmit = () => {
    setIsAssigning(true);

    track('Updated Creative Toggle', { ...Object.keys(creativeLockData).reduce((memo, area) => ({ ...memo, [area]: creativeLockData[area] ? 'locked' : 'unlocked' }), {}) })

    return justFetch(endpoints.klassCreativeLock(classId!), 'PUT', creativeLockData)
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        mutate(endpoints.classById(classId!, ['assignments']), undefined, true);
        alert.success(`Creative Areas Updated`);
        creativeAreaDialogState.onClose();
        setIsAssigning(false);
      })
      .catch(() => { setIsAssigning(false); setShowAssignError(true) });
  }

  const clickManageFuzztopia = () => {
    track('Clicked Creative Toggle', { 'plan type': currentUser.plan })
    if (currentUser.plan === 'School') {
      creativeAreaDialogState.handleOpen();
    } else {
      teacherPaywallDialogState.handleOpen();
    }
  }

  return <>
    <DogEarBox imageUrl={currentUser.plan !== 'School' ? '/images/badges/premium-badge-100.png' : undefined} color={currentUser.plan !== 'School' ? theme.palette.blue.main : undefined} width="50%" component={Paper} {...{ variant: 'outlined' }} p={2} display="flex" flexDirection="column" className={sharedClasses.vspacing1}>
      <Box display="flex" alignItems="center">
        <Typography variant="h2" style={{ fontWeight: 300 }}>Manage Fuzztopia</Typography>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" className={sharedClasses.hspacing2} flexGrow={1}>
        <img style={{ width: 75 }} src="/images/courses/creative_area_icons/FuzzBuilder.png"/>
        <Typography>
          Lock and unlock buildings on Fuzztopia.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={clickManageFuzztopia}
        >Manage Fuzztopia</Button>
      </Box>
    </DogEarBox>
    <TeacherPaywallDialog
      plan="Premium"
      copy="Tool"
      {...teacherPaywallDialogState}
    />
    <Dialog open={creativeAreaDialogState.open}>
      <LinearProgress style={{ visibility: isAssigning ? 'visible' : 'hidden' }} />
      <DialogTitle>Manage Fuzztopia</DialogTitle>
      <DialogContent>
        <Typography paragraph>Students need to obtain keys through gameplay to unlock creative areas. This setting allows you to override those keys to prevent students from playing even if they have unlocked them.</Typography>
        <Box display="flex" justifyContent="space-around">
          {creativeAreaCourses.map(course => {
            const assigned = currentUser.plan === 'Free' || (creativeLockData && !creativeLockData[course.key]);

            return <Box key={course.key} display="flex" flexDirection="column" width={150} alignItems="center">
              <img
                src={`images/courses/creative_area_icons/${course.key}.png`}
                style={{
                  height: 100,
                }}
              />
              <Box display="flex" alignItems="center" justifyContent="center">
                <FontAwesomeIcon icon={faLock} />
                <Switch checked={(classId && !creativeLockData) || assigned} disabled={isAssigning} onChange={handleChangeAssignment(course.key)} />
                <FontAwesomeIcon icon={faLockOpen} />
              </Box>
            </Box>
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={creativeAreaDialogState.onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  </>
}

