import { faArrowLeft, faCheck, faSchool } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { justFetch } from "mutations/mutate";
import { useFormik } from "formik";
import useCurrentUser, { mutate as updateCurrentUser } from "loaders/useCurrentUser";
import * as Yup from 'yup';
import { Alert } from "@material-ui/lab";
import { routes } from "routes";
import { ISchoolSearchResultItem } from "./ISchoolSearchResultItem";
import RequestAddSchoolDialog from "./RequestAddSchoolDialog";
import { useTracking } from "context/TrackingProvider";

const useUpdateSchoolResultsPageStyles = makeStyles(theme => ({
  listContainer: {
    maxHeight: '50vh',
    overflow: 'scroll'
  }
}));

const UpdateSchoolResultsPage: React.FC = () => {
  const { state: locationState } = useLocation<{ zipcode: string } | { city: string, state: string }>();
  const { data: schools, error, revalidate } = useSWR<ISchoolSearchResultItem[]>('zipcode' in locationState ? endpoints.schoolSearchByZip(locationState.zipcode) : endpoints.schoolSearchByCity(locationState.city, locationState.state));
  const loading = !schools && !error;
  const classes = useUpdateSchoolResultsPageStyles();
  const sharedClasses = useSharedStyles();
  const [searchString, setSearchString] = useState('');
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [submissionError, setSubmissionError] = useState(false);
  const { track } = useTracking();

  const form = useFormik({
    initialValues: {
      is_non_usa: false,
      school_id: undefined
    },
    validationSchema: Yup.object({
      school_id: Yup.number().required('Please select a school to continue')
    }),
    onSubmit: values => {
      return justFetch(endpoints.user(`${currentUser.id}`), 'PUT', values)
        .then(res => {
          if (!res.ok) {
            setSubmissionError(true);
          } else {
            updateCurrentUser();
            history.push(routes.settings.updateSchoolSuccess, {
              selectedSchool: schools?.find(({ id }) => values.school_id === id)
            });
            track('Add School Completed', {
              result: 'school added'
            });
          }
        })
        .catch(() => setSubmissionError(true));
    }
  });

  const schoolsFound = schools && schools.length > 0;

  const filteredSchools = useMemo(() => {
    const filteredSchools = schools?.filter(({ school_name }) => school_name.toLowerCase().includes(searchString.toLowerCase()));

    if (!filteredSchools?.find(({ id }) => id === form.values.school_id)) {
      form.setFieldValue('school_id', undefined);
    }

    return filteredSchools;
    // eslint-disable-next-line
  }, [schools, searchString]);

  const [showRequestSchoolDialog, setShowRequestSchoolDialog] = useState(false);

  useEffect(() => {
    track('Viewed Onboarding Page', {
      'Page Name': 'Add School Results'
    });
  }, []);

  if (loading) {
    return <PageContainer variant="centered" centeredFlavorImage={currentUser.needsTeacherOnboarding ? '/images/TeacherTransparent.png' : undefined}>
      <PageHeader title="Select A School" />
      <Box minHeight={500} maxHeight="80vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    </PageContainer>
  }

  if (error) {
    return <PageContainer variant="centered" centeredFlavorImage={currentUser.needsTeacherOnboarding ? '/images/TeacherTransparent.png' : undefined}>
      <Box className={sharedClasses.vspacing2}>
        <PageHeader title="Select A School" />
        <Alert severity="error"
          action={
            <Button
              onClick={() => revalidate()}
              color="inherit"
              size="small"
            >Try again</Button>
          }
        >There was an error performing this search.</Alert>
        <Button
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </Box>
    </PageContainer>
  }

  const searchParam = (() => {
    if ('zipcode' in locationState) {
      return locationState.zipcode;
    }

    return `${locationState.city}, ${locationState.state}`;
  })();

  return (
    <PageContainer variant="centered" showProgress={form.isSubmitting} centeredFlavorImage={currentUser.needsTeacherOnboarding ? '/images/TeacherTransparent.png' : undefined}>
      <RequestAddSchoolDialog
        open={showRequestSchoolDialog}
        onClose={() => setShowRequestSchoolDialog(false)}
        school_searched_zip={(locationState as any).zipcode}
        school_mailing_city={(locationState as any).city}
        school_mailing_state={(locationState as any).state}
      />
      <form onSubmit={form.handleSubmit}>
        <Box className={sharedClasses.vspacing2}>
          <PageHeader title="Select A School"></PageHeader>
          {schools && schools.length > 0 && <Typography>
            {schools.length} schools found in {searchParam}{schoolsFound ? ':' : '.'} <Link href="#" onClick={e => {
              e.preventDefault();
              setShowRequestSchoolDialog(true);
            }}>
              Can't find your school?
            </Link>
          </Typography>}
          {schools && schools.length === 0 && <Typography>
            We couldn't find any schools in {searchParam}. <Link href="#" onClick={e => {
              e.preventDefault();
              setShowRequestSchoolDialog(true);
            }}>
              Click here to add your school.
            </Link>
          </Typography>}
          {schoolsFound &&
            <TextField
              autoFocus
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
              variant="outlined"
              fullWidth
              placeholder="Start typing to filter results"
              margin="dense"
            ></TextField>
          }
          {schoolsFound &&
            <Paper classes={{
              root: classes.listContainer
            }}>
              <List>
                {filteredSchools?.map(({ id, school_name, school_location_city, school_location_state, school_location_zip }) => {
                  return <ListItem key={id} button selected={id === form.values.school_id} onClick={() => form.setFieldValue('school_id', id)}>
                    <ListItemIcon>
                      <FontAwesomeIcon size="2x" icon={faSchool} />
                    </ListItemIcon>
                    <ListItemText primary={school_name} secondary={`${school_location_city}, ${school_location_state} ${`${school_location_zip}`.padStart(5, '0')}`}></ListItemText>
                  </ListItem>
                })}
              </List>
            </Paper>
          }

          {submissionError && <Alert severity="error">There was an error submitting this form.</Alert>}
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Button
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                onClick={() => history.goBack()}
              >
                Go Back
              </Button>
            </Box>
            {schoolsFound && <Button
              color="primary"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faCheck} />}
              disableElevation
              type="submit"
              disabled={!form.isValid || form.isSubmitting}
            >Set as my school</Button>}
          </Box>
        </Box>
      </form>
    </PageContainer>
  )
}

export default UpdateSchoolResultsPage;