import { useEffect } from 'react';
import OnboardingLayout from './shared/OnboardingLayout';
import useParentInit from 'loaders/useParentInit';
import useCurrentUser from 'loaders/useCurrentUser';
import mutate from 'mutations/mutate';
import Amplitude from 'utils/Amplitude';
import {routes} from "../../routes";
import endpoints from "../../endpoints";
import {List, ListItem, makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";

function Star() {
  return (
    <svg
      style={{ minWidth: '32px'}}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="star"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      className="w-8 h-8 text-kodable-yellow mr-5">
      <path
        fill="currentColor"
        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
    </svg>
  );
}

const useStyles = makeStyles((theme) => ({
  starList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 50
    }
  },
}))

const ProgressPage = ({
  link = routes.parentOnboarding.plans,
  buttonText = 'Choose your plan',
}) => {
  const { students, loading } = useParentInit();
  const { currentUser } = useCurrentUser();
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    Amplitude.track('(Parent Onboarding) Viewed Progress Page');
  }, []);

  useEffect(() => {
    if (!currentUser.is_dashboard_onboarded) {
      mutate(endpoints.parent.onboarding.finalize(currentUser.id), 'PUT', {
        is_dashboard_onboarded: true,
      });
    }
  }, [currentUser.is_dashboard_onboarded, currentUser.id]);

  if (loading) {
    return null;
  }

  return (
    <OnboardingLayout
      imgName="jump"
      imgSrc="/images/onboarding/jump.jpg"
      title={`Follow ${
        students && students[0] ? `${students[0].name}'s` : "your child's"
      } Growth`}
      activeSteps={4}
    >
      <List className={classes.starList}>
        <ListItem>
          <Star />
          Three stars means they’re excelling!
        </ListItem>
        <ListItem>
          <Star />
          {students && students[0] ? `${students[0].name}` : 'Your Child'} can build
          characters and games in Kodable.
        </ListItem>
        <ListItem>
          <Star />
          Print and share their projects by posting on the fridge at home!
        </ListItem>
      </List>

      <div className="flex items-center justify-center lg:justify-end mt-12 lg:mt-24">
        <button
          className="bg-kodable-orange hover:bg-kodable-orange-dark active:bg-kodable-orange-darker text-white items-center font-semibold text-lg px-4 flex py-2 rounded shadow-kodable text-center w-32"
          onClick={() => history.push(link)}
          style={{ minWidth: "220px" }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="w-6 h-6 mr-4">
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
          </svg>
          <span style={{whiteSpace: 'nowrap'}}>{buttonText}</span>
        </button>
      </div>
    </OnboardingLayout>
  );
}

export default ProgressPage