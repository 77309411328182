import { Box, Typography } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import GradeSubwaySign from "../../../components/ui/GradeSubwaySign";
import { ICourseData } from "../ICourseData";
import courseSections from "../courseSections";
import CourseCard from "./CourseCard";
import Button from "components/ui/buttons/Button";
import React from "react";

interface CourseSectionProps {
    section: keyof typeof courseSections;
    courses: ICourseData[];
    creativeBuildings?: ICourseData[];
    classId?: number;
    courseAssignments?: number[]
  }
  
  const CourseSection: React.VFC<CourseSectionProps> = ({ courseAssignments, classId, section, courses, creativeBuildings }) => {
    const sharedClasses = useSharedStyles();
    const sectionData = courseSections[section];
  
    return <Box mb={8} display="flex" flexDirection="row" >
      <Box mr={2} minWidth={48}>
        {sectionData?.icon &&
          <img style={{ width: 48, filter: 'drop-shadow(0px 2px 4px gray)' }} src={`images/courses/course_icons/${sectionData.icon}.png`} />
        }
      </Box>
      <Box className={sharedClasses.vspacing1} flexGrow={1}>
        <Typography variant="h2">{sectionData.title}</Typography>
        {sectionData?.grades &&
          <GradeSubwaySign grades={sectionData.grades} />
        }
        {courses.map(course => <CourseCard
          key={course.id}
          classId={classId}
          course={course}
          assignedCourses={courseAssignments}
        />)}
        {creativeBuildings?.map(building => <CourseCard
          key={building?.id}
          classId={classId}
          course={building}
          isBuilding={true}
          iconOverride={building?.meta?.iconUrl}
        />)}
        {section === 'atHome' &&
          <Box border="4px solid #91BC55" borderRadius="4px" width="100%" padding="16px" style={{backgroundColor: "#fff"}}>
            <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
              <Box display="flex" flexDirection="row">
                <Box borderRadius={4} overflow="hidden" height="100px" width="100px" marginRight="16px">
                  <img alt="BugWorld app icon" src="/images/courses/bugworld-app-icon.png" height="100" width="100" style={{ height: '100px' }} />
                </Box>
                <Box>
                  <Typography variant="h1" style={{fontWeight: 600}}>Bug World</Typography>
                  <Typography variant="body1" style={{opacity: "0.65", marginLeft: '2px'}}>Intro to Object-Oriented Programming</Typography>
                </Box>
              </Box>
              <Button
                style={{width: 200, height: "36.5px"}}
                href="http://support.kodable.com/en/articles/8609483-all-about-bug-world"
                variant="outlined"
              >
                Learn More
              </Button>
            </Box>
          </Box>
        }
      </Box>
    </Box>
}

export default CourseSection