import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import endpoints from "endpoints";
import { useFormik } from "formik";
import { DialogStateProps } from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useState } from "react";
import { IQuote } from "../IQuote";
import * as Yup from 'yup';
import useSharedStyles from "components/useSharedStyles";
import linkifyStr from 'linkify-string';
import { Alert } from "@material-ui/lab";
import { useAlert } from "context/AlertProvider";
import useSWR from "swr";
import { ISalesProfile } from "pages/settings/teacher/ISalesProfile";
import { format, parse } from "date-fns";

export const ShareQuoteDialog: React.VFC<DialogStateProps & { quote: IQuote }> = ({ open, onClose, quote }) => {
  const [submitError, setSubmitError] = useState<string | false>(false);
  const { data: salesProfile } = useSWR<ISalesProfile>(quote.teacher_id ? endpoints.salesProfile(quote.teacher_id) : null);
  const activePlan = useMemo(() => {
    return salesProfile?.plan_history.find(({ active }) => active);
  }, [salesProfile]);

  const message = useMemo(() => {
    if (!quote.teacher_id) {
      return [
        "Hi,",
        `I've been researching Kodable and have customized a quote. Here is a quote for ${quote.organization_name}, based on the Kodable Premium${quote.has_curriculum ? '+' : ''} plan:`,
        `${process.env.REACT_APP_DASHBOARD_ENDPOINT}/sales/quotes/view/${quote.secret_code}`,
        `We can start our 12-month subscription whenever we want and we can pay with a check, credit card, or purchase order if we decide to buy Kodable.`,
        `Here are some resources and information on Kodable Premium options: https://kodable.com/plans`,
        `Any questions can be answered at support@kodable.com.`,
        `Thanks for joining me in exploring a Kodable license for ${quote.organization_name}!`
      ].join('\n\n')
    } else if (activePlan) {
      return [
        "Hi,",
        `Our Kodable license expires on ${format(parse(activePlan!.end_date, 'yyyy-MM-dd', new Date()), 'PPP')}. Here is our quote to renew for another year:`,
        `${process.env.REACT_APP_DASHBOARD_ENDPOINT}/sales/quotes/view/${quote.secret_code}`,
        "You can renew anytime before then by viewing the quote and clicking Renew Now or by going to:",
        `${process.env.REACT_APP_DASHBOARD_ENDPOINT}/sales/orders/submit/plan?quote=${quote.secret_code}`,
        "Thanks!"
      ].join('\n\n');
    } else {
      return '';
    }
  }, [quote, salesProfile]);

  const alert = useAlert();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      to_email: '',
      from_name: quote.contact_name,
      subject: `Kodable Quote for ${quote.organization_name}`,
      message: message
    },
    validationSchema: Yup.object({
      to_email: Yup.string().email('Please enter a valid email').required('Please enter a valid email'),
      from_name: Yup.string().required('Please enter your name'),
      subject: Yup.string().required('Please enter a subject'),
      message: Yup.string().required('Please enter a message'),
    }),
    onSubmit: values => {
      return justFetch(endpoints.shareQuote(quote.secret_code), 'POST', {
        ...values,
        message: linkifyStr(values.message.trim()).replaceAll('\n', '<br />')
      })
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          onClose();
          alert.success(`Quote shared to ${values.to_email}`)
        })
        .catch(() => setSubmitError('An unknown error occurred or you are offline. Please try again.'));
    }
  });

  useEffect(() => {
    if (open) {
      form.resetForm();
      setSubmitError(false);
    }
  }, [open]);

  const sharedClasses = useSharedStyles();

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <DialogTitle>Share Quote</DialogTitle>
    <DialogContent className={sharedClasses.vspacing2}>
      <Box display="flex" className={sharedClasses.hspacing2}>
        <TextField
          value={form.values.to_email}
          fullWidth
          id="to_email"
          name="to_email"
          label="Recipient Email"
          onChange={e => form.setFieldValue('to_email', e.target.value.trim())}
          helperText={form.submitCount > 0 ? form.errors.to_email : undefined}
          error={form.submitCount > 0 && !!form.errors.to_email}
          disabled={form.isSubmitting}
        />
        <TextField
          value={form.values.from_name}
          fullWidth
          id="from_name"
          name="from_name"
          label="Sender Name"
          onChange={form.handleChange}
          helperText={form.submitCount > 0 ? form.errors.from_name : undefined}
          error={form.submitCount > 0 && !!form.errors.from_name}
          disabled={form.isSubmitting}
        />
      </Box>
      <TextField
        value={form.values.subject}
        fullWidth
        id="subject"
        name="subject"
        label="Subject"
        onChange={form.handleChange}
        helperText={form.submitCount > 0 ? form.errors.subject : undefined}
        error={form.submitCount > 0 && !!form.errors.subject}
        disabled={form.isSubmitting}
      />
      <TextField
        multiline
        fullWidth
        id="message"
        name="message"
        label="Message"
        onChange={form.handleChange}
        disabled={form.isSubmitting}
        value={form.values.message}
      />
      {submitError && <Alert severity="error">{submitError}</Alert>}
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={onClose}
        disabled={form.isSubmitting}
      >Cancel</Button>
      <Button
        variant="contained"
        onClick={form.submitForm}
        startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
        disabled={form.isSubmitting}
        color="primary"
      >Send</Button>
    </DialogActions>
  </Dialog>;
}