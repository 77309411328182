import {useEffect, useLayoutEffect, useState} from "react";
import {justFetch} from "../../mutations/mutate";
import endpoints from "../../endpoints";
import {Box, Paper} from "@material-ui/core";
import {DataGrid, GridColDef, GridRowData} from "@material-ui/data-grid";
import {addColumnFormatters} from "./utils/formatter";
import SyncCleverDialog from "../../components/dialogs/clever/SyncCleverDialog";
import useDialogState from "../../hooks/useDialogState";
import CleverButtonToolbox from "./components/CleverButtonToolbox";
import CleverTable from "./components/CleverTable";

interface CleverStudent {
  klass_id: number,
  klass_name: string,
  clever_id: string,
  last_sync: string,
  student_id: number
  student_name: string,
  school_name: string,
  teachers: {id: number, name: string}[],
  klasses: {id: number, name: string}[],
  teacher_name: string
}

const CleverStudents: React.VFC = () => {
  const [students, setStudents] = useState<CleverStudent[]>([])
  const [query, setQuery] = useState<string>('');
  const [pageSize, setPageSize] = useState(50);
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const [isLoading, setLoading] = useState(true);
  const syncCleverDialogState = useDialogState()

  const onPageChange = async (page: number) => {
    await getStudents(page+1, pageSize, query)
    setPage(page)
  }

  const getStudents = async (page: number, pageSize: number, query: string = '') => {
    const resp = await justFetch(endpoints.clever.allStudents(page, pageSize, false, query), 'GET')
    const data = await resp.json()

    setTotalRowCount(data.total_count)

    setStudents(data.students.map((student: CleverStudent) => {
      return {...student, id: `${student.student_id}-${student.clever_id}`}
    }))
    setLoading(false)
  }

  useLayoutEffect(() => {
    getStudents(page+1, pageSize)
  }, [])

  useEffect(() => {
    getStudents(page+1, pageSize, query)
  }, [query])


  const columns: GridColDef[] = [
    { field: 'student_name', headerName: 'Student Name', width: 175 },
    { field: 'clever_id', headerName: 'Clever ID', width: 250 },
    { field: 'school_name', headerName: 'School Name', width: 175 },
    { field: 'teachers', headerName: 'Teacher Name', width: 250 },
    { field: 'klasses', headerName: 'Class Name', width: 250 },
    { field: 'last_sync', headerName: 'Last Sync', width: 125, type: 'date' },
  ]

  return (
    <Box ml={2} mr={2}>
      <SyncCleverDialog open={(syncCleverDialogState.open)} onClose={syncCleverDialogState.onClose} />
      <CleverButtonToolbox
        searchPlaceholder={"Search Students"}
        searchDisabled={students.length === 0 && query.length === 0}
        onSearchChange={(searchValue) => setQuery(searchValue)}
        toolTip={'These are the students currently shared with Kodable through Clever. If a specific student is missing, please contact your Clever admin to ensure they are synced correctly.'}
      />
      <Box component={Paper}>
        <CleverTable
          onPageSizeChange={setPageSize}
          onPageChange={onPageChange}
          pageSize={pageSize}
          page={page}
          columns={columns}
          rows={students}
          loading={isLoading}
          totalRowCount={totalRowCount}
        />
      </Box>
    </Box>
  )
}

export default CleverStudents