import { Box, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from "@material-ui/core"
import Cookies from 'universal-cookie';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from "react";
import Loading from "components/loading/Loading";
import Button from "components/ui/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Buffer } from 'buffer';
import ParentInvites, { TrackParentInviteMethod, TrackParentInviteMethodLocation } from "utils/ParentInvites";
import { IKlass } from "types/IKlass";

const cookies = new Cookies();

interface PrintDialogProps {
  open?: boolean;
  onClose?: (...args: any[]) => any;
  handoutUrl: string;
  filename: string;
  v2?: boolean;
  isParentLetters?: boolean;
  klass?: IKlass | null;
  location?: TrackParentInviteMethodLocation;
}

export const handoutPdfUrl = (handoutUrl: string, v2: boolean) => `${process.env.REACT_APP_HANDOUT_URL}?`
  + `&kodable_token=${cookies.get('kodable_kode')}`
  + (v2 ? `&react_handout=${handoutUrl}` : `&handout=${handoutUrl}`)
  + `&env=${process.env.REACT_APP_HANDOUT_ENV}`
  + `&response=json`;

export const downloadPrintout = (handoutUrl: string, v2: boolean) => fetch(handoutPdfUrl(handoutUrl, v2))
    .then(res => res.json());

export const savePrintout = (filename: string, { pdf }: { pdf: string }) => saveAs(new Blob([Buffer.from(pdf.replace(/\s/g, ''), 'base64')], { type: 'application/pdf' }), `${filename}.pdf`);

const PrintDialog: React.VFC<PrintDialogProps> = ({ open = false, onClose = () => { }, handoutUrl, filename, v2 = false, isParentLetters = false, klass = null, location }) => {
  const [generating, setGenerating] = useState(false);
  const [printoutJson, setPrintoutJson] = useState<{ pdf: string }>();

  useEffect(() => {
    if (open) {
      setGenerating(true);

      downloadPrintout(handoutUrl, v2)
        .then((res) => {
          setPrintoutJson(res);
          setGenerating(false);
        });

      ParentInvites.track(klass, TrackParentInviteMethod.clicked, location)
    }
  }, [open]);

  const handleDownloadGeneratedPrintout = () => {
    if (isParentLetters && klass) {
      ParentInvites.track(klass, TrackParentInviteMethod.download, location)
    }
    savePrintout(filename, printoutJson!)
    onClose()
  }

  const theme = useTheme();

  return <Dialog open={open}>
    <DialogTitle>{generating ? "Generating printout..." : "Printouts ready!"}</DialogTitle>
    <DialogContent>
      {generating && <Box display="flex" alignItems="center" justifyContent="center">
        <Loading />
      </Box>}
      {!generating && <Box display="flex" justifyContent="center">
        <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.green.main} size="7x" />
      </Box>}
    </DialogContent>
    <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
      {generating && <Button variant="outlined" onClick={onClose}>Cancel</Button>}
      {!generating && <Button variant="contained" color="primary" onClick={() => handleDownloadGeneratedPrintout()}>Download</Button>}
    </DialogActions>
  </Dialog>
  // return open && generating ? <PrintModal type={`${filename}.pdf`} /> : null;
}

export default PrintDialog